import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../customerservice';

@Component({
  selector: 'app-bidslayout',
  templateUrl: './bidslayout.component.html',
  styleUrls: ['./bidslayout.component.scss'],
  providers:[CustomerService]
})
export class BidsLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
  }

}
