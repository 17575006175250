<!-- Sidebar -->
<div class="sidebar-root sidebarmobclose" id="sidebar-root" *ngIf="isLoggedIn">
    <div class="email-for-mob">
        <a href="mailto:{{useremail}}">{{useremail}}</a>
    </div>
    <div [ngClass]="assumeUser ? 'assumeUser':''">
        <div class="accordion" [ngClass]="builderlist.length > 1 ? 'builderlistforone' : ''" >
            <!-- <div class="select-builder-content">
            <p>
                Select a builder <i class="fa fa-angle-down" style="margin-left: 5%;"></i>
            </p>
        </div> -->
            <mat-accordion *ngIf="builderlist">
                <mat-expansion-panel *ngFor="let blist Of builderlist;first as isFirst;let i = index" [attr.data-index]="i" [expanded]="isFirst"
                    (opened)="fetchjobs(blist,blist.COMPANY_ID,blist.VENDOR_ID, i)" (closed)="collapseBC(blist,blist.COMPANY_ID,blist.VENDOR_ID, i)">
                    <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'" [ngClass]="{ 'special-header': selectedBC == i }">
                        <mat-panel-title>
                            <p class="builder-name">
                               <!-- {{blist.COMPANY_NAME && blist.COMPANY_NAME.length >= 20 ? blist.COMPANY_NAME.substr(0, 20)+'...' : blist.COMPANY_NAME}}-->
                               {{blist.COMPANY_NAME && blist.COMPANY_NAME ? blist.COMPANY_NAME: ' '}}
                            </p>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div *ngIf="isSearched" class="reset-click">
                        <a (click)="resetList()">
                            Reset
                        </a>
                    </div>
                    <div *ngIf="norjoberr">
                        <p class="text-center">{{norjoberr}}</p>
                    </div>
                    <div *ngIf="this.jobsloading" style="position: relative;margin-top: 6%;margin-bottom: 10%;">
                        <ngx-loader [loading]="this.jobsloading"></ngx-loader>
                    </div>
                    <div class="search-root" style="flex: 10" *ngIf="selectedbuilder !== i && builderjoblist && builderjoblist.length > 0">
                        <div class="search-job" style="flex: 4">
                            <p class="search-job-p">
                                Job #
                            </p>
                        </div>
                        <div class="search-job" style="flex: 6">
                            <p class="search-job-p search-job-p-align">
                                <span class="search-job-p-align">Customer</span>
                            </p>
                        </div>
                        <div class="search-job" (click)="showsearch(i)" style="flex: 1.2">
                            <i class="fa fa-search search-icon"></i>
                        </div>
                    </div>
                    <div *ngIf="selectedbuilder !== i && builderjoblist && builderjoblist.length > 0" [ngClass]="builderjoblist && builderjoblist.length > 5 ? 'pvd-style' : ''">
                        <div class="search-root-list" *ngFor="let plist Of builderjoblist;let x = index"
                            [attr.data-index]="x">
                            <div class="search-root-list-pl" (click)="selectplan(plist.JOB_ID,x,plist)" style="flex: 10">
                                <div class="search-root-list-pl-code" style="flex: 3">
                                    <p class="search-root-list-text">
                                        {{plist.JOB_NO}}
                                    </p> 
                                </div>
                                <div class="search-root-list-pl-code" style="flex: 7">
                                    <p class="search-root-list-text">
                                        {{
                                            plist.CUSTOMER_NAME && plist.CUSTOMER_NAME.length > 30 ?
                                            plist.CUSTOMER_NAME.substr(0,30)+'...'
                                            :plist.CUSTOMER_NAME
                                        }} 
                                    </p>
                                </div>
                                <div class="search-root-list-pl-code" *ngIf="selectedplan === plist.JOB_NO && showCheck" style="flex: .2">
                                    <img src="..\assets\img\checkmark.png" class="checktick"/>
                                </div>
                                <div class="search-root-list-pl-code" *ngIf="selectedplan !== plist.JOB_NO" style="flex: .2"><img src="..\assets\img\checkmark.png" class="checktick"
                                style="visibility: hidden;"/></div>
                            </div>
                        </div>
                    </div>
                    <div class="search-now" *ngIf="selectedbuilder === i">
                        <form>
                            <div class="search-by">
                                <div>
                                    <p>
                                        Search by
                                    </p>
                                </div>
                                <div class="sr-cross" (click)="setContent()">
                                    <i class="fa fa-times"></i>
                                </div>
                            </div>
                            <div class="search-by-job">
                                <input type="text" placeholder="Job Number" [(ngModel)]="jobno" name="jobno"/>
                            </div>
                            <div class="search-by-job">
                                <input type="text" placeholder="Customer Name" [(ngModel)]="customername" name="customername"/>
                            </div>
                            <p class="searcherr">
                                {{this.errsearch}}
                            </p>
                            <div>
                                <button (click)="searchJobs()" class="button-clipped">Search</button>
                            </div>
                        </form>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
            <div *ngIf="this.loading" class="sidebar-loader-sp">
                <ngx-loader [loading]="this.loading"></ngx-loader>
            </div>
        </div>
    </div>    
</div>