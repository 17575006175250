import { Component, Renderer2, OnInit, Inject } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Router } from "@angular/router";
import { DOCUMENT } from '@angular/common';
import { environment } from "../environments/environment";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { delay } from 'rxjs/operators';
import { ApiService } from './api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';

  constructor(private idle: Idle, private keepalive: Keepalive,private router: Router,
    private api: ApiService,
    private _renderer2: Renderer2, 
    private modalService: NgbModal, 
    @Inject(DOCUMENT) private _document: Document) {
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(20);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(1780);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => { 
      this.idleState = 'No longer idle.'
      this.reset();
    });
    
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      localStorage.removeItem('userdata');
      this.modalService.dismissAll();
      // this.router.navigate(['/logout']);
      window.location.href = '/logout'; // making sure logout template should always render explicitly, no cache template will carry
    });
    
    idle.onIdleStart.subscribe(() => {
        this.idleState = 'You\'ve gone idle!'
    });
    
    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);
    keepalive.onPing.subscribe(() => this.lastPing = new Date());
    this.reset();
 
  }
  reset() {
      this.idle.watch();
      this.idleState = 'Started.';
      this.timedOut = false;
  }

  ngOnInit() {
    console.log("This is a test console!!!!!!!")
    this.api.checkForTokenExpiration();
    let userdata = JSON.parse(localStorage.getItem('userdata'));    
    // This block is for smartlook implementation. Id and Region is taken from environment file
    if(environment.SMART_LOOK_ID && environment.SMART_LOOK_REGION && userdata != null){
      userdata = userdata.data;
      let script = this._renderer2.createElement('script');
      script.type = `text/javascript`;
      script.text = `window.smartlook||(function(d) {
      var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
      var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
      c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
      })(document);
      smartlook('init', '${environment.SMART_LOOK_ID}', { region: '${environment.SMART_LOOK_REGION}' });
      smartlook('record', { forms: true, emails: true, ips: true, numbers: true });
      smartlook('identify', "${userdata.WEB_VENDOR_ID}", {
        "User Name": "${userdata.VENDOR_NAME}",
        "User Email": "${userdata.EMAIL_ADDRESS}",
        "name": "${userdata.VENDOR_NAME}",
        "email": "${userdata.EMAIL_ADDRESS}"
      });`
      this._renderer2.appendChild(this._document.body, script);
    }
  }
}