import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../customerservice';

@Component({
  selector: 'app-homelayout',
  templateUrl: './homelayout.component.html',
  styleUrls: ['./homelayout.component.scss'],
  providers:[CustomerService]
})
export class HomelayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
