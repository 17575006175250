import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage } from '@kolkov/ngx-gallery';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CustomerService } from '../../customerservice';
import { ApiService } from 'src/app/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import { saveAs } from 'file-saver'; 
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { Router } from "@angular/router";
import { langauge } from '../../../environments/language/en';
//import * as moment from 'moment-timezone';


@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})

export class HomepageComponent implements OnInit {


  galleryOptions: NgxGalleryOptions[];
  popupImages: NgxGalleryImage[];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public isCollapsed = false;
  public docsloading = true;
  public reportsloading = false;
  public galleryloading = false;
  public scheduleLoading = false;
  public companyLoading = true;
  public poLoading = false;
  public punchLoading = false;
  public bidsLoading = false;
  public warrentyLoading = false;
  public dashboardreportsLoading = false;
  public jobselectionreportsLoading = false;
  loginPopToggle: number = 1;
  subPopToggle: number = 1;
  modalRef: any;
  datesort: boolean = false;
  assetsascensort: boolean = true;
  docsOrig: any;
  docerrMsg: string;
  galleryerrMsg: string;
  scheduleerr: string;
  reportserr: string;
  userdata: any;
  moment: any = moment;
  selectedThing: any;
  selectedkey: any;
  //bookmark
  public bookmarksLoading = false;
  bookmarkserr: string;
  public loading = false;
  public encodedocsloading = false;
  errMsg: string = "";
  companyErrMsg: string = "";
  nojoberrMsg: string = "";
  bookmarks: any;
  reportascensort: boolean = true;
  assetsdatesort: boolean = false;
  public contactsLoading = false;
  contactsserr: string = "";
  ascensort: boolean = true;
  urlexists: Number
  doctype: any;
  reportsDesc:any;
  downloadsuccess: boolean = false;
  contacts: any;
  reports: any;
  docsList: any;
  galleryimages: any;
  origgalleryimages: any;
  scheduledata: any;
  purchasedata: any;
  bidsdata: any;
  punchitemsData: any;
  wpoData: any;
  legalDetails: any;
  @ViewChild('gallerycontent', { static: false }) galleryContent;
  @ViewChild('documentcontent', { static: true }) docContents;
  @ViewChild('emailData', { static: true }) emailData;
  @ViewChild("reportsdoccontents", {static: false} ) divView: ElementRef;
  @ViewChild('notFound', { static: true }) notFound;
  pdfViewerEnabled:boolean;


  companyDetails: any;
  origScheduledata: any;
 
  // constructor(private api: ApiService,private customerservice: CustomerService,private modalService: NgbModal, ele: ElementRef, private gallery: Gallery) {
  origpurchasedata: any;
  origbidsdata: any;
  origpuchitemsdata: any;
  origwpodata: any;
  searchingjob: boolean = false;
  purchaseerr: string;
  bidserr: string;
  puchitemserr: string;
  wpoerr: string;
  selectedpo: any;
  userplandocs: any;
  userorigdocs: any;
  userorigdocsbckp: any;
  encodederr: any;
  reportsOrig: any;
  selectedjob: any;
  totalcount: any;
  pagenumber: any;
  imagesrc: string = "";
  imagetodownload: string = "";
  descriptionsort: boolean = false;
  bididsort: boolean = false;
  reversesort: boolean = false;
  namesort: boolean = false;
  pnosort: boolean = false;
  nosort: boolean = false;
  startdatesort: boolean = false;
  enddatesort: boolean = false;
  roomsort: boolean = false;
  activity: boolean = false;
  bidstatus: boolean = false;
  itemsort: boolean = false;
  showmobmenu: boolean = false;
  canaryReleaseUserFlag:boolean = true;
  canaryReleaseUsers:any;
  @HostListener("window:scroll") onWindowScroll() {
    let scroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    let max = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    if(scroll  > max-200 && this.companyDetails) {
      this.getAssets(scroll,max-200,this.companyDetails.COMPANY_ID,this.companyDetails.VENDOR_ID);
    }
  }



  constructor(
    private router: Router,
    private api: ApiService,
    private customerservice: CustomerService,
    private modalService: NgbModal, 
    ele: ElementRef,
    private sanitizer: DomSanitizer,
    private deviceService: DeviceDetectorService) {
    this.userdata = JSON.parse(localStorage.getItem('userdata'));    //fetching user data from local storage
   }

  ngOnInit() {
    this.getbuilderDocs();
    this.getLegaladdr();
    this.getBuildersData();
    this.getScheduleData();
    this.getSelectedjobid();
    this.getcustomerbookmarks();  
    this.getpurchaseorder();
    this.getBids();
    this.getPunchItems();
    this.getWarrantyPo();
    this.getBuilderContacts();
    this.jobSearching();
    this.getbuilderImages();
    this.getjobDocuments();
    this.getjobReports();
  }

 checkCanaryReleaseUsers(companyId){
  let canaryReleaseUsers
  if(companyId){
    if(canaryReleaseUsers.indexOf(parseInt(companyId)) !== -1){
      this.canaryReleaseUserFlag = true;
    }
    else{
      this.canaryReleaseUserFlag = false;
    }
  }  
 }
  
  //fetching selected builder details from builderData behaviour subject
  getBuildersData() {                                                   
    this.customerservice.builderData
    .subscribe(
      data => {
        if(data){
          this.companyLoading = false
          this.companyDetails = data;
          //this.checkCanaryReleaseUsers(data.COMPANY_ID);
        }
        else{
          this.companyLoading = false
          this.companyErrMsg = "No Company Found"          
        }
        //console.log(">>>>load",this.companyLoading)
        //console.log(">>>>data",this.companyDetails)
      },err => {
        console.log(err) 
      }
    );
  }



  //fetching selected builder plan legal address from builderlegalData behaviour subject
  getLegaladdr() {                                                    
    this.customerservice.builderlegalData
    .subscribe(
      data => {

        this.legalDetails = data;
      },err => {
        console.log(err) 
      }
    );
  }


  //fn to get builder documents
  getbuilderDocs() {
    let res = this.customerservice.customerdocsData;
    res.subscribe(
      data => {  
        if(data && Object.keys(data).length > 0) {
          if(data.loading === true) {
            this.docsList = [];
            this.userorigdocsbckp = [];
            this.docsloading = true;
            this.docerrMsg = '';
          } else if(data && Object.keys(data).length > 0 && data['message'] === 'SUCCESS' && data['status'] === true) { 
            this.docsloading = false;
            this.docsList = data['data'];
            this.userorigdocs = Object.assign({}, data['data']);
            this.userorigdocsbckp = Object.assign([], data['data']);
            this.docerrMsg = '';
          } else {
            this.docerrMsg = data['data'];
            this.docsloading = false;
            this.docsList = [];
            this.userorigdocsbckp = [];
          }
        } else if(data && data.length == 0 || data === undefined) {
          this.docsList = [];
          this.userorigdocsbckp = [];
          this.docsloading = false;
          this.docerrMsg = '';
        }
      } 
    );
  }


  getAssets(scroll,max,company_id,vendor_id) {
    let res = this.customerservice.planjobid;
    res.subscribe(
      data => {
        if(data && data != undefined && data[1].jobselected) {
          this.loadjobassets(scroll,max,company_id,vendor_id,data[0].jobid)
        } else if(data && data != undefined && !data[1].jobselected && data[2].builderselected) {
          this.loadbuilderassets(scroll,max,company_id,vendor_id);
        }
    })
  }

  loadbuilderassets(scroll,max,company_id,vendor_id) {
    let tot = parseInt(this.totalcount) / environment.TOTAL_IMG_COUNT;
    let offset = Math.ceil(tot);
    if (scroll > max && this.loginPopToggle === 4 && !this.galleryloading && vendor_id != undefined && offset > 1 && parseInt(this.pagenumber) < offset) {
      this.galleryloading = true;
      let response = this.api.get_builder_photos(parseInt(this.pagenumber, 10) + 1,this.userdata.data.WEB_VENDOR_ID,company_id,vendor_id);
      response.subscribe(res => {
        if(res["message"] === "SUCCESS" && res["status"] === true) { 
          this.galleryloading = false;
          this.pagenumber = res['data'][0].pagenumber;
          res['data'].map((u) => {
            this.galleryimages.push(u);
            this.origgalleryimages.push(u);
          })
        } else {
          this.galleryloading = false;
          this.customerservice.vendorbuilderimages(res['data']);
        }
      });
    } 
  }

  //fn to load job assets
  loadjobassets(scroll,max,company_id,vendor_id,jobid) {
    let tot = parseInt(this.totalcount) / environment.TOTAL_IMG_COUNT;
    let offset = Math.ceil(tot);
    if (scroll > max && this.loginPopToggle === 4 && !this.galleryloading && vendor_id != undefined && offset > 1 && parseInt(this.pagenumber) < offset && this.galleryimages &&this.galleryimages.length  < this.totalcount) {
      this.galleryloading = true;
      let response = this.api.vendor_job_images(parseInt(this.pagenumber, 10) + 1,this.userdata.data.WEB_VENDOR_ID,company_id,vendor_id,jobid);
      response.subscribe(res => {
        if(res["message"] === "SUCCESS" && res["status"] === true) { 
          this.galleryloading = false;
          this.pagenumber = res['data'][0].PAGE_NUMBER;
          res['data'].map((u) => {
            this.galleryimages.push(u);
            this.origgalleryimages.push(u);
          })
        } else {
          this.galleryloading = false;
          this.customerservice.vendorbuilderimages(res['data']); 
        }
      });
    } 
  }

  //fn to get builder photos
  getbuilderImages() {
    let res = this.customerservice.vendorBuilderImagesList;
    res.subscribe(
      data => { 
        if(data && Object.keys(data).length > 0) {
          if(data.loading === true) {
            this.galleryimages = [];
            this.origgalleryimages = [];
            this.galleryloading = true;
            this.galleryerrMsg = '';
          } else if(data && Object.keys(data).length > 0 && data['message'] === 'SUCCESS' && data['status'] === true) {
            this.totalcount = data['data'][0].TOTAL;
            this.pagenumber = data['data'][0].PAGE_NUMBER;
            this.galleryloading = false;
            this.galleryimages = data['data'];
            this.origgalleryimages = Object.assign([], data['data']);
            this.galleryerrMsg = '';
          } else {
            this.galleryerrMsg = data['data'];
            this.galleryloading = false;
            this.galleryimages = [];
            this.origgalleryimages = [];
          }
        } else if(data && data.length == 0 || data === undefined) {
          this.galleryimages = [];
          this.origgalleryimages = [];
          this.galleryloading = false;
          this.galleryerrMsg = '';
        }
      } 
    );

  }

  //fn to get schedule data list 
  getScheduleData() {
    let res = this.customerservice.scheduleData;
    res.subscribe(
      data => {  
        if(data && Object.keys(data).length > 0) {
          if(data.loading === true) {
            this.scheduledata = [];
            this.origScheduledata = [];
            this.scheduleLoading = true;
            this.scheduleerr = '';
          } else if(data && Object.keys(data).length > 0 && data['message'] === 'SUCCESS' && data['status'] === true) {
            this.scheduleLoading = false;
            this.scheduledata = data['data'];;
            this.origScheduledata = data['data'];;
            this.scheduleerr = '';
          } else if(data && Object.keys(data).length > 0 && data['message'] === 'ERROR' && data['status'] === false){
            this.scheduledata = [];
            this.origScheduledata = [];
            this.scheduleerr = data['data'];
            this.scheduleLoading = false;
          }
        } else if(data && data.length == 0 || data === undefined) {
          this.scheduledata = [];
          this.origScheduledata = [];
          this.scheduleLoading = true;
          this.scheduleerr = '';
        }
      } 
    );
  }

  //fn to get purchase order

  getpurchaseorder() {
    let res = this.customerservice.purchaseorderData;
    res.subscribe(
      data => {  
        if(data && Object.keys(data).length > 0) {
          if(data.loading === true) {
            this.purchasedata = [];
            this.origpurchasedata = [];
            this.poLoading = true;
            this.purchaseerr = '';
          } else if(data && Object.keys(data).length > 0 && data['message'] === 'SUCCESS' && data['status'] === true) {
            this.poLoading = false;
            this.purchasedata = data['data'];
            this.origpurchasedata = data['data'];
            this.purchaseerr = '';
          } else if(data && Object.keys(data).length > 0 && data['message'] === 'ERROR' && data['status'] === false) {
            this.purchasedata = [];
            this.origpurchasedata = [];
            this.purchaseerr = data['data'];
            this.poLoading = false;
          }
        } else if(data && data.length == 0 || data === undefined) {
          this.purchasedata = [];
          this.origpurchasedata = [];
          // this.poLoading = true;
          this.purchaseerr = '';
        }
      } 
    );
  }

  //fn to get Bids List
  getBids() {
    let res = this.customerservice.bidsData;
    res.subscribe(
      data => {
        if(data && Object.keys(data).length > 0) {
          if(data.loading === true) {
            this.bidsdata = [];
            this.origbidsdata = [];
            this.bidsLoading = true;
            this.bidserr = '';
          } else if(data && Object.keys(data).length > 0 && data['message'] === 'SUCCESS' && data['status'] === true) {
            this.bidsLoading = false;
            this.bidsdata = data['data'];
            this.origbidsdata = data['data'];
            this.bidserr = '';
          } else if(data && Object.keys(data).length > 0 && data['message'] === 'ERROR' && data['status'] === false) {
            this.bidserr = data['data'];
            this.bidsLoading = false;
            this.bidsdata = [];
            this.origbidsdata = [];
          }
        } else if(data && data.length == 0 || data === undefined) {
          this.bidsdata = [];
          this.origbidsdata = [];
          // this.bidsLoading = true;
          this.bidserr = '';
        }
      } 
    );
  }

  //fn to get Punch Items List
  getPunchItems() {
    let res = this.customerservice.punchItemsData;
    res.subscribe(
      data => { 
        if(data && Object.keys(data).length > 0) {
          if(data.loading === true) {
            this.punchitemsData = [];
            this.origpuchitemsdata = [];
            this.punchLoading = true;
            this.puchitemserr = '';
          } else if(data && Object.keys(data).length > 0 && data['message'] === 'SUCCESS' && data['status'] === true) {
            this.punchLoading = false;
            this.punchitemsData = data['data'];
            this.origpuchitemsdata = data['data'];
            this.puchitemserr = '';
          } else if(data && Object.keys(data).length > 0 && data['message'] === 'ERROR' && data['status'] === false) {
            this.puchitemserr = data['data'];
            this.punchLoading = false;
            this.punchitemsData = [];
            this.origpuchitemsdata = [];
          }
        } else if(data && data.length == 0 || data === undefined) {
          this.punchitemsData = [];
          this.origpuchitemsdata = [];
          // this.punchLoading = true;
          this.puchitemserr = '';
        }
      } 
    );
  }

  //fn to get Warranty Po's List
  getWarrantyPo() {
    let res = this.customerservice.wpoData;
    res.subscribe(
      data => {  
        if(data && Object.keys(data).length > 0) {
          if(data.loading === true) {
            this.wpoData = [];
            this.origwpodata = [];
            this.warrentyLoading = true;
            this.wpoerr = '';
          } else if(data && Object.keys(data).length > 0 && data['message'] === 'SUCCESS' && data['status'] === true) {
            this.warrentyLoading = false;
            this.wpoData = data['data'];
            this.origwpodata = data['data'];
            this.wpoerr = '';
            if(this.scheduledata.length > 0) {
              this.subPopToggle = 1;
            } else if(this.purchasedata.length > 0 && this.scheduledata.length === 0) {
              this.subPopToggle = 2;
            } else if(this.punchitemsData.length > 0 && this.scheduledata.length === 0 && this.purchasedata.length === 0) {
              this.subPopToggle = 3;
            } else if(this.bidsdata.length > 0 && this.scheduledata.length === 0 && this.purchasedata.length === 0 && this.punchitemsData.length === 0) {
              this.subPopToggle = 4;
            } else if(this.wpoData.length > 0 && this.scheduledata.length === 0 && this.purchasedata.length === 0 && this.punchitemsData.length === 0 && this.bidsdata.length === 0) {
              this.subPopToggle = 5;
            }
          } else if(data && Object.keys(data).length > 0 && data['message'] === 'ERROR' && data['status'] === false) {
            this.wpoerr = data['data'];
            this.warrentyLoading = false;
            this.wpoData = [];
            this.origwpodata = [];
            if(this.scheduledata.length > 0) {
              this.subPopToggle = 1;
            } else if(this.purchasedata.length > 0 && this.scheduledata.length === 0) {
              this.subPopToggle = 2;
            } else if(this.punchitemsData.length > 0 && this.scheduledata.length === 0 && this.purchasedata.length === 0) {
              this.subPopToggle = 3;
            } else if(this.bidsdata.length > 0 && this.scheduledata.length === 0 && this.purchasedata.length === 0 && this.punchitemsData.length === 0) {
              this.subPopToggle = 4;
            } else if(this.wpoData.length > 0 && this.scheduledata.length === 0 && this.purchasedata.length === 0 && this.punchitemsData.length === 0 && this.bidsdata.length === 0) {
              this.subPopToggle = 5;
            }
          }
        } else if(data && data.length == 0 || data === undefined) {
          this.wpoData = [];
          this.origwpodata = [];
          // this.purchaseLoading = true;
          this.wpoerr = '';
        } 
      });
  }


  //fn to find if user has done a job search
  jobSearching() {
    let res = this.customerservice.searchingJob;
    res.subscribe(
      data => {
        if(data && data !=undefined && data.length > 0 && data[0].searchingjob) {
          this.searchingjob = true;
          this.docsList = [];
          this.galleryimages = [];
          this.reports = [];
          this.userorigdocsbckp = [];
          this.docerrMsg = langauge.NO_DOCS;
          this.galleryerrMsg = langauge.NO_IMAGES;
          this.reportserr = langauge.NO_REPORTS;
        } else if(data && data !=undefined && data[0].resetall) { 
          this.searchingjob = false;
          this.scheduledata = this.origScheduledata;
          this.purchasedata = this.origpurchasedata;
          this.bidsdata = this.origbidsdata;
          this.punchitemsData = this.origpuchitemsdata;
          this.wpoData = this.origwpodata;
          this.docsList = [];
          this.galleryimages = [];
          this.reports = [];
          this.userorigdocsbckp = [];
          this.docerrMsg = langauge.NO_DOCS;
          this.galleryerrMsg = langauge.NO_IMAGES;
          this.reportserr = langauge.NO_REPORTS;
        } else {
          this.searchingjob = false;
        }
    })
  }



  //fn getting filter job by job id
  getSelectedjobid() {
    let res = this.customerservice.planjobid;
    res.subscribe(
      data => {
        this.searchingjob = false;
        if(data && data.length > 0 && data[1].jobselected) {
          if(this.origScheduledata != undefined && this.origScheduledata.length > 0) {
            this.scheduledata = this.origScheduledata.filter((u) => {
              if(u.JOB_ID == data[0].jobid) {
                return u;
              }
            })
            this.nojoberrMsg = '';
          }
          if(this.origpurchasedata && this.origpurchasedata != undefined && this.origpurchasedata.length > 0) {
            this.purchasedata = this.origpurchasedata && this.origpurchasedata != undefined && this.origpurchasedata.filter((u) => {
              if(u.JOB_ID == data[0].jobid) {
                return u;
              }
            })
            this.nojoberrMsg = '';
          }
          if(this.origbidsdata != undefined && this.origbidsdata.length > 0) {
            this.bidsdata = this.origbidsdata && this.origbidsdata != undefined && this.origbidsdata.filter((u) => {
              if(u.JOB_ID == data[0].jobid) {
                return u;
              }
            })
            this.nojoberrMsg = '';
          }
          if(this.origpuchitemsdata != undefined && this.origpuchitemsdata.length > 0) {
            this.punchitemsData = this.origpuchitemsdata && this.origpuchitemsdata != undefined && this.origpuchitemsdata.filter((u) => {
              if(u.JOB_ID == data[0].jobid) {
                return u;
              }
            })
            this.nojoberrMsg = '';
          }
          if(this.origwpodata != undefined && this.origwpodata.length > 0) {
            this.wpoData = this.origwpodata && this.origwpodata != undefined && this.origwpodata.filter((u) => {
              if(u.JOB_ID == data[0].jobid) {
                return u;
              }
            })
            this.nojoberrMsg = '';
          }
          if(this.scheduledata && this.scheduledata.length === 0 && this.purchasedata && this.purchasedata.length === 0 && this.bidsdata && this.bidsdata.length === 0 &&
            this.punchitemsData && this.punchitemsData.length === 0 && this.wpoData && this.wpoData.length === 0)
          {
            this.nojoberrMsg = 'Job not assigned to Vendor.';
          }
        } else {
          this.nojoberrMsg = '';
          if(this.origScheduledata != undefined) {
            this.scheduledata = this.origScheduledata
          }
          if(this.origpurchasedata != undefined) {
            this.purchasedata = this.origpurchasedata
          }
          if(this.origbidsdata != undefined) {
            this.bidsdata = this.origbidsdata;
          }
          if(this.origpuchitemsdata != undefined) {
            this.punchitemsData = this.origpuchitemsdata
          }
          if(this.origwpodata != undefined) {
            this.wpoData = this.origwpodata;
          }
          this.docerrMsg = langauge.NO_DOCS;
          this.docsList = [];
          this.galleryerrMsg = langauge.NO_IMAGES;
          this.galleryimages = [];
        }
    })
  }


  //fn to get user selected job documents 
  getjobDocuments() {
    let res = this.customerservice.customerplansData;
    res.subscribe(
      data => {  
        if(data && Object.keys(data).length > 0) {
          if(data.loading === true) {
            this.docsList = [];
            this.docsloading = true;
            this.docerrMsg = '';
          } else if(data && Object.keys(data).length > 0 && data['message'] === 'SUCCESS' && data['status'] === true) { 
            this.docsloading = false;
            this.docsList = data['data'];
            this.userorigdocs = data['data'];
            this.docerrMsg = '';
          } else {
            this.docerrMsg = data['data'];
            this.docsloading = false;
          }
        } else if(data && data.length == 0 || data === undefined) {
          this.docsList = [];
          this.docsloading = true;
          this.docerrMsg = '';
        }
      } 
    );
  }

  //fn to get user select job report documents 
  getjobReports() {
    let res = this.customerservice.vendorJobReport;
    res.subscribe(
      data => {  
        if(data && Object.keys(data).length > 0) {
          if(data.loading === true) {
            this.reports = [];
            this.reportsloading = true;
            this.reportserr = '';
          } else if(data && Object.keys(data).length > 0 && data['message'] === 'SUCCESS' && data['status'] === true) {
            this.reportsloading = false;
            this.reports = data['data'];
            this.reportsOrig = data['data'];
            this.reportserr = '';  
          } else {
            this.reportserr = data['data'];
            this.reportsloading = false;
          }
        } else if(data && data.length == 0 || data === undefined) {
          this.reports = [];
          this.reportsloading = false;
          this.reportserr = langauge.NO_REPORTS;
        }
      } 
    );
  }

  //fetching customer bookmarks
  getcustomerbookmarks() {
    let res = this.customerservice.customerbookmarksList;
    res.subscribe(
      data => { 
        if(data && Object.keys(data).length > 0) {
          if(data.loading === true) {
            this.bookmarksLoading = true;
            this.bookmarks = [];
            this.bookmarkserr = '';
          } else if(data && Object.keys(data).length > 0 && data['message'] === 'SUCCESS' && data['status'] === true) {
            this.bookmarks = data['data'];
            this.bookmarkserr = "";
            this.bookmarksLoading = false;
          } else {
            this.bookmarkserr = data['data'];
            this.bookmarksLoading = false;
          }
        } else if(data && data.length == 0 || data === undefined) {
          this.bookmarks = [];
          this.bookmarkserr = '';
          this.bookmarksLoading = false;
        }
      }   
    );
  }

   //fetching builder customer contacts
  getBuilderContacts() {
    let res = this.customerservice.vendorcontactsList;
    res.subscribe(
      data => { 
        if(data && Object.keys(data).length > 0) {
          if(data.loading === true) {
            this.contactsLoading = true;
            this.contacts = [];
            this.contactsserr = '';
          } else if(data['message'] === 'SUCCESS' && data['status'] === true) {
            this.contacts = data['data'];
            this.contactsserr = "";
            this.contactsLoading = false;
          } else if(data['message'] === 'ERROR' && data['status'] === false) {
            this.contactsserr = data['data'];
            this.contactsLoading = false;
          }
        } else if(data && data.length == 0 || data === undefined) {
          this.contacts = [];
          this.contactsserr = '';
          this.contactsLoading = false;
        }
      }   
    );
  }


  //fn for fetching schedule reports
  scheduleReports(company_id,jobid,vendorid,i,jobno,activity_id) {
    const isDesktopDevice = this.deviceService.isDesktop(); 
    this.dashboardreportsLoading = true;
    this.selectedpo = i;
    this.selectedThing = 'schedule';
    let response = this.api.get_vendor_schedule_report_url(this.userdata.data.WEB_VENDOR_ID,company_id,jobid,vendorid,jobno,activity_id);
    response.subscribe(res => {
      if (Object.keys(res).length > 0) {
        if(res['message'] === 'SUCCESS' && res['status'] === true) {
          this.pdfViewerEnabled = window.navigator['pdfViewerEnabled'] ? window.navigator['pdfViewerEnabled']:false;
          if(res['data'].URL_REPORT_PATH != "") {
            this.dashboardreportsLoading = false;
            this.doctype = 'pdf';
            this.urlexists = 1;
            let urlres = this.transform(res.data.URL_REPORT_PATH);
            this.reportsDesc = urlres;
            if(isDesktopDevice) {
              this.openDocumentCentered(this.divView);
            } else {
              let download= res.data.tempAssetUrl;
              this.downloadDocument(download,res.data.assetName);
            }
          } else {
            let urlres = this.transform(res['data'].URL_REPORT_PATH);
            this.reportsDesc = urlres;
            this.urlexists = 0;
            this.dashboardreportsLoading = false;
            this.openDocumentCentered(this.divView);
          }
        } else {
          this.dashboardreportsLoading = false;
        }
      }
    });
  }

  //fn for fetching schedule reports
  jobSelectionReports(mid,filename) {
    const isDesktopDevice = this.deviceService.isDesktop(); 
    this.jobselectionreportsLoading = true;
    this.selectedpo = mid;
    this.doctype = 'pdf';
    let response = this.api.get_vendor_job_report_url(this.userdata.data.WEB_VENDOR_ID,this.companyDetails.COMPANY_ID,this.legalDetails.JOB_ID);
    response.subscribe(res => {
      if (Object.keys(res).length > 0) {
        if(res['message'] === 'SUCCESS' && res['status'] === true) {
          this.pdfViewerEnabled = window.navigator['pdfViewerEnabled'] ? window.navigator['pdfViewerEnabled']:false;
          if(res['data'].URL_REPORT_PATH != "") { 
            this.urlexists = 1;
            this.jobselectionreportsLoading = false;
            let urlres = this.transform(res.data.URL_REPORT_PATH);
            this.reportsDesc = urlres;
            if(isDesktopDevice) {
              this.openDocumentCentered(this.divView);
            } else {
              let download= res.data.tempAssetUrl;
              this.downloadDocument(download,res.data.assetName);
            }
          } else {
            let urlres = this.transform(res['data'].URL_REPORT_PATH);
            this.reportsDesc = urlres;
            this.urlexists = 0;
            this.jobselectionreportsLoading = false;
            this.openDocumentCentered(this.divView);
          }
        } else {
          this.jobselectionreportsLoading = false;
        }
      }
    });
  }

  //fn for fetching purchase reports 
  purchaseReports(po_no,i,jobno) {
    const isDesktopDevice = this.deviceService.isDesktop(); 
    this.dashboardreportsLoading = true;
    this.selectedpo = i;
    this.selectedThing = 'purchase';
    this.doctype = 'pdf';
    let response = this.api.get_vendor_po_report_url(this.userdata.data.WEB_VENDOR_ID,po_no,jobno);
    response.subscribe(res => {
      if (Object.keys(res).length > 0) {
        if(res["message"] === "SUCCESS" && res["status"] === true) { 
          this.pdfViewerEnabled = window.navigator['pdfViewerEnabled'] ? window.navigator['pdfViewerEnabled']:false;
          if(res['data'].URL_REPORT_PATH != "") { 
            this.urlexists = 1;
            this.dashboardreportsLoading = false;
            let urlres = this.transform(res.data.URL_REPORT_PATH);
            this.reportsDesc = urlres;
            if(isDesktopDevice) {
              this.openDocumentCentered(this.divView);
            } else {
              let download= res.data.tempAssetUrl;
              this.downloadDocument(download,res.data.assetName);
            }
          } else {
            let urlres = this.transform(res['data'].URL_REPORT_PATH);
            this.reportsDesc = urlres;
            this.urlexists = 0;
            this.openDocumentCentered(this.divView);
          }
        }  else {
          this.dashboardreportsLoading = false;
        }
      }
    });
  }

  //fn for fetching punch reports
  punchReports(company_id,jobid,vendorid,i,jobno,activity_id){
    const isDesktopDevice = this.deviceService.isDesktop(); 
    this.dashboardreportsLoading = true;
    this.selectedpo = i;
    this.selectedThing = 'punchreports';
    this.doctype = 'pdf';
    let response = this.api.get_vendor_punch_report_url(this.userdata.data.WEB_VENDOR_ID,company_id,jobid,vendorid,jobno,activity_id);
    response.subscribe(res => {
      if (Object.keys(res).length > 0) {
        if(res["message"] === "SUCCESS" && res["status"] === true) { 
          this.pdfViewerEnabled = window.navigator['pdfViewerEnabled'] ? window.navigator['pdfViewerEnabled']:false;
          if(res['data'].URL_REPORT_PATH != "") { 
            this.urlexists = 1;
            this.dashboardreportsLoading = false;
            let urlres = this.transform(res.data.URL_REPORT_PATH);
            this.reportsDesc = urlres;
            if(isDesktopDevice) {
              this.openDocumentCentered(this.divView);
            } else {
              let download= res.data.tempAssetUrl;
              this.downloadDocument(download,res.data.assetName);
            }
          } else {
            let urlres = this.transform(res['data'].URL_REPORT_PATH);
            this.reportsDesc = urlres;
            this.urlexists = 0;
            this.openDocumentCentered(this.divView);
          }
        } else {
          this.dashboardreportsLoading = false;
        }
      }
    });
  }

  //fn to fetch bids reports
  bidsReports(bid_id,jobid,vendorid,i,jobno) {
    const isDesktopDevice = this.deviceService.isDesktop(); 
    this.dashboardreportsLoading = true;
    this.selectedpo = i;
    this.selectedThing = 'bids';
    this.doctype = 'pdf';
    let response = this.api.get_vendor_bids_report_url(this.userdata.data.WEB_VENDOR_ID,this.companyDetails.COMPANY_ID,bid_id,jobid,vendorid,jobno);
    response.subscribe(res => {
      if (Object.keys(res).length > 0) {
        this.pdfViewerEnabled = window.navigator['pdfViewerEnabled'] ? window.navigator['pdfViewerEnabled']:false;
        if(res["message"] === "SUCCESS" && res["status"] === true) {
          if(res['data'].URL_REPORT_PATH != "") { 
            this.urlexists = 1;
            this.dashboardreportsLoading = false;
            let urlres = this.transform(res.data.URL_REPORT_PATH);
            this.reportsDesc = urlres;
            if(isDesktopDevice) {
              this.openDocumentCentered(this.divView);
            } else {
              let download= res.data.tempAssetUrl;
              this.downloadDocument(download,res.data.assetName);
            }
          } else {
            let urlres = this.transform(res['data'].URL_REPORT_PATH);
            this.reportsDesc = urlres;
            this.urlexists = 0;
            this.openDocumentCentered(this.divView);
          }
        } else {
          this.dashboardreportsLoading = false;
        }
      }
    });
  }



  //fn to get reports for warrenty po
  warrentyReports(work_order_no,i,jobno) {
    const isDesktopDevice = this.deviceService.isDesktop(); 
    this.dashboardreportsLoading = true;
    this.selectedpo = i;
    this.selectedThing = 'warrenty';
    this.doctype = 'pdf';
    let response = this.api.get_warrenty_report_url(this.userdata.data.WEB_VENDOR_ID,work_order_no,jobno);
    response.subscribe(res => {
      if (Object.keys(res).length > 0) {
        if(res["message"] === "SUCCESS" && res["status"] === true) {
          this.pdfViewerEnabled = window.navigator['pdfViewerEnabled'] ? window.navigator['pdfViewerEnabled']:false;
          if(res['data'].URL_REPORT_PATH != "") { 
            this.urlexists = 1;
            this.dashboardreportsLoading = false;
            let urlres = this.transform(res.data.URL_REPORT_PATH);
            this.reportsDesc = urlres;
            if(isDesktopDevice) {
              this.openDocumentCentered(this.divView);
            } else {
              let download= res.data.tempAssetUrl;
              this.downloadDocument(download,res.data.assetName);
            }
          } else {
            let urlres = this.transform(res['data'].URL_REPORT_PATH);
            this.reportsDesc = urlres;
            this.urlexists = 0;
            this.openDocumentCentered(this.divView);
          }
        } else {
          this.dashboardreportsLoading = false;
        }
      }
    });
  }

  openDocumentpopup(id,doc,docname,filename) { 
    if(doc){
      this.pdfViewerEnabled = window.navigator['pdfViewerEnabled'] ? window.navigator['pdfViewerEnabled']:false;
      const isDesktopDevice = this.deviceService.isDesktop(); 
      let split = filename.split('.');
      let type1 = split[split.length-1];
      this.doctype = type1.toLowerCase();
      if(type1 === 'pdf' || type1 === 'PDF') {
        let urlres = this.transform(doc);
        this.urlexists = 1;
        this.reportsDesc = urlres;
    
        if(isDesktopDevice) {
          this.openDocumentCentered(this.divView);
        } else {
          let download= doc;
          this.downloadDocument(download,docname);
        }
      } else {
        let urlres = doc;
        this.reportsDesc = urlres;
        this.urlexists = 1;
        if(isDesktopDevice) {
          this.openDocumentCentered(this.divView);
        } else {
          let download=doc;
          this.downloadDocument(download,docname);
        }
      }
    }else{
      this.modalRef = this.modalService.open(this.notFound, { centered: true,windowClass:'not-found-model'});
    }
  }

   // fn to transform file urls into a secure url
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  //fn to view files in a popup modal
  openDocumentCentered(Doccontents) {
    this.modalRef = this.modalService.open(Doccontents, { centered: true , windowClass: 'img-class'});
  }

  downloadDocument(download,docname) {
    saveAs(download,docname);
    this.downloadsuccess = true;
    setTimeout(() => {
      this.downloadsuccess = false;
    }, 2000);
  }


  downloaddoc(doc) {
    let file =doc.split('.');
    if(file && file.length > 0) {
      let filename = file[2].split('/');
      saveAs(doc,filename[2]);
    }    
  }

  downloadpdf(doc) {    
    let file = doc.changingThisBreaksApplicationSecurity;
    let fileSplit = file.split('/');
    let fileName = fileSplit[fileSplit.length-1];
    if(fileName && fileSplit.length > 0) {
      saveAs(file,fileName);
    }    
  }

  openDocument(Doccontents) {
    this.modalRef = this.modalService.open(Doccontents, { centered: true });
  }

  openReport(Reports) {
    this.modalRef = this.modalService.open(Reports, { centered: true });
  }

  setsubpopup(val) {
    this.subPopToggle = val;
  }

  //for tab buttons
  openTabButon(val) {
    this.loginPopToggle = val;
  }


  //fn to sort documents list in ascending or descending order
  sortDocs(val) {
    if(val === 1 && this.ascensort) {
      this.ascensort = false;
      this.docsList = Object.assign([], this.userorigdocs).reverse();
    } if(val === 0 && !this.ascensort) {
      this.ascensort = true;
      this.docsList = Object.assign([], this.userorigdocs);
    }
  }


    //fn to sort reports list in ascending or descending order
    sortReports(val) {
      if(val === 1 && this.reportascensort) {
        this.reportascensort = false;
        this.reports = Object.assign([], this.reportsOrig).reverse();
      } if(val === 0 && !this.reportascensort) {
        this.reportascensort = true;
        this.reports = Object.assign([], this.reportsOrig);
      }
    }

  //fn to sort documents list date wise
  sortDocsbyDate(val) {
    if(val === 0 && this.datesort) {
      this.datesort = false;
      this.docsList.sort(function(a,b){
        return (<any>new Date(b.MODIFICATION_DATE)) - (<any>new Date(a.MODIFICATION_DATE));
      });
    } else if(val === 1 && !this.datesort) {
      this.datesort = true;
      this.docsList.sort(function(a,b){
        return (<any>new Date(a.MODIFICATION_DATE)) - (<any>new Date(b.MODIFICATION_DATE));
      });
    }
  }

  //sort assets
  sortassets(val) {
    if(val===0 && !this.assetsascensort) {
      this.assetsascensort = true;
      this.galleryimages.sort(function(a,b) {
        return a.ASSET_NAME.toString().localeCompare(b.ASSET_NAME);
      });
    } else if(val===1 && this.assetsascensort) {
      this.assetsascensort = false;
      this.galleryimages.sort(function(a,b) {
        return b.ASSET_NAME.toString().localeCompare(a.ASSET_NAME);
      });
    } 
  }


 /* image gallery start */
  openGallery(galleryContent,obj,type){
    this.modalRef = this.modalService.open(galleryContent, { centered: false });
    this.addGalleryItem(obj,type);
  }

  addGalleryItem(obj,type) {
    let arr =this.galleryimages.slice();
    let init_img = arr[arr.indexOf(obj)].FILE_NAME.split('.');
    // this.imagesrc = arr[arr.indexOf(obj)].ASSET_ID+'-'+arr[arr.indexOf(obj)].FILE_NAME+'.jpg';
    this.imagetodownload = arr.indexOf(obj);
    this.galleryOptions = [
      {
        width: '100%',
        height: '450px',
        startIndex : arr.indexOf(obj),
        thumbnailsColumns: 7,
         thumbnailsPercent: 23,
        // thumbnailsMargin: 100,
        // thumbnailMargin: 10,
        arrowPrevIcon:'fa fa-chevron-left',
        arrowNextIcon:'fa fa-chevron-right',
        preview: false

      },
      //max-width 1920
      {
        breakpoint: 1920,
        width: '100%',
        height: '450px',
        imagePercent: 80,
        thumbnailsPercent: 30,
        thumbnailsMargin: 5,
        thumbnailMargin: 11,
        thumbnailsColumns: 7,
      },
      // max-width 1440 
      {
        breakpoint: 1440,
        width: '100%',
        height: '450px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 5,
        thumbnailMargin: 8,
        thumbnailsColumns: 7,
      },
      // max-width1024
      {
        // breakpoint: 1024,
        // width: '100%',
        // height: '450px',
        // imagePercent: 65,
        // thumbnailsPercent: 35,
        // thumbnailsMargin: 5,
        // thumbnailMargin: 5,
        // thumbnailsColumns: 4,

        breakpoint: 1024,
        width: '100%',
        height: '450px',
        imagePercent: 80,
        thumbnailsPercent: 30,
        thumbnailsMargin: 5,
        thumbnailMargin: 5,
        thumbnailsColumns: 4,
      },

      // max-width 992
      {
        breakpoint: 992,
        width: '100%',
        height: '450px',
        imagePercent: 80,
        thumbnailsPercent: 30,
        thumbnailsMargin: 5,
        thumbnailMargin: 5,
        thumbnailsColumns: 4,
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '350px',
        imagePercent: 70,
        thumbnailsPercent: 30,
        thumbnailsMargin: 5,
        thumbnailMargin: 5,
        thumbnailsColumns: 3,
      },

      // max-width 676
      {
        breakpoint: 676,
        width: '100%',
        height: '350px',
        imagePercent: 60,
        thumbnailsPercent: 30,
        thumbnailsMargin: 7,
        thumbnailMargin: 7,
        thumbnailsColumns: 3,
        // breakpoint: 676,
        //     width: '100%',
        //     height: '250px',
        //     thumbnailsPercent: 40,
        //     thumbnailsMargin: 7,
        //     thumbnailMargin: 7,
        //     thumbnailsColumns: 3,
      },
      // max-width 400
      {

        // breakpoint: 400,
        // width: '100%',
        // height: '340px',
        // thumbnailsPercent: 30,
        // thumbnailsMargin: 5,
        // thumbnailMargin: 5,
        // thumbnailsColumns: 3,
        breakpoint: 400,
            width: '100%',
            height: '350px',
            thumbnailsPercent: 30,
            thumbnailsMargin: 7,
            thumbnailMargin: 7,
            thumbnailsColumns: 3,
      },
      // max-width320 
      {

        // breakpoint: 320,
        // width: '100%',
        // height: '250px',
        // imagePercent: 60,
        // thumbnailsPercent: 40,
        // thumbnailsMargin: 5,
        // thumbnailMargin: 5,
        // thumbnailsColumns: 3,

        breakpoint: 320,
            width: '100%',
            height: '250px',
            imagePercent: 60,
            thumbnailsPercent: 30,
            thumbnailsMargin: 7,
            thumbnailMargin: 7
      },

    ];
    this.popupImages = [];
    arr.map((u)=> {
    let img_name = u.FILE_NAME.split('.');
    let obj = {
      small:  u.URL_FILE_PATH,
      medium:  u.URL_FILE_PATH,
      big:  u.URL_FILE_PATH,
      label: u.ASSET_NAME,
      url:""
    }
  this.popupImages.push(obj)
  })
  }
  
  downloadImage(imageUrl: string, imageName: string) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', imageUrl, true);
    xhr.responseType = 'blob';
  
    xhr.onload = () => {
      if (xhr.status === 200) {
        const blob = new Blob([xhr.response], { type: 'image/jpeg' });
        saveAs(blob, imageName);
      }
    };
  
    xhr.send();
  }
  
  onChange(data: any): void {
    this.imagesrc = data.image.url;
    this.imagetodownload = data.index;
  }


  //fn to sort dashboard list 
  
  scheduleSort(val){
    if(this.scheduledata && this.scheduledata.length > 0) {
      this.selectedkey= val;
      if(val === 'jobid') {
        if(!this.reversesort) {
          this.scheduledata.sort((a, b) => parseInt(a.JOB_NO) - parseInt(b.JOB_NO));
          this.reversesort = true;
        } else {
          this.scheduledata.sort((a, b) => parseInt(b.JOB_NO) - parseInt(a.JOB_NO));
          this.reversesort = false;
        }
      } else if(val === 'cname') {
        if(!this.namesort) {
          this.scheduledata.sort((a,b) => {
            return a.CUSTOMER_NAME.toUpperCase() < b.CUSTOMER_NAME.toUpperCase() ? -1: 1;
          });
          this.namesort = true;
        } else {
          this.scheduledata.sort((a,b) => {
            return a.CUSTOMER_NAME.toUpperCase() < b.CUSTOMER_NAME.toUpperCase() ? 1: -1;
          });
          this.namesort = false;
        }
      } else if(val === 'activity') {
        if(!this.activity) {
          this.scheduledata.sort((a,b) =>  {
            return a.ACTIVITY_DESC.toUpperCase() < b.ACTIVITY_DESC.toUpperCase() ? -1: 1;
          });
          this.activity = true;
        } else {
          this.scheduledata.sort((a,b) =>  {
            return a.ACTIVITY_DESC.toUpperCase() < b.ACTIVITY_DESC.toUpperCase() ? 1: -1;
          });
          this.activity = false;
        }
      }  else if(val === 'startdate') {
        if(!this.startdatesort) {
          this.scheduledata.sort(function(a,b){
            return (<any>new Date(a.ACTUAL_START_DATE)) - (<any>new Date(b.ACTUAL_START_DATE));
          });
          this.startdatesort = true;
        } else {
          this.scheduledata.sort(function(a,b){
            return (<any>new Date(b.ACTUAL_START_DATE)) - (<any>new Date(a.ACTUAL_START_DATE));
          });
          this.startdatesort = false;
        }
      } else if(val === 'enddate') {
        if(!this.enddatesort) {
          this.scheduledata.sort(function(a,b){
            return (<any>new Date(a.ACTUAL_FINISH_DATE)) - (<any>new Date(b.ACTUAL_FINISH_DATE));
          });
          this.enddatesort = true;
        } else {
          this.scheduledata.sort(function(a,b){
            return (<any>new Date(b.ACTUAL_FINISH_DATE)) - (<any>new Date(a.ACTUAL_FINISH_DATE));
          });
          this.enddatesort = false;
        }
      }
    }
  }

  //fn to sort assets date wide
  sortassetsbydate(val) {
    if(val === 0 && this.assetsdatesort) {
      this.assetsdatesort = false;
      this.galleryimages.sort(function(a,b){
        return (<any>new Date(b.MODIFICATION_DATE)) - (<any>new Date(a.MODIFICATION_DATE));
      });
    } else if(val === 1 && !this.assetsdatesort) {
      this.assetsdatesort = true;
      this.galleryimages.sort(function(a,b){
        return (<any>new Date(a.MODIFICATION_DATE)) - (<any>new Date(b.MODIFICATION_DATE));
      });
    }
  }
  
  //fn to sort bookmarks
booksort(val){
  if(this.bookmarks && this.bookmarks.length > 0){
    this.selectedkey= val;
    if(val=='bookname') {
      if(!this.namesort){
        this.bookmarks.sort((a,b)=> a.BOOKMARK_DESC.toString().localeCompare(b.BOOKMARK_DESC));
        this.namesort=true;
      } else{
        this.bookmarks.sort((a, b)=> b.BOOKMARK_DESC.toString().localeCompare(a.BOOKMARK_DESC));
        this.namesort = false;
      }
    }

  }
}



  //fn to sort purchase order 
  poSort(val) {
    if(this.purchasedata && this.purchasedata.length > 0) {
      this.selectedkey= val;
      if(val === 'pojobid') {
        if(!this.reversesort) {
          this.purchasedata.sort((a, b) => parseInt(a.JOB_NO) - parseInt(b.JOB_NO));
          this.reversesort = true;
        } else {
          this.purchasedata.sort((a, b) => parseInt(b.JOB_NO) - parseInt(a.JOB_NO));
          this.reversesort = false;
        }
      } else if(val === 'poname') {
        if(!this.namesort) {
          this.purchasedata.sort((a,b) => {
            return a.CUSTOMER_NAME.trim().toUpperCase() < b.CUSTOMER_NAME.trim().toUpperCase() ? -1: 1;
          });
          this.namesort = true;
        } else {
          this.purchasedata.sort((a,b) => {
            return a.CUSTOMER_NAME.trim().toUpperCase() < b.CUSTOMER_NAME.trim().toUpperCase() ? 1: -1;
          });
          this.namesort = false;
        }
      } else if(val === 'pono') {
        if(!this.pnosort) {
          this.purchasedata.sort((a, b) => parseInt(a.PO_NO) - parseInt(b.PO_NO));
          this.pnosort = true;
        } else {
          this.purchasedata.sort((a, b) => parseInt(b.PO_NO) - parseInt(a.PO_NO));
          this.pnosort = false;
        }
      } else if(val === 'startdate') {
        if(!this.startdatesort) {
          this.purchasedata.sort(function(a,b){
            return (new Date(a.PRINTED_DATE).getTime() - new Date(b.PRINTED_DATE).getTime());
          });
          this.startdatesort = true;
        } else {
          this.purchasedata.sort(function(a,b){
            return (new Date(b.PRINTED_DATE).getTime() - new Date(a.PRINTED_DATE).getTime());
          });
          this.startdatesort = false;
        }
      } else if(val === 'enddate') {
        if(!this.enddatesort) {
          this.purchasedata.sort((a, b) => parseFloat(a.PO_AMT) - parseFloat(b.PO_AMT));
          this.enddatesort = true;
        } else {
          this.purchasedata.sort((a, b) => parseFloat(b.PO_AMT) - parseFloat(a.PO_AMT));
          this.enddatesort = false;
        }
      }
    }
  }

  //fn to sort punch items 
  punchSort(val) {
    if(this.punchitemsData && this.punchitemsData.length > 0) {
      this.selectedkey= val;
      if(val === 'pnjobid') {
        if(!this.reversesort) {
          this.punchitemsData.sort((a, b) => parseInt(a.JOB_NO) - parseInt(b.JOB_NO));
          this.reversesort = true;
        } else {
          this.punchitemsData.sort((a, b) => parseInt(b.JOB_NO) - parseInt(a.JOB_NO));
          this.reversesort = false;
        }
      } else if(val === 'pncname') {
        if(!this.namesort) {
          this.punchitemsData.sort((a,b) => {
            return a.CUSTOMER_NAME.toUpperCase() < b.CUSTOMER_NAME.toUpperCase() ? -1: 1;
          });
          this.namesort = true;
        } else {
          this.punchitemsData.sort((a,b) =>  {
            return a.CUSTOMER_NAME.toUpperCase() < b.CUSTOMER_NAME.toUpperCase() ? 1: -1;
          });
          this.namesort = false;
        }
      } else if(val === 'activity') {
        if(!this.activity) {
          this.punchitemsData.sort((a,b) => {
            return a.ACTIVITY_DESC.toUpperCase() < b.ACTIVITY_DESC.toUpperCase() ? -1: 1;
          });
          this.activity = true;
        } else {
          this.punchitemsData.sort((a,b) =>  {
            return a.ACTIVITY_DESC.toUpperCase() < b.ACTIVITY_DESC.toUpperCase() ? 1: -1;
          });
          this.activity = false;
        }
      } else if(val === 'room') {
        if(!this.roomsort) {
          this.punchitemsData.sort((a,b) =>  {
            return a.ROOM_DESC.toUpperCase() < b.ROOM_DESC.toUpperCase() ? -1: 1;
          });
          this.roomsort = true;
        } else {
          this.punchitemsData.sort((a,b) => {
            return a.ROOM_DESC.toUpperCase() < b.ROOM_DESC.toUpperCase() ? 1: -1;
          });
          this.roomsort = false;
        }
      } else if(val === 'item') {
        if(!this.itemsort) {
          this.punchitemsData.sort((a,b) =>  {
            return a.ITEM_DESC.toUpperCase() < b.ITEM_DESC.toUpperCase() ? -1: 1;
          });
          this.itemsort = true;
        } else {
          this.punchitemsData.sort((a,b) =>  {
            return a.ITEM_DESC.toUpperCase() < b.ITEM_DESC.toUpperCase() ? 1: -1;
          });
          this.itemsort = false;
        }
      } else if(val === 'pnstartdate') {
        if(!this.startdatesort) {
          this.punchitemsData.sort(function(a,b){
            return (<any>new Date(a.PLI_CREATE_DATE)) - (<any>new Date(b.PLI_CREATE_DATE));
          });
          this.startdatesort = true;
        } else {
          this.punchitemsData.sort(function(a,b){
            return (<any>new Date(b.PLI_CREATE_DATE)) - (<any>new Date(a.PLI_CREATE_DATE));
          });
          this.startdatesort = false;
        }
      }
    }
  } 


  //fn for bids sort 
  bidsSort(val) {
    if(this.bidsdata && this.bidsdata.length > 0) {
      this.selectedkey= val;
      if(val === 'bjobid') {
        if(!this.reversesort) {
          this.bidsdata.sort((a, b) => a.JOB_NO - b.JOB_NO);
          this.reversesort = true;
        } else {
          this.bidsdata.sort((a, b) => b.JOB_NO - a.JOB_NO);
          this.reversesort = false;
        }
      } else if(val === 'bname') {
        this.bidsdata.map((u)=> {
          u["DUMMY_CUSTOMER_NAME"] = u.CUSTOMER_NAME == null ? "A" : u.CUSTOMER_NAME;
        });        
        if(!this.namesort) {
          this.bidsdata.sort((a,b) =>  {
            return a.DUMMY_CUSTOMER_NAME.toUpperCase() < b.DUMMY_CUSTOMER_NAME.toUpperCase() ? -1: 1;
          });
          this.namesort = true;
        } else {
          this.bidsdata.sort((a,b) =>  {
            return a.DUMMY_CUSTOMER_NAME.toUpperCase() < b.DUMMY_CUSTOMER_NAME.toUpperCase() ? 1: -1;
          });
          this.namesort = false;
        }
      }else if(val === 'biddesc') {
        if(!this.descriptionsort) {
          this.bidsdata.sort((a,b) =>  a.BID_DESC.toString().localeCompare(b.BID_DESC));
          this.descriptionsort = true;
        } else {
          this.bidsdata.sort((a,b) =>  b.BID_DESC.toString().localeCompare(a.BID_DESC));
          this.descriptionsort = false;
        }
      } else if(val === 'recieveon') {
        if(!this.startdatesort) {
          this.bidsdata.sort(function(a,b){
            return (<any>new Date(a.RECEIVED)) - (<any>new Date(b.RECEIVED));
          });
          this.startdatesort = true;
        } else {
          this.bidsdata.sort(function(a,b){
            return (<any>new Date(b.RECEIVED)) - (<any>new Date(a.RECEIVED));
          });
          this.startdatesort = false;
        }
      } else if(val === 'dueon') {
        if(!this.enddatesort) {
          this.bidsdata.sort(function(a,b){
            return (<any>new Date(a.DUE_DATE)) - (<any>new Date(b.DUE_DATE));
          });
          this.enddatesort = true;
        } else {
          this.bidsdata.sort(function(a,b){
            return (<any>new Date(b.DUE_DATE)) - (<any>new Date(a.DUE_DATE));
          });
          this.enddatesort = false;
        }
      }
      else if(val === 'bidstatus') {
        if(!this.bidstatus) {
          this.bidsdata.sort((a,b) =>  a.STATUS.toString().localeCompare(b.STATUS));
          this.bidstatus = true;
        } else {
          this.bidsdata.sort((a,b) =>  b.STATUS.toString().localeCompare(a.STATUS));
          this.bidstatus = false;
        }
      }
      else if(val === 'bidid') {
        if(!this.bididsort) {
          this.bidsdata.sort((a, b) => parseInt(a.BID_ID) - parseInt(b.BID_ID));
          this.bididsort = true;
        } else {
          this.bidsdata.sort((a, b) => parseInt(b.BID_ID) - parseInt(a.BID_ID));
          this.bididsort = false;
        }
      }
    }
  }


  //fn for warrenty po sort 
  warrentyPo(val) {
    if(this.wpoData && this.wpoData.length > 0) {
      this.selectedkey= val;
      if(val === 'wpojobid') {
        if(!this.reversesort) {
          this.wpoData.sort((a, b) => parseInt(a.JOB_NO) - parseInt(b.JOB_NO));
          this.reversesort = true;
        } else {
          this.wpoData.sort((a, b) => parseInt(b.JOB_NO) - parseInt(a.JOB_NO));
          this.reversesort = false;
        }
      } else if(val === 'wpocname') {
        if(!this.namesort) {
          this.wpoData.sort((a,b) =>  {
            return a.CUSTOMER_NAME.toUpperCase() < b.CUSTOMER_NAME.toUpperCase() ? -1: 1;
          });
          this.namesort = true;
        } else {
          this.wpoData.sort((a,b) =>  {
            return a.CUSTOMER_NAME.toUpperCase() < b.CUSTOMER_NAME.toUpperCase() ? 1: -1;
          });
          this.namesort = false;
        }
      } else if(val === 'wpo') {
        if(!this.pnosort) {
          this.wpoData.sort((a, b) => parseInt(a.WORK_ORDER_NO) - parseInt(b.WORK_ORDER_NO));
          this.pnosort = true;
        } else {
          this.wpoData.sort((a, b) => parseInt(b.WORK_ORDER_NO) - parseInt(a.WORK_ORDER_NO));
          this.pnosort = false;
        }
      } else if(val === 'startdate') {
        if(!this.startdatesort) {
          this.wpoData.sort(function(a,b){
            return (<any>new Date(a.CREATE_DATE)) - (<any>new Date(b.CREATE_DATE));
          });
          this.startdatesort = true;
        } else {
          this.wpoData.sort(function(a,b){
            return (<any>new Date(b.CREATE_DATE)) - (<any>new Date(a.CREATE_DATE));
          });
          this.startdatesort = false;
        }
      } else if(val === 'wpoamount') {
        if(!this.enddatesort) {
          this.wpoData.sort(function(a,b){
            return a.PO_AMT - b.PO_AMT;
          });
          this.enddatesort = true;
        } else {
          this.wpoData.sort(function(a,b){
            return b.PO_AMT - a.PO_AMT;
          });
          this.enddatesort = false;
        }
      }
    }
  }


  //fn to show hide in mobile view 
  hideMenu(e) {
    if(e.target.scrollTop > 60 && this.showmobmenu === false){
      this.showmobmenu = true;
    } else if(e.target.scrollTop < 60) {
      this.showmobmenu = false;
    }
  }


  //fn to show bids logs 
  raiseBids(bid_id,bid_vendor_id,job_id,job_no, vendor_id) {
    localStorage.setItem("vendor_id",vendor_id);
    this.router.navigate(
      ['/bidsubmit'],
      { queryParams: { 'bid_vendor_id': bid_vendor_id, 'job_no': job_no},queryParamsHandling:"merge"}

    );
  }
}
