import { Routes, RouterModule } from '@angular/router'; 
//header ,footer and homepage component
import { HeaderComponent } from './core/header/header.component';
import { HomepageComponent } from './modules/homepage/homepage.component';
//Bookmarks Component 
import { BookmarksComponent} from './modules/bookmarks/bookmarks.component';
//change password, login, logout
import {ChangePasswordComponent} from './modules/change-password/change-password.component';
import { LoginComponent } from './modules/login/login.component';
import { LogoutComponent } from './modules/logout/logout.component';
//contains header and footer routing
import { HomelayoutComponent } from './homelayout/homelayout.component';
import { AppComponent } from './app.component';
import { AuthGuard } from './auth.guard';
import { SidebarComponent } from './core/sidebar/sidebar.component';
import { BidsubmitComponent } from './modules/bidsubmit/bidsubmit.component';
import { BidsLayoutComponent } from './bidsLayout/bidslayout.component';
import { PagenotfoundComponent } from "./modules/pagenotfound/pagenotfound.component";
import { AdminLoginComponent } from './adminLogin/adminLogin.component';

export const AppRoutes:Routes=[
    // {
    //   path:'http://localhost:4201/',
    //   redirectTo:'http://localhost:4201/',
    //   pathMatch:'full',
    // },
    {
    path:'',
      component:AppComponent,
      children: [   
        {path:'', component: LoginComponent},
        {path:'login', component: LoginComponent},
        {path:'logout', component:LogoutComponent},
        {path:'authenticateAD', component:AdminLoginComponent},
        {path:'authentication-failed', component:AdminLoginComponent}
      ]
    },
    {
      
      path:'',
      component:HomelayoutComponent,
      children: [
        { path: '', component: HomepageComponent,canActivate: [AuthGuard] },
        { path: 'bookmarks', component: BookmarksComponent,canActivate: [AuthGuard] },
        { path: 'change-password', component: ChangePasswordComponent,canActivate: [AuthGuard] },
        { path: 'homepage', component: HomepageComponent,canActivate: [AuthGuard] },
        { path: 'header', component: HeaderComponent,canActivate: [AuthGuard] },
      ],
    },
    {
      
      path:'',
      component:BidsLayoutComponent,
      children: [
        {path:'bidsubmit', component: BidsubmitComponent,canActivate: [AuthGuard]},
      ],
    },
    { 
      path: '**', 
      component: PagenotfoundComponent,
    },
  ]
