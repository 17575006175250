import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { delay } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public isCollapsed = false;
  public isCollapsed2 = false;
  loggedUser:any;
  userdata: any;
  username: string;
  useremail: any;
  userprofile: string;
  getBacktoBidsCondtion:any;
  editBidsLenthForBackToBids:any = 0;
  addBidsLenthForBackToBids:any = 0;
  flagLenthForBackToBids:any = false;
  isLoggedIn:boolean = false;
  adminToken;
  adminData;

  constructor(private router: Router,private api: ApiService) {

    this.userdata = JSON.parse(localStorage.getItem('userdata')); 
    this.adminToken = localStorage.getItem('vendor_admin_token');// getting admin token
    this.adminData = this.parseJwt(this.adminToken);
    this.isLoggedIn = this.userdata ? true : false;
    // subscribe to home component messages
        this.getBacktoBidsCondtion = this.api.backToBidsHeaderCondtionGetfn().subscribe(message => {
          if (message) {
            this.editBidsLenthForBackToBids = message['editarray'];
            this.addBidsLenthForBackToBids = message['addarray'];
            this.flagLenthForBackToBids = message['secondSectionFlag'];
            //console.log(message);
          } else {
            // clear messages when empty message received
            this.editBidsLenthForBackToBids = [];
          }
        });
   }
  
  ngOnInit() {
    this.checkForToken();
    if (this.userdata) {
      let name = this.userdata.data.VENDOR_NAME ? this.userdata.data.VENDOR_NAME.split(' ') : '';
      //console.log("charAt>>>>>",name);
      this.username = this.userdata.data.VENDOR_NAME ? this.userdata.data.VENDOR_NAME : '';
      this.useremail = this.userdata.data.EMAIL_ADDRESS ? this.userdata.data.EMAIL_ADDRESS : '';
      // let name = middlename && lastname ? middlename.charAt(0):middlename && !lastname ? middlename.charAt(0):!middlename && lastname ? lastname.charAt(0):'';
      
      this.userprofile = (name[0]) ? name[0].charAt(0): ""+' '+(name[1]) ?name[1].charAt(0) : "";
    }
  }

  // This function decodes the admin token and returns the details
  parseJwt(token) {
    return token ? jwt_decode(token): '';
  }

  // This function will check if there is token in the local storage
  checkForToken(){
    this.api.checkLocalChanges().pipe(delay(100)).subscribe(
      ldata => {
       this.isLoggedIn = this.api.loggedIn();
       if(!this.isLoggedIn) this.api.logout();   
      }
    );
  }


  open(){
    var x = document.getElementById("myDIV");
    if (x.style.display === "none") {
      x.style.display = "block";
    }else {
      x.style.display = "none"; 
    }
  }
  togglebar() {
    var root = document.getElementsByTagName( 'html' )[0];
    var x = document.getElementById("sidebar-root");
    if(x.classList.contains("sidebarmobclose")) {
      root.classList.add('nav_open'); 
      x.classList.remove("sidebarmobclose");
      x.classList.add("sidebarmobopen");
      document.body.style.position = 'fixed';
    } else {
      root.classList.remove('nav_open'); 
      x.classList.add("sidebarmobclose");
      x.classList.remove("sidebarmobopen");
      document.body.style.position = 'initial';
    }
  }
  //function getting called when user clicks on logout button
  logout() {
    localStorage.removeItem('userdata');
    localStorage.removeItem('vendor_admin_token');
    this.router.navigate(['/logout']);
  }

  ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.getBacktoBidsCondtion.unsubscribe();
    }
}

