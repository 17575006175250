import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  bdata: any;
  builderData= new BehaviorSubject(this.bdata);                       //behaviour for storing user companies data on click of sidebar
  sdata: any;
  scheduleData= new BehaviorSubject(this.sdata);
  purchasedata: any;
  purchaseorderData= new BehaviorSubject(this.sdata);
  biddata: any;
  bidsData= new BehaviorSubject(this.biddata);                      //behaviour for storing user schedule data on click of sidebar
  punchdata: any;
  punchItemsData= new BehaviorSubject(this.punchdata); 
  podata: any;
  poscheduleData= new BehaviorSubject(this.podata);                      //behaviour for storing user schedule data on click of sidebar
  wpodata: any;
  wpoData= new BehaviorSubject(this.wpodata);     
  legaldata: any;
  builderlegalData= new BehaviorSubject(this.legaldata);              //behaviour for storing user companies legal address on click of sidebar
  contactsdata: any;
  customercontactsData= new BehaviorSubject(this.contactsdata);       //behaviour for storing user contact details on click of sidebar
  docsdata: any;
  customerdocsData= new BehaviorSubject(this.docsdata);               //behaviour for storing user documents list on click of sidebar
  imagesdata: any;
  vendorBuilderImagesList= new BehaviorSubject(this.imagesdata);             //behaviour for storing builder photos list on click of sidebar
  plansdata: any;
  customerplansData= new BehaviorSubject(this.plansdata);             //behaviour for storing user plans list on click of sidebar
  quotesdata: any;
  customerquotesData= new BehaviorSubject(this.quotesdata);           //behaviour for storing user quotes list on click of sidebar
  reportsdata: any;
  customerreportsData= new BehaviorSubject(this.reportsdata);         //behaviour for storing user reports list on click of sidebar
  homeid: any;
  planhomeid= new BehaviorSubject(this.homeid);                       //behaviour for storing homeid to get reports file 
  assetsList: any;                                                
  customerassetsList= new BehaviorSubject(this.assetsList);           //behaviour for storing user assets list for builder
  planassetsList: any;                                                
  customerplanassetsList= new BehaviorSubject(this.planassetsList);   //behaviour for storing user assets list for builders plan
  bookmarksList: any;                                                
  customerbookmarksList= new BehaviorSubject(this.bookmarksList);   //behaviour for storing user assets list for builders plan
  contactsList: any;                                                
  vendorcontactsList= new BehaviorSubject(this.contactsList);   //behaviour for storing user assets list for builders plan
  builderid: any;
  builderjobid= new BehaviorSubject(this.builderid);                       //behaviour for storing jobid
  jobid: any;
  planjobid= new BehaviorSubject(this.jobid);                       //behaviour for storing jobid
  jobsearch: any;
  searchingJob= new BehaviorSubject(this.jobsearch);                       //behaviour for storing jobid
  jobreport: any;
  vendorJobReport= new BehaviorSubject(this.jobreport);                       //behaviour for report list for builders


  constructor() {}

  builderDetails(obj) {
    this.builderData.next(obj);
  }
  builderLegaladdr(obj) {
    this.builderlegalData.next(obj);
  }
  customercontacts(obj) {
    this.customercontactsData.next(obj);
  }
  customerdocuments(obj) {
    this.customerdocsData.next(obj);
  }
  customerplandocs(obj) {
    this.customerplansData.next(obj);
  }
  customerquoteslist(obj) {
    this.customerquotesData.next(obj);
  }
  customerreportslist(obj) {
    this.customerreportsData.next(obj);
  }
  customerassetslist(obj) {
    this.customerassetsList.next(obj);
  }
  customerplanassetslist(obj) {
    this.customerplanassetsList.next(obj);
  }
  customerbookmarkslist(obj) {
    this.customerbookmarksList.next(obj);
  }
  vendorbuilderimages(obj) {
    this.vendorBuilderImagesList.next(obj);
  }
  vendorcontactslist(obj) {
    this.vendorcontactsList.next(obj);
  }
  scheduledata(obj){
    this.scheduleData.next(obj);
  }
  purchaseorder(obj) {
    this.purchaseorderData.next(obj);
  }
  bidsdata(obj) {
    this.bidsData.next(obj);
  }
  punchidata(obj) {
    this.punchItemsData.next(obj);
  }
  warrantypodata(obj){
    this.wpoData.next(obj);
  }
  vendorjobreport(obj) {
    this.vendorJobReport.next(obj);
  }
}


