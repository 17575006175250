<nav *ngIf="adminData" class="navbar navbar-light sticky-top admin-header">
  <span class="navbar-text text-white" style="font-weight: bold;">
    <i class='fas fa-user-friends' aria-hidden="true"></i> 
    <span class="ml-2">Assuming User:</span> 
    <span class="ml-1">{{adminData.admin_name}} ({{adminData.admin_email}})</span>
  </span>
  <span class="pull-right text-white" style="cursor: pointer; font-weight: bold;" (click)="logout()"> Logout</span>
</nav>

<div class="wrapper" *ngIf="isLoggedIn" [ngClass]="{ assumeUserHeaderMargin: adminData}">
  <div class="main-header">
    <!-- Logo Header -->
    <div class="logo-header" data-background-color="white">

      <a *ngIf="editBidsLenthForBackToBids > 0 || addBidsLenthForBackToBids > 0 || flagLenthForBackToBids" data-toggle="modal" data-target="#submitModalHome"
              class="logo">
        <img alt="AR Homes Logo new" class="navbar-brand" src="../assets/img/ARHomes_Horizontal.png" class="arlogo">
      </a>

      <a routerLink="/homepage" *ngIf="editBidsLenthForBackToBids == 0 && addBidsLenthForBackToBids == 0 && !flagLenthForBackToBids" class="logo">
        <img alt="AR Homes Logo new" class="navbar-brand" src="../assets/img/ARHomes_Horizontal.png" class="arlogo">
      </a>

      <button class="navbar-toggler sidenav-toggler ml-auto" type="button" data-toggle="collapse" data-target="collapse"
        aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon" (click)="togglebar()">
          <i class="fa fa-bars" style="color: #8d9498"></i>
        </span>
      </button>
      <!-- <button class="topbar-toggler more">
        <div class="avatar-sm float-left mr-2 ml-2">
          <img src="..\assets\img\logout.png" alt="..." class="avatar-img rounded-circle">
        </div>
      </button> -->
      <button class="more logout"><a (click)="logout()" style="background: #03798a;
        padding: 8px;
        border-radius:8px;
        font-size: 10px;
        color: #ffffff;  position: relative;
        top: -7px;">Logout</a></button>
      <div class="nav-toggle">
        <button class="btn btn-toggle toggle-sidebar" (click)="open()">
          <i class="fa fa-bars"></i>
        </button>
      </div>
    </div>
    <!-- End Logo Header -->

    <!-- Navbar Header -->
    <nav class="navbar navbar-header navbar-expand-lg" data-background-color="white">
      <div class="container-fluid">
        <div class="company-name">{{username}}</div>
        <ul class="navbar-nav topbar-nav ml-md-auto align-items-center">
          <!-- <li class="nav-item navlink ">
            <a routerLink='/homepage' routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:
              true}" class="active-but">Home</a>
          </li>
          <li class="nav-item navlink">
            <a routerLink='/bookmarks' routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:
              true}">Bookmarks</a>
          </li> -->
          <!-- <li class="nav-item navlink">
            <a routerLink='/change-password' >Change Password</a>
          </li> -->

          <li class="nav-item dropdown hidden-caret pdiv hidemob padleft-15">

            <div>
              <a class="dropdown-toggle profile-pic" data-toggle="dropdown" href="#" aria-expanded="false">
                <div class="user user_profile_root" [ngClass]="{ disableCursor: adminData}">
                  <!-- <div class="mr-2 ml-2 user_profile_back">
                      <div class="avatar-img rounded-circle proimg"><span class="user_profile_span">{{userprofile}}</span></div> -->
                      <!-- <img src="../assets/img/profile.jpg" alt="..." class="avatar-img rounded-circle"> -->
                  <!-- </div> -->
                  <div ngbDropdown id="dropdownBasic1" ngbDropdownToggle class="info pcnt">
                    <!-- <span class="hidelink michel">{{username}}</span><span class="caret"></span><br> -->
                    <span class="user-level hidelink">{{useremail}} <i class="fa fa-caret-down"></i></span>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                      <!-- <a class="userlink" routerLink='/homepage'><button class="dropdown-item">View Account</button></a> -->
                      <a class="userlink"><button  [disabled]="adminData" class="dropdown-item" (click)="logout()">Logout</button></a>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <!-- End Navbar -->
  </div>

  <div>
    <router-outlet></router-outlet>
  </div>