<section id="login" class="">
  <ngx-loading  [show]="loading" [config]="{
                backdropBackgroundColour: '#fff',
                primaryColour: '#000', 
                secondaryColour: '#000', 
                tertiaryColour: '#000' }"
                class="custom_loading">
  </ngx-loading>
    <div class="container-fluid">
        <div class="row justify-content-md-center bgimg">
            <div class="col-12 col-lg-6 col-md-8 col-sm-12 col-xs-12 padding-left-0">
                <div id="content" class="mob-hide bg-image" style="background-image: url(../../assets/img/login.png);"></div>
            </div>
            <div class="col-12 col-lg-6 col-md-12 col-sm-12 col-xs-12 centre"> 
                <div class="form-container">
                    <div id="logoimg"><img src="../../assets/img/ARHomes_Horizontal.png"></div>
                    <div class="tag"> Bringing Your Dream Home to Life</div>
                    <!-- <p class="login-content">
                        Use the login below to check in on the progress of your new luxury home.
                        If you don’t have an active AR Homes ® project, what are you waiting for? <a href="https://arhomes.com/#contactSection" target="_blank" class="contact_link">Contact</a> Us today to get started.
                    </p> -->
                    <h3 *ngIf="isAuthenticating && !loginConfirmationPop">We are logging you in, please wait...</h3>
                    <h3 *ngIf="!isAuthenticating && !loginConfirmationPop">Login failed, please try reloading the page!</h3>
                    <!-- <div class="inputs">
                        <form>
                            <mat-form-field>
                                <input matInput placeholder="Email ID" [(ngModel)]="email" name="email" type="email"/>
                            </mat-form-field><br>
                            <p *ngIf="this.erremail" class="emailerr_msg">{{erremail}}</p>
                            <mat-form-field>
                                <input matInput placeholder="Password" [(ngModel)]="password" name="password" type="password" />
                            </mat-form-field><br>
                            <p *ngIf="this.errpassword" class="err_msg">{{errpassword}}</p>
                            <p *ngIf="this.errMsg" class="err_msg">{{errMsg}}</p>
                            <div *ngIf="this.loading">
                                <ngx-loading [show]="loading" [config]="{
                                    backdropBackgroundColour: '#fff',
                                    primaryColour: '#000', secondaryColour: '#000', tertiaryColour: '#000' }"
                                    class="custom_loading">
                                </ngx-loading>
                            </div>
                            <button (click)="onSubmit()" type="submit" class="button-clipped">LOGIN</button><br>
                            <div class="psw">
                                <a class="view-file1" (click)="openForgotPassword(contents,'forgetpwd')">Need a login to view your home’s status?</a>
                            </div>
                        </form>
                    </div> -->
                    <div>
                  </div>
                </div>
                <p class="copyright text-center footer">
                   © AR Franchising, Inc. 2023 All rights reserved. 
                </p>
            </div>
        </div>
    </div>
</section>