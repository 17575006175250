export const langauge = {
    EMAIL_REQ: 'Email is required',
    EMAIL_VALID_REQ: 'Please enter valid email address',
    PASSWORD_REQ: 'Password is required',
    NEW_PWD_REQ: 'New password is required',
    CNF_PWD_REQ: 'Confirm password is required',
    INV_NEW_PWD: 'Invalid new password',
    PWD_UNMATCH: 'Password not matched',
    NO_IMAGES: 'No Photos.',
    NO_REPORTS: 'No Reports.',
    NO_DOCS:'No Documents.',
  };