import { Component, OnInit, ViewChild, HostListener, ElementRef} from '@angular/core';
import { Router,ActivatedRoute, ParamMap } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import * as moment from 'moment';
import {LocationStrategy} from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from 'file-saver'; 



@Component({
  selector: 'app-bidsubmit',
  templateUrl: './bidsubmit.component.html',
  styleUrls: ['./bidsubmit.component.scss']
})
export class BidsubmitComponent implements OnInit {
  modalRef: any;
  bidsHeadersLoading: boolean = false;
  bidsTablesDataLoading: boolean = false;
  loggedUser: any;
  errsearch: string = '';
  dataLoaded: boolean = false;
  filterItems: any = [];
  filterItemsCopy: any = [];
  newAttribute: any = {};
  moment: any = moment;
  // canEdit: boolean = false;
  isUpdated: boolean = false;
  localStorageData: any;
  deletedId: any;
  isDeleted: boolean = false;
  count: number = 1;
  disabled: boolean = false;
  disabledbutton: boolean = false;
  disableTextbox = false;
  statusClass = 'active';
  isTDChanged: boolean = false;
  searchvalue = "";
  selectedkey: any = "";
  noteSort:boolean =false;
  concreteSort: boolean = false;
  itemizationSort: boolean = false;
  locationSort: boolean = false;
  quantitySort: boolean = false;
  uomSort: boolean = false;
  unitCostSort: boolean = false;
  bidCostSort: boolean = false;
  taxSort: boolean = false;
  counter:any =1;
  isDataChanged: boolean = false;
  // contentEditable:boolean =false
  startProductBarPos = -1;
  RowEmptyFields: number = 0;
  rowHightLight: number = 0;
  itemProperty: any = ['ITEM_DESC','NEW_ITEM_QTY', 'MEASURE_UNIT', 'NEW_UNIT_COST', 'TAXABLE_IND', 'ITEM_NOTES']
  defaultProperty: any = ['NEW_ITEM_QTY', 'NEW_UNIT_COST', 'ITEM_NOTES']
  locationSelect: any = ['location1', 'location2', 'location3']
  umoSelect: any = ['Square Foot1', 'Square Foot2', 'Square Foot3']
  taxSelect : any = ['Y','N']
  bidByJobPrice: number = 0;
  bidDiscountType:string = '';
  bidDiscount: any = 0.00;
  bidDiscountWithsign:any = '0.00%';
  revisedFinalBid:any = 0;
  blankPrice:string = '';
  bidsItemErrMsg:string='';
  bidByItemflag:number = 0;
  //for dynamic values
  bidsHeaders:any = [];              //variable used to store bids headers data on API call
  bidsLogs: any;                //variable used to store bids logs data on API call
  dbitems:any;
  bidType: any = 'JOB';
  form: any;
  BID_ITEM_ID :any;
  itemtotal:any;
  pAllInclusiveInd:any
  pAllInclusiveIndCheced: any;
  pAllInclusiveCost:any;
  pBidDiscountAmt:any = '';
  pBidDiscountPct:any;
  pBidDiscountChecked:any;

  newquantitySort:boolean = false;

  showInput:boolean= true;
  hideInput:boolean= false;
  blankPrice0:any;
  displayErrorPopup:boolean =false;
  displaySuccessPopup:boolean =false;
 
  mobEditItemList:any=[];

  mobItemDesc:any;
  mobItemQuant: any;
  mobItemUnitDesc: any;
  mobUnitCost: any;
  mobTax: any;
  mobNotes: any;

  mobItemDescAdd:any;
  mobItemQuantAdd: any;
  mobItemUnitDescAdd: any;
  mobUnitCostAdd: any;
  mobTaxAdd: any;
  mobNotesAdd: any;
  array:any=[];
  VENDOR_ADDITION_IND: any;

  currentRowflag:any = -1;
  bidSubmitDisable:boolean = false;
  inclusiveCost:any;
  biddiscountamount :any;
  biddiscountperct :any;

  secondSectionArray:any = [];

  holdEditRowInArray:any = [];
  holdAddRowInArray:any = [];

  secondSectionArrayFlag = false;
  errorArray:any = [];

  loderBeforeApiLoad:boolean = false; 

  Bidstatus:any = false;

  bidTypeflag:any = false;

  bidsubmitLoder = false;
  urlexists: Number
  doctype: any;
  reportsDesc:any;
  downloadsuccess: boolean = false;
  selectedpo: any;
  selectedThing: any;
  dashboardreportsLoading: boolean = false;
  bidsubmitSuccessPopup = false;
  defaultDisablebutton = true;
  checkRevisedFinalBid = false;
  checkRevisedFinalBidSave = false;
  isShowDivIf =false;
  keypressed;
  cancelRequestCondtion = false;
  sortVariable:any;
  isMobile;
  addItemForm:FormGroup;
  editItemForm:FormGroup;
  submitForm:FormGroup;
  screenWidth:any;
  modalReference:any;
  addmodalReference:any;
  canceladdmodalReference:any;
  canceleditmodalReference:any;
  onTabClassUam:boolean = false;
  isdirty= true;
  cancelError=false;
  bidSubmitMessage:any = 0;
  percentdisabel:boolean = true;
  amountdisabel:boolean = true;
  bidsubmitError = false;
  bidsubmitmessagecounter:any = 500;
  edititemId:any;
  compareItemArrayFlag:boolean = false;
  editErrorMessage = false;
  queryParamObj = {}
  bidDetails = {
    bid_vendor_id: null,
    job_no: null,
    bid_id: null,
    p_vendor_id: null,
    p_company_id: null,
    job_id: null,
  }
  currencyNumPatern = /^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(\.[0-9][0-9])?$/;
  numPatern = /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/;
  isAssumeUser : boolean = false;

  //myArray holdEditRowInArray = [];

  @ViewChild('documentcontent', { static: true }) docContents;
  @ViewChild('edititemcontent', { static: true }) edititemcontent;
  @ViewChild('additemcontent', {  static: true }) additemcontent;
  @ViewChild('canceladditemcontent', { static: true }) canceladditemcontent;
  @ViewChild('canceledititemcontent', { static: true }) canceledititemcontent;

  @ViewChild("reportsdoccontents", {static: false} ) divView: ElementRef;
  @ViewChild('closebutton', {static: true}) closebutton; 
  price: any = "";

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.keypressed = event.keyCode;
    if(this.keypressed == 27){
      this.isShowDivIf = false;
    }
  }
   
  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {

    if((this.holdEditRowInArray.length > 0 || this.holdAddRowInArray.length > 0 || this.secondSectionArrayFlag) && !this.cancelRequestCondtion){
        event.returnValue = false;
    }
  }
  @HostListener('window:popstate', ['$event']) onPopState(event: Event) {
    if(this.holdEditRowInArray.length > 0 || this.holdAddRowInArray.length > 0 || this.secondSectionArrayFlag){
      // console.log(event);
       //alert('Once you click on Back Button, all your un-saved data will be lost.');
       let result = confirm("Once you click on Reload, all your un-saved data will be lost.");
       if (result) {
       }

    }
  }

  constructor(private formBuilder: FormBuilder,private deviceService: DeviceDetectorService,private sanitizer: DomSanitizer,private router: Router, private modalService: NgbModal, private api: ApiService,private Location: LocationStrategy,private route: ActivatedRoute) 
  { 
    this.route.queryParamMap.subscribe((params) => {
      let queryParamObj = { ...params};
      if(Object.keys(queryParamObj['params']).length){
        this.bidDetails.bid_vendor_id = queryParamObj['params'].bid_vendor_id;
        this.bidDetails.job_no  = queryParamObj['params'].job_no;
      }else {
        this.router.navigate(['/homepage']);
      }
    });
    this.isAssumeUser = this.api.isAssumeUser();
  }


  ngOnInit() {
    window.scrollTo(0, 0);
    if(this.isAssumeUser) this.Bidstatus = true; // disabling all CRUD operations for assume user
    this.is_mobile();
    this.editItemForm = this.formBuilder.group({
          edititemdesc: [null,Validators.required],
          editnewitemqty: [null,[
              Validators.required,
              Validators.pattern(this.numPatern),
            ]],
          editmeasureunitdesc: [null,Validators.required],
          editbidunitcost: [null,[
              Validators.required,
              Validators.pattern(this.currencyNumPatern),
            ]],
          edittaxableind: [null],
          edititemnote: [null],
          vendorAdditionInd:[null],
          biditemId:[null],
          itemqty:[null],
     });

    this.addItemForm = this.formBuilder.group({
      itemdesc: [null,Validators.required],
      newitemqty: [null,[
        Validators.required,
        Validators.pattern(this.numPatern),
      ]],
      measureunit: [null,Validators.required],
      bidunitcostvalue: [null,[
        Validators.required,
        Validators.pattern(this.currencyNumPatern),
      ]],
      taxableind: [null],
      itemnotes: [null],
    });

    this.submitForm = new FormGroup({
      sectionTitle: new FormControl(),
    });

    this.dataLoaded = true;
    this.getBidsHeaders();

    this.getBidsItemsMeasureUnit();
    this.backToBidsHeaderCondition(this.holdEditRowInArray.length ,this.holdAddRowInArray.length, this.secondSectionArrayFlag);
  }

      //fn to get bids headers
  getBidsHeaders() {
    this.bidsHeadersLoading = true;
    let response = this.api.gets_bids_headers(this.bidDetails.bid_vendor_id);
    response.subscribe(res => {
      if(res && Object.keys(res).length > 0 && res["message"] === "SUCCESS" && res["status"] === true) {
          //Calling Bit Items after Bid Headers 25 March 2021
          this.bidsHeadersLoading = false;
          this.bidsHeaders = res["data"];
          if(this.bidsHeaders.length){
            let bidsHeaderObj = this.bidsHeaders[0];
            if(bidsHeaderObj && bidsHeaderObj.p_retn_status == "SUCCESS") {
              if(bidsHeaderObj && bidsHeaderObj.p_vendor_id != localStorage.getItem('vendor_id')) {
                this.router.navigate(['/homepage']);
              }
              this.bidDetails.bid_id  = bidsHeaderObj.p_bid_id;
              this.bidDetails.p_vendor_id  = bidsHeaderObj.p_vendor_id;
              this.bidDetails.p_company_id = bidsHeaderObj.p_company_id;
              this.bidDetails.job_id = bidsHeaderObj.p_job_id;
              this.getBidsItems();
              this.bidType = bidsHeaderObj.p_bid_type;
              if(this.bidType != "JOB"){
                  this.bidTypeflag = true;
              }
              this.pAllInclusiveInd = bidsHeaderObj.p_all_inclusive_ind;
              this.inclusiveCost = bidsHeaderObj.p_all_inclusive_cost;
              this.biddiscountamount  =  bidsHeaderObj.p_bid_discount_amt;
              this.biddiscountperct = bidsHeaderObj.p_bid_discount_pct;
              let vendorStatusDesc = bidsHeaderObj.p_bid_vendor_status_desc;
              if(vendorStatusDesc != "Received" && vendorStatusDesc != "Expiring"){
                 this.Bidstatus = true;
              }
              //bid logs
              this.getBidsLogs(this.bidDetails.bid_id,this.bidDetails.bid_vendor_id);
            }
            else{
              this.router.navigate(['/homepage']);
            }
          }else {
            this.router.navigate(['/homepage']);
          }
        } else {
          this.bidsHeadersLoading = false;
          this.bidsHeaders = [];
        }
    })
  }

    //fn to get bids items
  getBidsItems() {
    this.bidsTablesDataLoading = true;    
    let response = this.api.gets_bids_items(this.bidDetails.bid_id,this.bidDetails.bid_vendor_id);
    response.subscribe(res => {
      if(res && Object.keys(res).length > 0 && res["message"] === "SUCCESS" && res["status"] === true) {
        this.bidsTablesDataLoading = false;
        this.filterItems = res["data"];
        this.filterItemsCopy = res["data"];
        //console.log(this.filterItems);
        this.getBidbyItem();
        this.setDefaultDataSecondSection(this.bidsHeaders,this.itemtotal);
        this.resetIds(this.filterItems);
      } else {
        this.bidsTablesDataLoading = false;
        this.filterItems = [];
        this.bidsItemErrMsg = "No Data Records Found."
      }
    })
  }

  //fn to get bids items location
  getBidsItemsMeasureUnit() {
    let response = this.api.gets_bids_items_measure_unit();
    response.subscribe(res => {
      if(res && Object.keys(res).length > 0 && res["message"] === "SUCCESS" && res["status"] === true) {
        this.umoSelect = res["data"];
      } else {
        this.umoSelect = [];
      }
    });
  }


  //fn to get bids logs
  getBidsLogs(localBidId,localBidVendorId) {
    let response = this.api.get_bids_logs(localBidId,localBidVendorId);
    response.subscribe(res => {
      if(res && Object.keys(res).length > 0 && res["message"] === "SUCCESS" && res["status"] === true) {
        this.bidsLogs = res["data"];
      } else {
        this.bidsLogs = [];
      }
    });
  }

  //fn to get bids items location
  addBitItems(bidItems) {
    this.loderBeforeApiLoad = true;
    let response = this.api.add_bid_items(this.bidDetails.bid_id,this.bidDetails.bid_vendor_id,this.bidDetails.p_vendor_id,this.bidDetails.p_company_id,bidItems);
    response.subscribe(res => {
      if(res && Object.keys(res).length > 0 && res["message"] === "SUCCESS" && res["status"] === true) {
        console.log(res["data"]);
        this.getBidsItems();
        this.displaySuccessPopup= true;
        this.loderBeforeApiLoad = false;
        this.compareItemArrayFlag = false;
        setTimeout(() => {
          this.displaySuccessPopup= false;
        }, 3000);
      } else {
      }
    })
  }

  //fn to update bids items location
  updateBitItems(updateBidItemsobj,itemstorageData) {
    this.loderBeforeApiLoad = true;
    let response = this.api.update_bid_items(updateBidItemsobj);
    response.subscribe(res => {
      if(res && Object.keys(res).length > 0 && res["message"] === "SUCCESS" && res["status"] === true) {
       // console.log(res);
        // this.getBidsItems();
        this.getBidsItems();
        //this.updateFlagInLocalStorage(itemstorageData);
        this.displaySuccessPopup= true;
        this.displayErrorPopup= false;
        this.loderBeforeApiLoad = false;
        this.compareItemArrayFlag = false;
        setTimeout(() => {
          this.displaySuccessPopup= false;
        }, 3000);
      } else {
        //console.log("update",res);
      }
    })
  }
  
  //fn to Delete bids items
  deleteBitItems(bid_item_id) {
    //console.log(bid_item_id);
    let response = this.api.delete_bid_items(bid_item_id);
    response.subscribe(res => {
      if(res && Object.keys(res).length > 0 && res["message"] === "SUCCESS" && res["status"] === true) {
         //console.log(res);
         //this.getBidsItems();
      } else {
        //console.log("delete",res);
      }
    })
  }

  //fn to submit bid
  bidSubmit(bidSubmitMessageText) {
    this.bidsubmitLoder = true;
    let response = this.api.vendor_bid_submit(this.bidDetails.bid_vendor_id,bidSubmitMessageText);
    response.subscribe(res => {
      if(res && Object.keys(res).length > 0 && res["message"] === "SUCCESS" && res["status"] === true) {
        this.bidsubmitLoder = false;
        this.bidsubmitSuccessPopup = true;
        this.getBidsHeaders();
        this.getBidsItems();
        //this.getBidsLogs();

      } else {

      }
    })
  }

  cancelRequest() {
     
    this.cancelRequestCondtion = true;
     //location.reload();
    //this.bidDiscountWithsign = '0.00%';
    this.getBidsHeaders();
    this.getBidsItems();
    this.getBidbyItem();
    this.holdAddRowInArray = [];
    this.holdEditRowInArray = [];
    this.secondSectionArray = [];
    this.secondSectionArrayFlag = false;
    this.defaultDisablebutton = true;
    this.checkRevisedFinalBid = false;
    this.RowEmptyFields = 0;
    this.backToBidsHeaderCondition(this.holdEditRowInArray.length ,this.holdAddRowInArray.length, this.secondSectionArrayFlag);
    
 
  }

  closeBidSubmitPopup() {
    document.getElementById('submitModal').click();
    this.bidsubmitLoder = false;
    this.bidsubmitSuccessPopup = false;
  }

  toggleDisplayDivIf() {
    this.isShowDivIf = !this.isShowDivIf;
  }

  addBidData() {
    
    //this.filterItems = JSON.parse(localStorage.getItem('filterItems'));
    //console.log(this.filterItems);
    
    if (this.RowEmptyFields == 0) {
      this.newAttribute = {};
      this.newAttribute = {id:0,BID_ID:this.bidDetails.bid_id,BID_VENDOR_ID:this.bidDetails.bid_vendor_id,NEW_ITEM_QTY:"",COMPANY_ID:this.bidDetails.p_company_id,VENDOR_ID:this.bidDetails.p_vendor_id,ITEM_DESC: "", ITEMIZATION_NO: "1", LOCATION_NAME: "", ITEM_QTY: "", MEASURE_UNIT: "", ORIGINAL_UNIT_COST: "", NEW_UNIT_COST: "", TAXABLE_IND: "N", ITEM_NOTES: "", VENDOR_ADDITION_IND: 'Y', NEW_ADD_ROW:'0'};
      this.filterItems.unshift(this.newAttribute);
      this.resetIds(this.filterItems);
      //console.log(this.filterItems);

     //console.log(this.filterItems);
      //localStorage.setItem("filterItems", JSON.stringify(this.filterItems));
      this.disabledbutton = true;
      this.checkCurrentRowEmptyFields(0);
      //this.resetIds(this.filterItems);
    }
    else {
     this.rowHightLight = 1;
     this.displayErrorPopup = true;
     this.displaySuccessPopup= false;
     setTimeout(() => {
      this.displayErrorPopup = false;
    }, 3000);
   }
    

  }


  removeDefaultText(value: any, selectedRow: any, property: any,index) 
  {
     if (property == 'ITEM_DESC' && value.target.textContent.trim() == "Vendor Item") {
         value.target.textContent = '';
     }
     if (property == 'NEW_ITEM_QTY' && value.target.value.trim() == "-") {
         value.target.value = '';
     }
     if (property == 'NEW_UNIT_COST' && value.target.value.trim() == "-") {
         value.target.value = '';
     }
     if(property == 'ITEM_NOTES' && value.target.textContent.trim() == "-") {
         value.target.textContent = '';
     }
  }

  updateData(value: any, selectedRow: any, property: any,index) {

    var textdata;

    if (property == 'ITEM_DESC' || property == 'ITEM_NOTES') {
      textdata = value.target.textContent;
    } else {
      textdata = value.target.value;
    }

    //console.log(value);
    //console.log("selectedRow id= " + index + " updatedValue= " + textdata + " property= " + property);

    if (property == 'ITEM_DESC') {
     
       if(value.target.textContent == 'Vendor Item'){
          value.target.textContent = '';
       }

       if(value.target.textContent == ''){
          value.target.textContent = 'Vendor Item';
       }

        if (textdata.length > 250) {
          value.target.textContent = '-';
          textdata = '-';
          alert('Character must be less then 250.');
        }
    }

    if(property == 'NEW_ITEM_QTY' && textdata == '-'){
        textdata = '';
    }

    if (property == 'ITEM_NOTES') {

      if(value.target.textContent.trim() == "-") {
         value.target.textContent = '';
      }

      if (textdata.length > 4000) {
        value.target.textContent = '-';
        textdata = '-';
        alert('Character must be less then 4000.');
      }
    }

    if (property == 'NEW_UNIT_COST') {
      if(textdata == '-'){
        textdata = '';
      }else{
        textdata = textdata.replace(/[^0-9.-]+/g,"");
      }
    }
    
   //let biditemArray = this.filterItems;
   //console.log(textdata);

    for(let i = 0; i < this.filterItems.length; i++) {
      //let fetchedRow = biditemArray[i];
      if (i == index) {
        this.filterItems[i][property] = textdata.trim();
        //this.filterItemsCopy[i]['UPDATEDROW'] = 'Y';
      }
    }
   

    if(selectedRow.BID_ITEM_ID != undefined)
    {
        var foundIndex = this.holdEditRowInArray.findIndex(x => x.BID_ITEM_ID == selectedRow.BID_ITEM_ID);
        if(foundIndex == -1){
          this.holdEditRowInArray.push(this.filterItems[index]);
        }
        else{
          this.holdEditRowInArray[foundIndex] = this.filterItems[index];
        }
    }
    else
    {  
       let addBidItemsdata = this.filterItems
              .filter(function (biditem) {
                    return biditem.BID_ITEM_ID == undefined;
                  });
        this.holdAddRowInArray = addBidItemsdata;
    }

    //console.log(this.holdAddRowInArray);

    this.backToBidsHeaderCondition(this.holdEditRowInArray.length ,this.holdAddRowInArray.length, this.secondSectionArrayFlag);
    this.defaultDisablebutton = false;
    this.checkCurrentRowEmptyFields(index);
    this.getBidbyItem();
    if (this.RowEmptyFields == 0) {
      this.rowHightLight = 0;
    }

  }

 

  mobupdateData(value: any,  property: any) {
    var textdata;
    textdata = value.target.value;
    value.preventDefault();
    

    if(property == 'NEW_UNIT_COST_MOB' || property == 'NEW_ITEM_QTY_MOB') {   
          const pattern = /\d*\.?\d?/g;
          let inputChar = String.fromCharCode(value.target.value);

          if (!pattern.test(inputChar)) {
              value.preventDefault();
          }
     }
    
    if (property == 'ITEM_DESC') {
      if (textdata.length > 250) {
        value.target.textContent = '-';
        textdata = '-';
        alert('Character must be less then 250.');
      }
    }
    if (property == 'ITEM_NOTES') {
      if (textdata.length > 4000) {
        value.target.textContent = '-';
        textdata = '-';
        alert('Character must be less then 4000.');
      }
    }

    this.compareItemArrayFlag = true;

  }

 showInputs() {
    this.showInput=true;
    this.hideInput=false;
    this.pBidDiscountPct=''; 
    this.bidDiscount = 0;
    this.percentdisabel = false;
    this.storeSecondSectiondata();
  }

  hideInputs(){
    this.showInput=false;
    this.hideInput=true;
    this.pBidDiscountAmt = '';
    this.bidDiscount = 0;
    this.amountdisabel = false;
    this.storeSecondSectiondata();
  }
  hiding(){
    this.showInput=true;
    this.hideInput=false;
  }
  showing(){
    this.showInput=false;
    this.hideInput=true;
  }
  Disable(event) {
    this.pAllInclusiveInd = 'N';
    this.disableTextbox = true;
    this.price = "$0";
    this.bidDiscount = 0;
    this.statusClass = 'not-active';
    this.pAllInclusiveInd
    this.getBidbyItem();
    this.pAllInclusiveCost = '';
    this.pBidDiscountPct = '';
    this.pBidDiscountAmt = '';
    this.storeSecondSectiondata();
  }
  changeVal(event) {
    let itemArr = this.filterItems.filter((row) => row.BID_ITEM_ID);
    this.filterItems = [...itemArr];
    this.holdAddRowInArray = [];
    this.getBidbyItem();
    this.RowEmptyFields = 0;    
    this.pAllInclusiveInd = 'Y';
    this.pAllInclusiveIndCheced = true;
    this.disableTextbox = false;
    this.statusClass = 'active';
    this.bidByJobPrice = 0; 
    this.bidDiscount = 0;
    this.pBidDiscountPct = '';
    this.pBidDiscountAmt = '';
    this.checkBidCalucation();
    this.storeSecondSectiondata();
  }

  storeSecondSectiondata(){
     let bid_discount_amt;
     let bid_discount_pct;
     let bid_allinclusive_ind = this.pAllInclusiveInd;
     let bid_allinclusive_cost = this.bidByJobPrice;
     if(this.pAllInclusiveInd == 'N')
     {
       bid_allinclusive_cost = 0;
     }
     
     if(this.bidDiscountType == "bidamountdiscount"){

         bid_discount_amt = this.bidDiscount;
         bid_discount_pct = 0;
     }
     else {

         bid_discount_pct = this.bidDiscount;
         bid_discount_amt = 0;
     }
     this.secondSectionArrayFlag = true;
     this.defaultDisablebutton = false;
     this.backToBidsHeaderCondition(this.holdEditRowInArray.length ,this.holdAddRowInArray.length, this.secondSectionArrayFlag);

    this.secondSectionArray = { bid_vendor_id:this.bidDetails.bid_vendor_id ,bid_discount_amt:bid_discount_amt, bid_discount_pct:bid_discount_pct ,bid_allinclusive_ind:bid_allinclusive_ind, bid_allinclusive_cost:bid_allinclusive_cost};
    //console.log(this.secondSectionArray);
  }

  bidsubmit() {
    this.router.navigate(['/bidsubmit']);
  }

  openBidDocument(Doccontents) {
    this.modalRef = this.modalService.open(Doccontents, { centered: true });
  }
  openAddPopup(additemcontent){
    this.addmodalReference = this.modalService.open(additemcontent, { centered: false });
  }
  opencancelAddpopup(){
    this.canceladdmodalReference = this.modalService.open(this.canceladditemcontent, { centered: true });

  }
  opencancelEditpopup(){
    this.canceleditmodalReference = this.modalService.open(this.canceledititemcontent, { centered: true });

  }

  cancelModelClose(){
    this.canceladdmodalReference.close();
  }

  Rowshow(){
    this.filterItems = JSON.parse(localStorage.getItem('filterItems'));

    this.newAttribute = {};
    this.newAttribute = {BID_ID:this.bidDetails.bid_id,BID_VENDOR_ID:this.bidDetails.bid_vendor_id,COMPANY_ID:this.bidDetails.p_company_id,VENDOR_ID:this.bidDetails.p_vendor_id,ITEM_DESC: "", ITEMIZATION_NO: "1", LOCATION_NAME: "", ITEM_QTY: "", MEASURE_UNIT: "", ORIGINAL_UNIT_COST: "", NEW_UNIT_COST: "", TAXABLE_IND: "", ITEM_NOTES: "", VENDOR_ADDITION_IND: 'Y'};
    this.filterItems.unshift(this.newAttribute);
    localStorage.setItem("filterItems", JSON.stringify(this.filterItems));
    this.disabledbutton = true;
    //this.checkCurrentRowEmptyFields();
    this.resetIds(this.filterItems);
  }

  //fn to get single bids item NEW_ITEM_QTY MEASURE_UNIT_DESC NEW_UNIT_COST ITEM_NOTES
  getSingleBitItems(itemid,formAction) {
    if(formAction=='edit'){
      //console.log("In formaction.....");
      this.modalReference =  this.modalService.open(this.edititemcontent, { centered: false});
    }else{
      this.cancelError=true;
      setTimeout(() => {
        this.cancelError=false;
      }, 3000);
     
    }
  

  //this.modalService.open(this.edititemcontent);
  // this.modalReference = this.modalService.open(this.edititemcontent, { centered: false});
  var formatter = new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD',});

  let mobileBiditems = this.filterItems;
  let BID_ITEM_ID = mobileBiditems[itemid]['BID_ITEM_ID'];
  let ITEM_DESC = mobileBiditems[itemid]['ITEM_DESC'];
  let ITEMIZATION_NO = mobileBiditems[itemid]['ITEMIZATION_NO'];
  let LOCATION_NAME = mobileBiditems[itemid]['LOCATION_NAME'];
  let ITEM_QTY = mobileBiditems[itemid]['ITEM_QTY'];
  let NEW_ITEM_QTY = mobileBiditems[itemid]['NEW_ITEM_QTY'];
  let MEASURE_UNIT_DESC = mobileBiditems[itemid]['MEASURE_UNIT'];
  let ORIGINAL_UNIT_COST = mobileBiditems[itemid]['ORIGINAL_UNIT_COST'];
  let NEW_UNIT_COST = mobileBiditems[itemid]['NEW_UNIT_COST'];
  if(NEW_UNIT_COST == '' || NEW_UNIT_COST == null){
    NEW_UNIT_COST = '';
  }else{
     NEW_UNIT_COST = formatter.format(NEW_UNIT_COST);
  }
  let NTAXABLE_IND = mobileBiditems[itemid]['TAXABLE_IND'];
  let ITEM_NOTES = mobileBiditems[itemid]['ITEM_NOTES'];

  this.VENDOR_ADDITION_IND = mobileBiditems[itemid]['VENDOR_ADDITION_IND'];
  if(this.bidType=='JOB' && formAction=='edit'){
  if (this.VENDOR_ADDITION_IND == 'N') {
    this.editItemForm = this.formBuilder.group({
      edititemdesc: [null],
      editnewitemqty: [null,[
        Validators.required,
        Validators.pattern(this.numPatern),
      ]],
      editmeasureunitdesc: [null],
      editbidunitcost: [null,[
        Validators.required,
        Validators.pattern(this.currencyNumPatern),
      ]],
      edittaxableind: [null],
      edititemnote: [null],
      vendorAdditionInd: [null],
      biditemId: [null],
      itemqty:[null],
    });

  }
  else {

    this.editItemForm = this.formBuilder.group({
      edititemdesc: [null, Validators.required],
      editnewitemqty: [null,[
        Validators.required,
        Validators.pattern(this.numPatern),
      ]],
      editmeasureunitdesc: ['all', Validators.required],
      editbidunitcost: [null,[
        Validators.required,
        Validators.pattern(this.currencyNumPatern),
      ]],
      edittaxableind: [null],
      edititemnote: [null],
      vendorAdditionInd: [null],
      biditemId: [null],
      itemqty: [null],
    });
  }
}else{
  
  if(formAction=='edit'){
    
  this.editItemForm = this.formBuilder.group({
    edititemdesc: [null],
    editnewitemqty: [null],
    editmeasureunitdesc: [null],
    editbidunitcost: [null,[
        Validators.required,
        Validators.pattern(this.currencyNumPatern),
      ]],
    edittaxableind: [null],
    edititemnote: [null],
    vendorAdditionInd: [null],
    biditemId: [null],
    itemqty: [null],
  });
}
}
  this.mobEditItemList = {
    
    itemindex: itemid, biditemid: BID_ITEM_ID, mobItemDesc: ITEM_DESC, itemizationno: ITEMIZATION_NO, locatonname: LOCATION_NAME,
    itemqty: ITEM_QTY, newitemqty: NEW_ITEM_QTY, measureunitdesc: MEASURE_UNIT_DESC,
    unitcost: ORIGINAL_UNIT_COST, bidunitcost: NEW_UNIT_COST, taxableind: NTAXABLE_IND, itemnote: ITEM_NOTES
  }
  
}

  searchtransform(searchValue: any): any {
    this.bidsItemErrMsg = "No Data Records Found.";
    //this.filterItemsCopy = this.filterItems;
    this.errsearch = '';
    //console.log(searchValue);
    if(searchValue != '') {
    this.filterItems = this.filterItemsCopy;
    let namepattern = /([\!\#\%\^\&\*\(\)\-\_\=\+\;\:\'\"\[\]\{\}\,\/\<\>\?])/;
    //this.filterItems = JSON.parse(localStorage.getItem('filterItems'));

    this.filterItems = this.filterItems.filter((val: any) => {
    //console.log("searchValue = " + searchValue);
    let itemDesc = "";
    let itemizationNo = "";
    let loc = "";
    let itemQty = "";
    let newItemQty = "";
    let uom = "";
    let originalUnitCost = "";
    let newUnitCost = "";
    let tax = "";
    let itemNotes = "";
    if (val.ITEM_DESC != null) {
        itemDesc = val.ITEM_DESC.toString();
    }
    if (val.ITEMIZATION_NO != null) {
        itemizationNo = val.ITEMIZATION_NO.toString();
    }
    if (val.LOCATION_NAME != null) {
        loc = val.LOCATION_NAME.toString();
    }
    if (val.ITEM_QTY != null) {
        itemQty = val.ITEM_QTY.toString();
    }
    if (val.NEW_ITEM_QTY != null) {
        newItemQty = val.NEW_ITEM_QTY.toString();
    }
    if (val.MEASURE_UNIT != null) {
        uom = val.MEASURE_UNIT.toString();
    }
    if (val.ORIGINAL_UNIT_COST != null) {
        originalUnitCost = val.ORIGINAL_UNIT_COST.toString();
    }
    if (val.NEW_UNIT_COST != null) {
        newUnitCost = val.NEW_UNIT_COST.toString();
    }
    if (val.TAXABLE_IND != null) {
        itemNotes = val.TAXABLE_IND.toString();
    }
    if (val.ITEM_NOTES != null) {
        itemNotes = val.ITEM_NOTES.toString();
    }
    let rVal = (itemDesc.toLowerCase().includes(searchValue.toLowerCase()) || itemizationNo.toLowerCase().includes(searchValue.toLowerCase()) ||loc.toLowerCase().includes(searchValue.toLowerCase()) || itemQty.toLowerCase().includes(searchValue.toLowerCase()) || newItemQty.toLowerCase().includes(searchValue.toLowerCase()) || uom.toLowerCase().includes(searchValue.toLowerCase()) || originalUnitCost.toLowerCase().includes(searchValue.toLowerCase()) || newUnitCost.toLowerCase().includes(searchValue.toLowerCase()) ||tax.toLowerCase().includes(searchValue.toLowerCase()) || itemNotes.toLowerCase().includes(searchValue.toLowerCase())); return rVal;
    })
    this.dataLoaded = true;
    return this.filterItems;
   }else{
      //this.getBidsItems();
     return this.filterItems = this.filterItemsCopy;
   }

  }

  checkCurrentRowEmptyFields(itemindex) {

    //console.log(itemindex);
    let checkStorage = this.filterItems; 
    let newAddEditdataProperty: any = ['ITEM_DESC', 'NEW_ITEM_QTY','MEASURE_UNIT', 'NEW_UNIT_COST', 'TAXABLE_IND'];
    let defaultItemProperty: any = ['NEW_ITEM_QTY','NEW_UNIT_COST'];
    let defaultItemKhbKeyHomeProperty: any = ['NEW_UNIT_COST'];
    if(checkStorage && checkStorage.length > 0){
     if(this.bidType == "JOB"){
          if(checkStorage[itemindex]['VENDOR_ADDITION_IND'] == 'N'){
            for (let i = 0; i < defaultItemProperty.length; i++) 
            {
              if (!checkStorage[itemindex][defaultItemProperty[i]]) {
                this.currentRowflag = itemindex;
                return this.RowEmptyFields = 1;
              }
            }
   
           }

           if(checkStorage[itemindex]['VENDOR_ADDITION_IND'] == 'Y'){
              for (let i = 0; i < newAddEditdataProperty.length; i++) 
              {
                if (!checkStorage[itemindex][newAddEditdataProperty[i]]) {
                  this.currentRowflag = itemindex;
                  return this.RowEmptyFields = 1;
                }
              }
             
           }
       }else{

           for (let i = 0; i < defaultItemKhbKeyHomeProperty.length; i++) 
           {
              if (!checkStorage[itemindex][defaultItemKhbKeyHomeProperty[i]]) {
                this.currentRowflag = itemindex;
                return this.RowEmptyFields = 1;
              }
           }
       }
       return this.RowEmptyFields = 0
    }

  }

  checkValue(value: any, selectedRow: any, property: any) {
    //alert('test');
    //console.log(value.target.textContent);
    if(property == 'TAXABLE_IND')
    {
      if (value) {
        var charCode = value.which;
      }
      else { return true; }
      if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123))
        return true;
      else
        return false;
    }
    
    if (property == 'ITEM_DESC' && value.target.textContent.length > 250) {
      value.preventDefault();
      return false;
    }

    if (property == 'ITEM_NOTES' && value.target.textContent.length > 4000) {
      value.preventDefault();
      return false;
    }

    if (property == 'ITEM_QTY' || property == 'NEW_UNIT_COST' || property == 'ORIGINAL_UNIT_COST' || property == 'ITEMIZATION_NO' || property == 'NEW_ITEM_QTY') {
     
      var charCode = (value.which) ? value.which : value.keyCode;
      if (charCode != 46 && charCode > 31
        && (charCode < 48 || charCode > 57))
        return false;

      return true;
    }

   
  }
  // editBidData() {
  //   this.canEdit = true;
  // }

   saveBidData() {
   
   //if(this.RowEmptyFields == 0) {

      if(this.holdAddRowInArray.length > 0)
      {
        let reverseobject = this.holdAddRowInArray.reverse();
        this.addBitItems(reverseobject);
        this.holdAddRowInArray = [];
      }
      
      if(this.holdEditRowInArray.length > 0)
      {
         var editBidItemsdata = this.holdEditRowInArray
              .filter(function (biditem) {
                    return biditem.BID_ITEM_ID !== undefined;
                  })
              .map((finalResult) => {
                if(finalResult.VENDOR_ADDITION_IND == 'Y' ){

                  
                      return {
                        BID_ITEM_ID: finalResult.BID_ITEM_ID,
                        ITEM_DESC: finalResult.ITEM_DESC,
                        NEW_ITEM_QTY: finalResult.NEW_ITEM_QTY,
                        MEASURE_UNIT:finalResult.MEASURE_UNIT,
                        NEW_UNIT_COST:finalResult.NEW_UNIT_COST, 
                        TAXABLE_IND:finalResult.TAXABLE_IND,
                        ITEM_NOTES:finalResult.ITEM_NOTES,
                        VENDOR_ADDITION_IND:finalResult.VENDOR_ADDITION_IND,
                      }
                }
                else{
                      if(this.bidType == "JOB")
                      {
                          return{
                             BID_ITEM_ID: finalResult.BID_ITEM_ID,
                             NEW_ITEM_QTY: finalResult.NEW_ITEM_QTY,
                             NEW_UNIT_COST:finalResult.NEW_UNIT_COST, 
                             ITEM_NOTES:finalResult.ITEM_NOTES,
                             VENDOR_ADDITION_IND:finalResult.VENDOR_ADDITION_IND,
                          }
                      }
                      else
                      {
                          return {
                             BID_ITEM_ID: finalResult.BID_ITEM_ID,
                             NEW_ITEM_QTY: finalResult.ITEM_QTY,
                             NEW_UNIT_COST:finalResult.NEW_UNIT_COST,
                             ITEM_NOTES:finalResult.ITEM_NOTES,
                           }
                      }
                }
          }); 
          //console.log("editBidItemsdata",editBidItemsdata);
         this.updateBitItems(editBidItemsdata,this.filterItems); 
         
         this.holdEditRowInArray = [];
      }
     
      if(Object.keys(this.secondSectionArray).length > 0)
      {
        
        this.vendoBidUpdate(this.secondSectionArray);
        this.secondSectionArray = [];
        this.secondSectionArrayFlag = false;
      } 
  }

  closeError(){
    this.displayErrorPopup=false;
  }

  closeSuccess(){
    this.displaySuccessPopup= false;
  }

  checkSavedData() {
    this.isDataChanged = false;
    this.clearContents();
  }

 acceptBid(){   
      this.bidSubmit(this.bidSubmitMessage); 
      //alert(this.bidSubmitMessage);
  }
  
  clearContents(){
    this.submitForm.reset();
    this.bidsubmitmessagecounter=500;
  }
  

  
  resetIds(arr: []) {
    for (let i = 0; i < this.filterItems.length; i++) {
      this.filterItems[i]['id'] = i;
    }
  }

  updateFlagInLocalStorage(arr: []){
    this.filterItems = arr;
    for (let i = 0; i < this.filterItems.length; i++) {
      this.filterItems[i]['UPDATEDROW'] = '';
    }
    //localStorage.setItem("filterItems", JSON.stringify(this.filterItems));
  }

  setDeleteId(itemSelectedRow: any,id) {

    //console.log(id);
    this.deletedId = id;
    this.BID_ITEM_ID = itemSelectedRow.BID_ITEM_ID;
  }

  deleteBidData() {
    
    this.filterItems.splice(this.deletedId, 1);
    this.holdAddRowInArray.splice(this.deletedId,1);
    if(!this.BID_ITEM_ID){
      let addBidItemsdata = this.filterItems.length ? this.filterItems.filter(function (biditem) {return !biditem.BID_ITEM_ID}): []
      if(addBidItemsdata.length != this.holdAddRowInArray.length){
        this.holdAddRowInArray = [...addBidItemsdata];
      }
    }else{
      this.deleteBitItems(this.BID_ITEM_ID);
    }

    this.resetIds(this.filterItems);
    
    let isemptyfield = this.checkCurrentRowEmptyFields(this.deletedId);
    //console.log(isemptyfield);
    if (isemptyfield == 0) {
      this.RowEmptyFields = 0;
     this.rowHightLight = 0;
    }
    if(this.disableTextbox){
      this.getBidbyItem();
    }
  }


  checkEmptyFieldonFocus(value: any, selectedRow: any, property: any) {
    let isemptyfield = this.checkCurrentRowEmptyFields(0);
    //console.log(isemptyfield);
    if (isemptyfield == 1 && selectedRow.id != 0) {
      this.rowHightLight = 1;
    }
  }

  changedTableData(value: any, selectedRow: any, property: any,index) {
    this.isTDChanged = true;
    if(property == 'MEASURE_UNIT'){
       //console.log('MEASURE_UNIT');
       this.onTabClassUam = true;
    }
    // this.contentEditable=false;
  }

 isActive(item) {
      return this.selectedkey === item;
  }
 //new sorting
  resetCounter() {
    if (this.counter > 1) {
      this.counter = 1;
    }
  }
  dataSorting(selectedValue: any) {
    
    if (this.selectedkey != selectedValue) {
      this.resetCounter();
    }


    this.selectedkey = selectedValue;
    let isAscending = false;
    if (this.counter % 2 == 0) {
      isAscending = false;
      //console.log("isAscending= " + isAscending);
    } else {
      isAscending = true;
      //console.log("isAscending= " + isAscending);
    }
    this.counter++;

    this.filterItems.sort((a: any, b: any) => {
      //console.log("a=" + a[selectedValue]);
     //console.log("b=" + b[selectedValue]);
      if (a[selectedValue] === b[selectedValue]) {
        return 0;
      }
      else if (a[selectedValue] === null) {
        return 1;
      }
      else if (b[selectedValue] === null) {
        return -1;
      }
      else if (isAscending) {
        return a[selectedValue] < b[selectedValue] ? -1 : 1;
      }
      else {
        return a[selectedValue] < b[selectedValue] ? 1 : -1;
      }
    });
  }
  
  changePrice(event:any,key:any){

    if(key == 'bidmode'){
      var textdata = event.target.value;
        if(isNaN(textdata)){
               textdata = event.target.value.replace(/[^0-9\.]/g,'');
               if(textdata.split('.').length>2) 
                   textdata =textdata.replace(/\.+$/,"");
          }
        event.target.value =  textdata;
        if(event.target.value != ''){
          let bidmode = event.target.value.replace(/[^0-9.-]+/g,"");
          this.bidByJobPrice = bidmode;
        }else{
          this.bidByJobPrice = 0;
        } 
    }
    if(key == 'biddiscount'){
        let biddiscount = event.target.value;
         if(isNaN(biddiscount)){
               biddiscount = event.target.value.replace(/[^0-9\.]/g,'');
               if(biddiscount.split('.').length>2) 
                   biddiscount =biddiscount.replace(/\.+$/,"");
          }
        event.target.value =  biddiscount;
        //console.log(this.bidByJobPrice);
        if(this.bidByJobPrice > 0){
           this.bidDiscount = event.target.value;
        }else{
          event.target.value = '';
        }
        if(event.target.value > 100){
          event.target.value = 0;  
        } 
    }
    if(key == 'bidamount'){
      let bidamount = event.target.value;
      if(isNaN(bidamount)){
               bidamount = event.target.value.replace(/[^0-9\.]/g,'');
               if(bidamount.split('.').length>2) 
                   bidamount =bidamount.replace(/\.+$/,"");
          }
        event.target.value =  bidamount;
       if(event.target.value != ''){
         this.bidDiscount = event.target.value.replace(/[^0-9.-]+/g,"");
       }else{
         this.bidDiscount = 0;
       }
      
    }
    this.checkBidCalucation(); 
    this.storeSecondSectiondata();

  }

  getTotal(filterItems) {
    
    let getAllbidcost:any = 0;
    let bidcost;
    let bidqty;
    let qty;
    let withoutDollerPrice;
    
      for (let i = 0; i < filterItems.length; i++) {
        bidcost = filterItems[i]['NEW_UNIT_COST'];
        bidqty = filterItems[i]['NEW_ITEM_QTY'];
        qty = filterItems[i]['ITEM_QTY'];

        if(this.bidType == "JOB"){
          if(bidcost != ''){
             if(bidqty == '' || bidqty == null){
                 bidqty = 1;
             }
             getAllbidcost = parseFloat(getAllbidcost) + bidqty * bidcost;
          }
        }else{
            if(bidcost != ''){
              if(qty == '' || qty == null){
                 qty = 1;
              }
              getAllbidcost = parseFloat(getAllbidcost) + qty * bidcost;
            }
        }
      }
      
      this.itemtotal = getAllbidcost;
  }

  currencyChange(event:any,key:any){
    ///console.log('focusout');
    if(key == 'bidmode'){
        if(event.target.value != ''){
           event.target.value = event.target.value.replace(/[^0-9.-]+/g,"");
           var formatter = new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD',});
           event.target.value = formatter.format(event.target.value);
        }
    }

    if(key == 'bidamount'){
        if(event.target.value != ''){
           event.target.value = event.target.value.replace(/[^0-9.-]+/g,"");
           var formatter = new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD',});
           event.target.value = formatter.format(event.target.value);
        }
    }

    if(key == 'biddiscount'){
        if(event.target.value != ''){
           event.target.value = event.target.value.replace(/[^0-9.-]+/g,"");
           event.target.value = parseFloat(event.target.value).toFixed(2);
        }
    }
    if(key == 'NEW_ITEM_QTY'){
        event.target.value = event.target.value.replace(/[^0-9.-]+/g,"");
        if(event.target.value != '' && event.target.value != '-'){
           let n = parseFloat(event.target.value).toFixed(2);
           let withCommas = Number(n).toLocaleString('en');
           event.target.value = (withCommas.indexOf(".")== -1) ? withCommas+'.00' : withCommas;
        }
    }
    if(key == 'NEW_UNIT_COST'){
        if(event.target.value != '' && event.target.value != '-'){
           event.target.value = event.target.value.replace(/[^0-9.-]+/g,"");
           var formatter = new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD',});
           event.target.value = formatter.format(event.target.value);
        }
    }
    
  }

  currencyBack(event:any,key:any){
     if(event.target.value != ''){
       event.target.value = event.target.value.replace(/[^0-9.-]+/g,"");
     }
     if(event.target.value == '-')
     {
       event.target.value = '';
     }
  }

  bidbyJobValidation(event:any,key:any){
    
    var keyCode = (event.which) ? event.which : event.keyCode;
    var str=event.target.value;
    
    if ((str.length==0) && (event.keyCode==46)) return false; // checking that length ==0 than not allow to enter '.'
    if ((str.indexOf('.')>=0) && (event.keyCode==46)) return false; // checking that if user already entered '.' than not allow to enter '.'
    
    if (keyCode != 46 && keyCode > 31
    && (keyCode < 48 || keyCode > 57))
    return false;
    
    return true;
    this.checkBidCalucation();
    }

  checkBidDiscountType(event:any){
     this.bidDiscountType = event.target.value;
     this.bidDiscount = 0;
     this.checkBidCalucation();
     //console.log('this.bidDiscountType',this.bidDiscountType);
  }

  checkBidCalucation(){
    let finalCalculation = 0;
    //console.log("bidDiscountType checkBidCalucation >>",this.bidDiscountType);
    //console.log("bidtype",this.bidType);
    if(this.bidType == "JOB")
    {

        //console.log("bidDiscountType",this.bidDiscountType);
        if(this.bidDiscountType == "bidpercentagediscount")
          {
            //console.log("bidDiscountcalulation",this.bidDiscount);
            if(this.bidDiscount > 100 || this.bidDiscount <= 0){
              this.bidDiscount = 0.00;
            } 
            if(this.bidDiscount == ''){
              this.bidDiscount = 0.00;
            }

           this.bidDiscountWithsign =  parseFloat(this.bidDiscount).toFixed(2)+'%';
     
           finalCalculation = this.bidByJobPrice - (this.bidByJobPrice*this.bidDiscount)/100;
           this.revisedFinalBid = finalCalculation.toFixed(2);

          }
          if(this.bidDiscountType == "bidamountdiscount")
          {
             if(this.bidDiscount == ''){
              this.bidDiscount = 0.00;
             }
            finalCalculation = this.bidByJobPrice-this.bidDiscount;
            this.revisedFinalBid = finalCalculation;
            this.bidDiscountWithsign =  '$'+parseFloat(this.bidDiscount).toFixed(2);
          }
       }
       else
       {
           this.revisedFinalBid = this.bidByJobPrice;
           //this.bidDiscountWithsign =  '$'+parseFloat(this.bidDiscount).toFixed(2);
       }

      // console.log("bidDiscountWithsign",this.bidDiscountWithsign);


       if(Math.sign(this.revisedFinalBid) == -1){
          this.checkRevisedFinalBid = true;
          //this.checkRevisedFinalBidSave = true;
          // setTimeout(() => {
          //   this.checkRevisedFinalBid = false;
          // }, 3000);
       }else{
         this.checkRevisedFinalBid = false;
         //this.checkRevisedFinalBidSave = false;
       }
  }

  remove_character(str:any, char_pos:any) {
      let part1 = str.substring(0, char_pos);
      let part2 = str.substring(char_pos + 1, str.length);
      return (part1 + part2);
  }

  getBidbyItem() {
    
    //console.log(getFromStorage);
    let getAllbidcost:any = 0;
    let bidcost;
    let bidqty;
    let qty;
    let withoutDollerPrice;
    //console.log(this.bidType);
      for (let i = 0; i < this.filterItems.length; i++) {
        bidcost = this.filterItems[i]['NEW_UNIT_COST'];
        bidqty = this.filterItems[i]['NEW_ITEM_QTY'];
        qty = this.filterItems[i]['ITEM_QTY'];
        if(this.bidType == "JOB"){
          if(bidcost != ''){
             if(bidqty == '' || bidqty == null){
                 bidqty = 1;
             }
             getAllbidcost = parseFloat(getAllbidcost) + bidqty * bidcost;
          } 
        }else{
            if(bidcost != ''){
              if(qty == '' || qty == null){
                 qty = 1;
              }
              getAllbidcost = parseFloat(getAllbidcost) + qty * bidcost;
            }
        }
      }

      if(this.pAllInclusiveInd == 'N' || this.bidType == "KHB" || this.bidType == "COM"){
        this.bidByJobPrice = getAllbidcost;
      }
      this.itemtotal = getAllbidcost;
      this.checkBidCalucation();
  }

  checkEmptyFiledInOldRows(selectedrow){

    let isemptyfield = this.checkCurrentRowEmptyFields(0);
    if (isemptyfield == 1 && selectedrow > 0) {
       this.rowHightLight = 1;
       this.displayErrorPopup = true;
       this.displaySuccessPopup= false;
       setTimeout(() => {
        this.displayErrorPopup = false;
      }, 3000);
    }
  }

  decimalValidation(txtvalue:any){
     let val = txtvalue;
      if(isNaN(txtvalue)){
         txtvalue = txtvalue.replace(/[^0-9\.]/g,'');
         if(txtvalue.split('.').length>2) 
             txtvalue =txtvalue.replace(/\.+$/,"");
       }
    return txtvalue;
  }




  //fn to Delete bids items
  vendoBidUpdate(secondSectionArray) {
    this.loderBeforeApiLoad = true;
    let response = this.api.vendor_bid_update(secondSectionArray);
    response.subscribe(res => {
      if(res && Object.keys(res).length > 0 && res["message"] === "SUCCESS" && res["status"] === true) {
         //console.log(res);
         this.displaySuccessPopup= true;
         this.loderBeforeApiLoad = false;
         setTimeout(() => {
          this.displaySuccessPopup= false;
        }, 3000);
        this.getBidsHeaders();
      } else {
        //console.log("delete",res);
      }
    })
  }

  //fn for set default values in second section using header api
  setDefaultDataSecondSection(headerdata,getAllbidcost){
        
         //console.log("pAllInclusiveIndCheced1",this.pAllInclusiveIndCheced);
         let bidbycost = this.inclusiveCost;
         let p_all_inclusive_ind:any =  this.pAllInclusiveInd;
         this.pAllInclusiveInd = p_all_inclusive_ind;

         if(this.pAllInclusiveInd == 'Y' && !this.bidTypeflag)
         {
           this.pAllInclusiveIndCheced = true;
           if(bidbycost == null || bidbycost == 0)
           {
             this.bidByJobPrice = bidbycost;
             this.pAllInclusiveCost = 0;
             this.getDiscountData(headerdata,bidbycost);
             this.checkBidCalucation();
           }
           else
           {
             var formatter = new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD',});
             this.pAllInclusiveCost = formatter.format(bidbycost);
             this.bidByJobPrice = bidbycost;
             this.getDiscountData(headerdata,bidbycost);
             this.checkBidCalucation();
           }
         }
        else 
        {
           //console.log("pAllInclusiveIndCheced1",this.pAllInclusiveIndCheced);
            this.pAllInclusiveCost = '';
            this.pAllInclusiveIndCheced = false;
            this.disableTextbox = true;
            this.bidByJobPrice = getAllbidcost;
            if(!this.bidTypeflag){
              this.getDiscountData(headerdata,getAllbidcost);
            }
            this.checkBidCalucation();
        }
  }

  getDiscountData(headerdata,bidbycost) {


   let p_bid_discount_amt:any =  this.biddiscountamount;
   let p_bid_discount_pct:any = this.biddiscountperct;

    if(p_bid_discount_amt == null || p_bid_discount_amt == '' || p_bid_discount_amt == 0){
      this.pBidDiscountAmt = '$0.00'; 
      this.bidDiscount = 0;
    }
    else 
    {
       this.pBidDiscountChecked = true;
       this.bidDiscount = p_bid_discount_amt;
       var formatter = new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD',});
       this.pBidDiscountAmt = formatter.format(p_bid_discount_amt);
       this.bidDiscountType = "bidamountdiscount";
       this.showInput = false;
       this.hideInput = true;
       this.amountdisabel = false;
      
    }

    if(p_bid_discount_pct == null || p_bid_discount_pct == '' ||  p_bid_discount_pct == 0){
      this.pBidDiscountPct = '0.00';
      //this.bidDiscount = 0;
    }
    else {
      this.bidDiscount = p_bid_discount_pct;
      this.pBidDiscountPct = parseFloat(p_bid_discount_pct).toFixed(2); // convert in to decimal
      this.bidDiscountType = "bidpercentagediscount" // add Discount type
      this.pBidDiscountChecked = false; // for checked
      this.showInput=true; // amount input show hide
      this.hideInput=false; // amount input show hide
      this.percentdisabel = false;
    }


        
  }

  //fn to view files in a popup modal
  openDocumentCentered(Doccontents) {
    this.modalRef = this.modalService.open(Doccontents, { centered: true , windowClass: 'img-class'});
  }
 // Back To Bids Logo Condtion Fn
  backToBidsHeaderCondition(editarray ,addarray, secondSectionFlag): void {
   this.api.backToBidsHeaderCondtionSetfn(editarray ,addarray, secondSectionFlag);
  }


  //fn to fetch bids reports
  bidsReports() {
    const isDesktopDevice = this.deviceService.isDesktop(); 
    this.dashboardreportsLoading = true;
    // this.selectedpo = i;
    this.selectedThing = 'bids';
    this.doctype = 'pdf';
    let userData = JSON.parse(localStorage.getItem('userdata'));
    let response = this.api.get_vendor_bids_report_url(
    userData["data"].WEB_VENDOR_ID,
    this.bidDetails.p_company_id,
    this.bidDetails.bid_id,
    this.bidDetails.job_id,
    this.bidDetails.p_vendor_id,
    this.bidDetails.job_no,
      // this.userdata.data.WEB_VENDOR_ID,this.companyDetails.COMPANY_ID,bid_id,jobid,vendorid,jobno
    );
    response.subscribe(res => {
      if (Object.keys(res).length > 0) {
        if(res["message"] === "SUCCESS" && res["status"] === true) {
          if(res['data'].URL_REPORT_PATH != "") { 
            this.urlexists = 1;
            this.dashboardreportsLoading = false;
            let urlres = this.transform(res.data.URL_REPORT_PATH);
            this.reportsDesc = urlres;
            if(isDesktopDevice) {
              this.openDocumentCentered(this.divView);
            } else {
              let download= res.data.tempAssetUrl;
              this.downloadDocument(download,res.data.assetName);
            }
          } else {
            let urlres = this.transform(res['data'].URL_REPORT_PATH);
            this.reportsDesc = urlres;
            this.urlexists = 0;
            this.openDocumentCentered(this.divView);
          }
        } else {
          this.dashboardreportsLoading = false;
        }
      }
    });
  }
  downloadDocument(download,docname) {
    saveAs(download,docname);
    this.downloadsuccess = true;
    setTimeout(() => {
      this.downloadsuccess = false;
    }, 2000);
  }

  /** Download doc funtion when viewing is disabled from browser **/

  downloaddoc(doc) {
    let file =doc.split('.');
    if(file && file.length > 0) {
      let filename = file[2].split('/');
      saveAs(doc,filename[2]);
    }    
  }

  downloadpdf(doc) {    
    let file = doc.changingThisBreaksApplicationSecurity;
    let fileSplit = file.split('/');
    let fileName = fileSplit[fileSplit.length-1];
    if(fileName && fileSplit.length > 0) {
      saveAs(file,fileName);
    }    
  }

  // fn to transform file urls into a secure url
  transform(url) {
  return this.sanitizer.bypassSecurityTrustResourceUrl(url);
}

is_mobile() {
  if(window.innerWidth >= 768){
      this.isMobile = 0;
  }else{
     this.isMobile = 1;
  }
}

addMobileItemData(addItemFormdata:any){

 let itemdesc = addItemFormdata.controls.itemdesc.value;
 let newitemqty = addItemFormdata.controls.newitemqty.value;
 let measureunit = addItemFormdata.controls.measureunit.value;
 let bidunitcostvalue = addItemFormdata.controls.bidunitcostvalue.value.replace(/[^0-9.-]+/g,"");
 let taxableind = addItemFormdata.controls.taxableind.value;
 let itemnotes = addItemFormdata.controls.itemnotes.value;

 //console.log(bidunitcostvalue);
 let addItemValues =  [{ITEM_DESC: itemdesc,NEW_ITEM_QTY:newitemqty,MEASURE_UNIT:measureunit,
                        NEW_UNIT_COST:bidunitcostvalue,TAXABLE_IND:taxableind,ITEM_NOTES:itemnotes}]

 if(itemdesc != '' && newitemqty != '' && measureunit != '' && bidunitcostvalue != '' && taxableind != ''){
    //console.log(addItemValues);
    this.addBitItems(addItemValues);
    this.addItemForm.reset({
          itemdesc : '',
          newitemqty:'',
          measureunit:'all',
          bidunitcostvalue:'',
          taxableind:'N',
          itemnotes:''
        });
    this.editErrorMessage = false;
 }else{
    this.editErrorMessage = true;
    setTimeout(() => {
          this.editErrorMessage = false;
        }, 3000);
 }
}

get f() { 
  return this.addItemForm.controls; }
get e() { 
  return this.editItemForm.controls; }
  


editMobileItemData(editItemFormdata:any){

   let itemqty = editItemFormdata.controls.itemqty.value;
   let edititemdesc = editItemFormdata.controls.edititemdesc.value;
   let editnewitemqty = editItemFormdata.controls.editnewitemqty.value;
   let editmeasureunitdesc = editItemFormdata.controls.editmeasureunitdesc.value;
   let editbidunitcost = editItemFormdata.controls.editbidunitcost.value.replace(/[^0-9.-]+/g,"");
   let edittaxableind = editItemFormdata.controls.edittaxableind.value;
   let edititemnote = editItemFormdata.controls.edititemnote.value;



   let biditemId = editItemFormdata.controls.biditemId.value;
   let vendorAdditionInd = editItemFormdata.controls.vendorAdditionInd.value;
   let editItemValues;

   if(this.bidType == "JOB"){
     if(vendorAdditionInd == 'Y')
     {
       if(edititemdesc != '' && editnewitemqty != '' && editmeasureunitdesc != '' && editbidunitcost != '' && edittaxableind != '') {
           editItemValues =  [{BID_ITEM_ID:biditemId,ITEM_DESC: edititemdesc,NEW_ITEM_QTY:editnewitemqty,MEASURE_UNIT:editmeasureunitdesc,
                          NEW_UNIT_COST:editbidunitcost,TAXABLE_IND:edittaxableind,ITEM_NOTES:edititemnote,VENDOR_ADDITION_IND:vendorAdditionInd}]
            this.editErrorMessage = false;
        }else{
            this.editErrorMessage = true;
        }
     }
     else
     {   
       if(editnewitemqty != '' && editbidunitcost != '') { 
          editItemValues =  [{BID_ITEM_ID:biditemId,NEW_ITEM_QTY:editnewitemqty,NEW_UNIT_COST:editbidunitcost,
                              ITEM_NOTES:edititemnote,VENDOR_ADDITION_IND:vendorAdditionInd}]
              this.editErrorMessage = false;
        }else{
              this.editErrorMessage = true;
        }
     }
   }
   else
   {
      if(editbidunitcost != '') { 
        editItemValues =  [{BID_ITEM_ID:biditemId,NEW_ITEM_QTY:itemqty,NEW_UNIT_COST:editbidunitcost,ITEM_NOTES:edititemnote}]
        this.editErrorMessage = false;
      }else{
        this.editErrorMessage = true;
      }
   }  
   if(!this.editErrorMessage){
     //console.log(editItemValues);
     this.updateBitItems(editItemValues,this.filterItems);
   }
    setTimeout(() => {
          this.editErrorMessage = false;
        }, 3000);
}

deleteMobileItemData(deleteItemData:any,deleteItemIndex){
   this.filterItems.splice(deleteItemIndex, 1);
   this.deleteBitItems(deleteItemData);
   //this.closebutton.nativeElement.click();
   //this.modalService.dismiss();
   this.modalReference.close();

}

resetAddItemForm(){
  this.cancelError=true;
        setTimeout(() => {
          this.cancelError=false;
        }, 3000);

  this.addItemForm.reset({
    itemdesc : '',
    newitemqty:'',
    measureunit:'all',
    bidunitcostvalue:'',
    taxableind:'N',
    itemnotes:''
  });

  this.compareItemArrayFlag = false;  
 }
closeEditPopup(){
  //console.log('priyank');
//  if(this.editItemForm.dirty){
//    this.isdirty=false;
//  } else{
//   this.isdirty=true;
//  }
  this.modalReference.close();
  //this.compareItemArrayFlag = false;

}
closeAddPopup(){
  this.resetAddItemForm();
  this.compareItemArrayFlag = false;
  this.addmodalReference.close(); 
}
cancelAddpopup(){
  this.resetAddItemForm();
  // 1 this.canceladdmodalReference.close(); 
}
canceleditpopup(itemindex){
 this.edititemId=itemindex;
  //  this.canceleditmodalReference.close(); 
  //  this.getSingleBitItems(this.mobEditItemList.itemindex,'cancel');
  // this.getSingleBitItems(this.mobEditItemList.itemid,'cancel');
   //console.log("dsdf...." + itemindex);
  this.cancelError=true;
        setTimeout(() => {
          this.cancelError=false;
        }, 3000);
  this.getSingleBitItems(this.edititemId,'cancel');
  this.compareItemArrayFlag = false;
}

cancelyesClick(){
  this.compareItemArrayFlag = false;
  this.modalReference.close();
}

saveSecondSectionData(){
  if(Object.keys(this.secondSectionArray).length > 0)
  {   
      this.vendoBidUpdate(this.secondSectionArray);
      this.secondSectionArray = [];
      this.secondSectionArrayFlag = false;
   }
}

textCounter(event){
  this.bidSubmitMessage = event.target.value;
  if(event.target.value.length > 500){
    event.preventDefault();
    event.target.value = event.target.value.slice(0,500); 
  }else{
    //this.bidsubmitmessagecounter = 500 - event.target.value.length;
    //this.bidSubmitMessage = event.target.value;
  } 
}

onInput(event,content: string) {
    if(content.length > 500){
         event.preventDefault();
         event.target.value = content.slice(0,500);
     }else{
    this.bidsubmitmessagecounter = 500 - content.length;
    this.bidSubmitMessage = content.slice(0,500);
    } 
  }

} // class end