<div class="change">
    <div class="container ">         
        <div class="row justify-content-md-center">
            <div class="col col-12 col-lg-6 col-md-8 col-sm-12 mx-auto bg-works">
                <form class="form-control padbot250 border-0 padding60 padtop35" id="doctorForm" >
                <div class="profile-name text-center">
                    <h3 class="m-bottom8">Change Password</h3>
                </div>
                <div class="row padtop35">
                    <div class="col col-12 col-lg-12 col-sm-12 pad-left4">
                        <div class="form-group">
                            <label class="m-bottom10">Old Password</label>
                            <input type="password" class="form-control" name="old_password"  >
                        </div>
                    </div>
                    <div class="col col-12 col-lg-12 col-sm-12 pad-left4">
                        <div class="form-group">
                            <label class="m-bottom10">New Password</label>
                            <input type="password" class="form-control" name="new_password"  >    
                        </div>
                    </div>
                    <div class="col col-12 col-lg-12 col-sm-12 pad-left4">
                        <div class="form-group">
                            <label class="m-bottom10">Confirm New Password</label>
                            <input type="password" class="form-control" name="conf_password" >
                        </div>
                    </div>
                    <div class="col col-12 col-lg-12 col-sm-12 pad-left4 mx-auto text-center">
                        <div class="inputs1">
                            <a class="button-clipped">Submit</a><br>
                        </div>
                    </div>
                </div>
                </form>
            </div>
        </div>
    </div>
</div>
