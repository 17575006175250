<section id="login">
    <div class="login-root">
        <div class="container-fluid">
            <div class="row login-back-wall">
                <div class="col-12 col-lg-6 col-md-8 col-sm-12 col-xs-12 padding-left-0">
                    <div id="content" class="mob-hide bg-image" style="background-image: url(./../../assets/img/login.png);"></div>
                </div>
                <div class="col-12 col-lg-6 col-md-12 col-sm-12 col-xs-12 centre">
                    <div class="form-container padbot-15">
                        <div id="logoimg">
                            <img src="./../../assets/img/ARHomes_Vertical.png">
                        </div>  
                        <div class="tag"> Welcome to AR Homes® Vendor portal</div>
                        <div class="inputs">
                            <form>
                                <label for="exampleInputEmail1" class="self-contrl-lbl">
                                    <i class="fa fa-envelope adjust-icon"></i> Email id
                                </label>
                                <input type="email" class="form-control self-form-control" placeholder="abc.xyz@gmail.com" [(ngModel)]="email" name="email">
                                <p *ngIf="this.erremail" class="err_msg">{{erremail}}</p>
                                <label for="exampleInputPassword1" class="self-contrl-lbl">
                                    <i class="fa fa-lock adjust-icon"></i> Password
                                </label>
                                <input type="password" class="form-control self-form-control pwd-spacing" placeholder="Password" [(ngModel)]="password" name="password">
                                <p *ngIf="this.errpassword" class="err_msg">{{errpassword}}</p>
                                <p *ngIf="this.errMsg" class="err_msg">{{errMsg}}</p>
                                <div *ngIf="this.loading" class="loader-sp">
                                    <ngx-loader [loading]="this.loading"></ngx-loader>
                                </div>
                                <button (click)="login()" type="submit" class="button-clipped">LOGIN</button><br>
                            </form>
                            <div class="psw">
                                <a class="view-file1" (click)="openForgotPassword(contents,'forgetpwd')">Forgot your password?</a>
                            </div>
                            <div *ngIf="isIEBrowser" class="psw p-4 cursorauto ienote">
                            <span class="note-head">Note</span>: This browser is not supported. For best in-app experience we highly recommend using Google Chrome, Microsoft Edge or Safari.
                            </div>
                        </div>
                        <div>
                            <ng-template #contents let-c="close" let-d="dismiss">
                                <div class="login-modal">
                                    <div class="login-box forgotbox" >
                                        <div class="frgt">
                                            <div class="close-i" (click)="d('Cross click')">
                                                <i class="fa fa-times" aria-hidden="true"></i>
                                            </div>
                                            <div class="fgt-popup" *ngIf="!changepassword">
                                                <div id="membership1" class="w3-container w3-border Buton">
                                                    <div class="file-box">
                                                        <p class="reset-content">
                                                            Please contact your Building Company Representative to reset your password.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="inputs" *ngIf="changepassword">
                                                <div class="tag"> Please update your password</div>
                                                <div class="pwd-tool-tip"><img src="assets/img/tootip.svg" height="22px;"/></div>
                                                <div class="tool-tip-msg"> Password length should be 8 to 30 alphanumeric characters. Having mimimum of one UpperCase, one lowercase,one number.</div>
                                                <form class="fgt-pwd-sp">
                                                    <mat-form-field>
                                                        <input matInput placeholder="New Password" [(ngModel)]="newpassword" name="newpassword" [type]="hide ? 'text' : 'password'" />
                                                        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                                                    </mat-form-field>
                                                    <p *ngIf="this.errnewpwd" style="color: red;">{{errnewpwd}}</p>
                                                    <mat-form-field>
                                                        <input matInput placeholder="Confirm Password" [(ngModel)]="confirmpassword" name="confirmpassword" [type]="cnfhide ? 'text' : 'password'" />
                                                        <mat-icon matSuffix (click)="cnfhide = !cnfhide">{{cnfhide ? 'visibility' : 'visibility_off'}}</mat-icon>
                                                    </mat-form-field>
                                                    <p *ngIf="this.errcnfpwd" style="color: red;">{{errcnfpwd}}</p>
                                                    <p *ngIf="this.successmsg" style="color: green;">{{successmsg}}</p>
                                                    <div *ngIf="this.updateloading" class="update_loader">
                                                        <ngx-loader [loading]="this.updateloading"></ngx-loader>
                                                    </div>
                                                    <button *ngIf="!this.updateloading" (click)="updatePassword()" type="submit" class="button-clipped">UPDATE</button><br>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <p class="copyright text-center footer">
                        © AR Franchising, Inc. <span>2023</span> All rights reserved.
                    </p>	
                </div>
            </div>
        </div>
    </div>
</section>