import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { Router } from "@angular/router";
import { ApiService } from '../api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { langauge } from '../../environments/language/en';
import { Base64 } from 'js-base64';
import jwt_decode from 'jwt-decode';
import * as moment from 'moment';

@Component({
selector: 'app-adminLogin',
templateUrl: './adminLogin.component.html',
styleUrls: ['./adminLogin.component.scss']
})

export class AdminLoginComponent implements OnInit {
    public loading = false;
    public updateloading = false;
    isAuthenticating: Boolean = true;
    modalRef;
    userdata;
    useremail: string;
    username: string
    @ViewChild("contents", {static: false} ) forgotPopup: ElementRef;
    activeDirectoryToken: string;
    rawAdminData: string;
    adminData;
    adminToken: string;
    adminTokenData;
    userDataFromToken;
    loginConfirmationPop: boolean = false;
    constructor(private api: ApiService, private router: Router, private modalService: NgbModal) {
      this.adminToken = localStorage.getItem('vendor_admin_token');
      this.userdata = JSON.parse(localStorage.getItem('userdata'));   
      this.adminTokenData = this.parseJwt(this.adminToken);
    }
    ngOnInit() {
      this.loading = true;
      this.getAdminDataAndLoginAdmin();
    }

    // This function will get the token and admin details from the URL and initiate the
    // user verification and login process
    getAdminDataAndLoginAdmin(){
      // Getting token from login URL
      this.activeDirectoryToken = new URL(window.location.href.replace("#", "?")).searchParams.get("id_token");
      // Getting admin data from the state of login URL
      this.rawAdminData = new URL(window.location.href.replace("#", "?")).searchParams.get("state");
      this.adminData = JSON.parse(this.rawAdminData);
      // Getting already logged in user detail
      if (this.userdata) {
        this.username = this.userdata.data.VENDOR_NAME ? this.userdata.data.VENDOR_NAME : '';
        this.useremail = this.userdata.data.EMAIL_ADDRESS ? this.userdata.data.EMAIL_ADDRESS : '';
      } 
      if (this.userdata && this.adminData.vendor_email == this.useremail){
        this.router.navigate(['/homepage']);
      } 
      else {
        if(this.router.url == "/authentication-failed"){
          this.isAuthenticating = false;
          this.loading = false;
        } else {
          this.adminLogin();
        }
      }
    }

    // This function will call the verification and login APIs
    adminLogin() {
    if(this.activeDirectoryToken){
      // Verifying admin and saving the token to local storage
      const response = this.api.verifyAdmin(this.adminData,this.activeDirectoryToken);
      response.subscribe(res => {
        if (res['message'] == 'SUCCESS' && res['status'] === true) {
            localStorage.setItem('vendor_admin_token', res['data']);
            //loggin user
            const formdata = {
              'email': this.adminData.vendor_email,
              'is_assume_user': this.api.isAssumeUser()
            }
            // logging in user and saving the token
            const loginResponse = this.api.adminLogin(formdata);
                  loginResponse.subscribe(res => {
                    if (res['message'] == 'SUCCESS' && res['status'] === true) {
                        this.loading = false;
                        localStorage.setItem('userdata', JSON.stringify(res));
                        if(res.accessToken){
                          const tokenExpirationTime = this.api.getTokenExpirationTime(res.accessToken);
                          const timeForTokenToExpire = moment(tokenExpirationTime * 1000).valueOf();
                          if(timeForTokenToExpire > moment().valueOf()) this.api.autoLogout(timeForTokenToExpire - moment().valueOf());
                         }
                        this.router.navigate(['/homepage']);
                    } else if(res['message'] === 'ERROR' && res['data'] === "Email cannot be same as password") {
                      this.router.navigate(['/authentication-failed']);
                    } else if(res['message'] === 'ERROR' && res['status'] === false) {
                      this.router.navigate(['/authentication-failed']);
                    }
                    },
                    err => {
                      this.router.navigate(['/authentication-failed']);
                    });
            } else if(res['message'] === 'ERROR' && res['data'] === "") {
              this.router.navigate(['/authentication-failed']);             
            } else if(res['message'] === 'ERROR' && res['status'] === false) {
              this.router.navigate(['/authentication-failed']); 
            }
            },
            err => {
              this.router.navigate(['/authentication-failed']); 
            });
      
    }
  }

  // This will parse the token
  parseJwt(token) {
    return token ? jwt_decode(token): '';
  }

}


