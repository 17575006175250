import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { CustomerService } from '../../customerservice';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import {delay} from 'rxjs/operators';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {

  public isCollapsed = false;
  public isCollapsed2 = false;
  public loading = false;
  public jobsloading = false;
  builderlist: any = [];
  selectedbuilder: any;
  selectedplan: any;
  showCheck: Boolean = false;
  userdata: any;
  errMsg: any;
  builderjoblist: any;
  selectedcompany : any;
  norjoberr: any;
  bookmarks: any;
  jobno: string = "";
  customername: string = "";
  errsearch: string = "";
  origjobslist: any;
  isSearched: boolean = false;
  companyDetails: any;
  useremail: any;
  selectedBC: number = 0;
  isLoggedIn: boolean = false;
  assumeUser: boolean = false;
  adminToken;
  adminTokenData;
  constructor(private router: Router,private api: ApiService,private customerservice: CustomerService) { 
    this.adminToken = localStorage.getItem('vendor_admin_token');
    this.userdata = JSON.parse(localStorage.getItem('userdata'));
    this.adminTokenData = this.parseJwt(this.adminToken); 
    this.isLoggedIn = this.userdata ? true : false;
    let adminToken = localStorage.getItem('vendor_admin_token');// getting admin token
    let token = this.parseJwt(adminToken);
    if(token){
      this.assumeUser = true;
    }
  }
  
  ngOnInit() {
    this.checkForToken();
    this.getbuilderList();
    this.useremail = this.userdata.data.EMAIL_ADDRESS ? this.userdata.data.EMAIL_ADDRESS : '';
  }

  // This function will check if there is token in the local storage
  checkForToken(){
    this.api.checkLocalChanges().pipe(delay(100)).subscribe(
      ldata => {
       this.isLoggedIn = this.api.loggedIn();    
      }
    );
  }

  // This function decodes the admin token and returns the details
  parseJwt(token) {
    return token ? jwt_decode(token): '';
  }

  getbuilderList() {
    if(this.userdata && this.userdata.data && this.userdata.data.WEB_VENDOR_ID) {
      this.loading = true;
      let response = this.api.getbuilderslist(this.userdata.data.WEB_VENDOR_ID);
      response.subscribe(res => {
        if (res['data'].length > 0) {
          if(res["message"] === "SUCCESS" && res["status"] === true) {
            this.loading = false;
            this.builderlist = res['data'];
            if(this.adminTokenData && this.adminTokenData.bc_num && this.builderlist){
              const bcNum = this.adminTokenData.bc_num;
              const loggedInBc = this.builderlist.find((bc) => bc.COMPANY_NO == bcNum);
              if(loggedInBc){
                this.builderlist.splice(this.builderlist.indexOf(loggedInBc), 1);
                this.builderlist.unshift(loggedInBc);
              } 
            } 
            this.customerservice.builderData.next(res['data'][0]);
            this.customerservice.planjobid.next([{"jobid": null},{"jobselected": false},{"builderselected": true}]);
            // this.getcustomerdocuments(res['data'][0].COMPANY_ID,res['data'][0].VENDOR_ID);
            // this.getscheduleData(res['data'][0].COMPANY_ID,res['data'][0].VENDOR_ID); 
            // this.getpurchaseorder(res['data'][0].COMPANY_ID,res['data'][0].VENDOR_ID);
            // this.getbidsData(res['data'][0].COMPANY_ID,res['data'][0].VENDOR_ID); 
            // this.getPunchItems(res['data'][0].COMPANY_ID,res['data'][0].VENDOR_ID); 
            // this.getWarrantyPo(res['data'][0].COMPANY_ID,res['data'][0].VENDOR_ID); 
            //this.getBuilderContacts(res['data'][0].COMPANY_ID,res['data'][0].VENDOR_ID);
            // this.customerbookmarks(res['data'][0].COMPANY_ID,res['data'][0].VENDOR_ID);
            // this.getBuilderImages(res['data'][0].COMPANY_ID,res['data'][0].VENDOR_ID);
          } else {
            this.loading = false;
            this.errMsg = res['data'];
            this.customerservice.customercontactsData.next([]); 
          }
        }
      });
    }
  }
  showsearch(val) {
    this.norjoberr = '';
    this.jobno = '';
    this.errsearch = '';
    this.customername = '';
    this.isSearched = false;
    this.selectedbuilder = val;
    this.builderjoblist = this.origjobslist;
  }
  selectplan(jobid,val,obj){
    if(this.showCheck && this.selectedplan == obj.JOB_NO) {
      this.showCheck = !this.showCheck;
      this.selectedplan = obj.JOB_NO;
      this.getjobscheduleData(jobid,false);
      this.customerservice.builderLegaladdr([]);
      this.customerservice.vendorcontactsList.next([]);
      this.customerservice.vendorJobReport.next([]);
      this.getcustomerdocuments(obj.COMPANY_ID,obj.VENDOR_ID);
      this.getBuilderContacts(obj.COMPANY_ID,obj.VENDOR_ID);
      // this.customerservice.vendorbuilderimages([]);
    } else {
      this.showCheck = true;
      this.selectedplan = obj.JOB_NO;
      this.customerservice.builderLegaladdr(obj);
      this.getplanassets(jobid);
      this.getplanDocuments(jobid);
      this.getjobscheduleData(jobid,true);
      this.getBuilderjobsContacts(jobid);
      this.getBuilderjobsReports(jobid);
    }
  }
  
  setContent() {
    this.selectedbuilder = "";
  }

  collapseBC(obj,company_id,vendor_id, i){
     if(this.selectedBC == i){
      this.fetchjobs(obj,company_id,vendor_id, i)
     }
  }
  
  fetchjobs(obj,company_id,vendor_id, i){
    this.isSearched = false;
    this.selectedplan = "";
    this.setContent();
    this.resetList();
    this.selectedBC = i;
    this.builderjoblist= [];
    this.customerservice.planjobid.next([{"jobid": null},{"jobselected": false},{"builderselected": true}]);
    this.customerservice.searchingJob.next([{"searchingjob": false}]);
    this.selectedcompany = {"company_id": company_id,"vendor_id": vendor_id}
    this.customerservice.vendorJobReport.next([]);
    this.getcustomerdocuments(company_id,vendor_id); 
    this.customerbookmarks(company_id,vendor_id);
    this.getBuilderContacts(company_id,vendor_id);
    this.getscheduleData(company_id,vendor_id);
    this.getpurchaseorder(company_id,vendor_id);
    this.getbidsData(company_id,vendor_id);
    this.getPunchItems(company_id,vendor_id);
    this.getWarrantyPo(company_id,vendor_id);
    this.customerservice.builderDetails(obj);
    this.getBuilderImages(company_id,vendor_id)
    if(this.userdata && this.userdata.data && this.userdata.data.WEB_VENDOR_ID && company_id && vendor_id) {
      this.jobsloading = true;
      let response = this.api.get_builder_jobs(this.userdata.data.WEB_VENDOR_ID,company_id,vendor_id);
      response.subscribe(res => {
        if (res['data'].length > 0) {
          if(res["message"] === "SUCCESS" && res["status"] === true) {
            this.jobsloading = false;
            this.builderjoblist = res['data'];
            this.origjobslist = res['data'];
          } else {
            this.jobsloading = false;
            this.errMsg = res['data'];
            this.customerservice.customercontactsData.next([]); 
          }
        }
      });
    }
  }



  // fn for fetching first customer documents list on page load using behaviour subject && onclick of builder name in sidebar section
  getcustomerdocuments(company_id,vendor_id) {
    this.customerservice.customerdocsData.next([]);
    this.customerservice.customerplansData.next([]);
    let response = this.api.get_userdocs_List(this.userdata.data.WEB_VENDOR_ID,company_id,vendor_id);
    response.subscribe(res => {
      if (res['data'].length > 0) {
        this.customerservice.customerdocuments(res);
      }
    });
  }
  togglebar() {
    var x = document.getElementById("sidebar-root");
    x.classList.add("sidebarmobclose");
    x.classList.remove("sidebarmobopen");
  }
  getbuilder() {
    this.togglebar();
  }

  
  //get schedule data list based on jobid
  getscheduleData(company_id,vendor_id) {
    this.customerservice.scheduleData.next({"loading": true});
    let response = this.api.get_jobSchedule_List(this.userdata.data.WEB_VENDOR_ID,company_id,vendor_id);
    response.subscribe(res => {
      if (res['data'].length > 0) {
        this.customerservice.scheduledata(res);
      }
    });
  }

 //get bids data list based on jobid
  getbidsData(company_id,vendor_id) {
    this.customerservice.bidsData.next({"loading": true});
    let response = this.api.get_jobBids_List(this.userdata.data.WEB_VENDOR_ID,company_id,vendor_id);
    response.subscribe(res => {
      if (res['data'].length > 0) {
        this.customerservice.bidsdata(res);
      }
    });
  }

  //get puch items list based on jobid
  getPunchItems(company_id,vendor_id) {
   this.customerservice.punchItemsData.next({"loading": true});
    let response = this.api.get_jobPunchItems_List(this.userdata.data.WEB_VENDOR_ID,company_id,vendor_id);
    response.subscribe(res => {
      if (res['data'].length > 0) {
        this.customerservice.punchidata(res);
      }
    });
  }

   //get puch items list based on jobid
  getWarrantyPo(company_id,vendor_id) {
   this.customerservice.wpoData.next({"loading": true});
    let response = this.api.get_jobWarrantyPo_List(this.userdata.data.WEB_VENDOR_ID,company_id,vendor_id);
    response.subscribe(res => {
      if (res['data'].length > 0) {
        this.customerservice.warrantypodata(res);
      }
    });
  }

  //fn 
  getjobscheduleData(jobid,selected) {
    this.customerservice.planjobid.next([{"jobid": jobid},{"jobselected": selected},{"builderselected": false}]);
  }

 //fn to fetch customer bookmarks on page load
 customerbookmarks(company_id,vendor_id) {
  this.customerservice.customerbookmarksList.next({"loading": true});
  let response = this.api.getbookmarks(this.userdata.data.WEB_VENDOR_ID,company_id,vendor_id);
  response.subscribe(res => {
    if (res['data'].length > 0) {
      this.customerservice.customerbookmarkslist(res);
    }
  });
}

  //fn to fetch Builder Images on page load
  getBuilderImages(company_id,vendor_id) {
    this.customerservice.vendorBuilderImagesList.next({"loading": true});
    let response = this.api.get_builder_photos(1,this.userdata.data.WEB_VENDOR_ID,company_id,vendor_id);
    response.subscribe(res => {
      if (res['data'].length > 0) {
        this.customerservice.vendorbuilderimages(res);
      }
    });
  }

   //fn to fetch builder contacts on page load
  getBuilderContacts(company_id,vendor_id) {
    this.customerservice.vendorcontactsList.next({"loading": true});
    let response = this.api.get_builder_contacts(company_id,vendor_id);
    response.subscribe(res => {
      if (res['data'].length > 0) {
        this.customerservice.vendorcontactslist(res);
      }
    });
  }
  
  getpurchaseorder(company_id,vendor_id) {
    this.customerservice.purchaseorderData.next({"loading": true});
    let response = this.api.get_jobpo_List(this.userdata.data.WEB_VENDOR_ID,company_id,vendor_id);
    response.subscribe(res => {
      if (res['data'].length > 0) {
        this.customerservice.purchaseorder(res);
      }
    });
  }

  //fn for job search 
  searchJobs() {
    this.showCheck = false;
    var reg = new RegExp('^[0-9]+$');
    if(this.jobno === '' && this.customername === '') {
      this.errsearch = 'Please enter job number or customer name';
    } else if(this.jobno && this.jobno.length > 0 && !reg.test(this.jobno)) {
      this.errsearch = `Please enter valid job number`;
    } else if(this.jobno && this.jobno.length < environment.MIN_SEARCH_LENGTH) {
      this.errsearch = `Job number should have atleast ${environment.MIN_SEARCH_LENGTH} numbers`;
    }else if(this.customername && this.customername.length < environment.MIN_SEARCH_LENGTH) {
      this.errsearch = `Customer Name should have atleast ${environment.MIN_SEARCH_LENGTH} Characters`;
    } else if(this.jobno && this.jobno.length >= environment.MIN_SEARCH_LENGTH && this.customername === '') {
      this.errsearch = '';
      let regx =new RegExp(`${this.jobno}`);
      this.builderjoblist= this.origjobslist.filter((u) => {
        if(regx.test(u.JOB_NO)) {
          return u;
        }
      });
      if(this.builderjoblist && this.builderjoblist.length > 0) {
        this.customerservice.searchingJob.next([{"searchingjob": true}]);
      } else {
        this.norjoberr = 'No matched records found';
      }
      this.clearList();
    } else if(this.customername && this.customername.length >= environment.MIN_SEARCH_LENGTH && this.jobno === '') {
      this.errsearch = '';
      let regx =new RegExp(`${this.customername.toString().toLowerCase()}`);
      this.builderjoblist= this.origjobslist.filter((u) => {
        if(regx.test(u.CUSTOMER_NAME != null ? u.CUSTOMER_NAME.toLowerCase() : ''))  {
          return u;
        }
      });
      // if(this.builderjoblist && this.builderjoblist.length == 0) {
      //   this.builderjoblist= this.origjobslist.filter((u) => {
      //     if(regx.test(u.OWNER_LAST_NAME != null ? u.OWNER_LAST_NAME.toLowerCase() : ''))  {
      //       return u;
      //     }
      //   });
      // }
      if(this.builderjoblist && this.builderjoblist.length > 0) {
        this.customerservice.searchingJob.next([{"searchingjob": true}]);
      } else {
        this.norjoberr = 'No matched records found';
      }
      this.clearList();
    } else if(this.customername && this.customername.length >= environment.MIN_SEARCH_LENGTH && this.jobno && this.jobno.length >= environment.MIN_SEARCH_LENGTH) {
      this.errsearch = '';
      let job_regx =new RegExp(`${this.jobno}`);
      let name_regx =new RegExp(`${this.customername.toString().toLowerCase()}`);
      this.builderjoblist= this.origjobslist.filter((u) => {
        if(job_regx.test(u.JOB_NO) && name_regx.test(u.CUSTOMER_NAME != null ? u.CUSTOMER_NAME.toLowerCase() : '')) {
          return u;
        }
      });
      if(this.builderjoblist && this.builderjoblist.length == 0) {
        this.builderjoblist = [];
        this.origjobslist.filter((u) => {
          if(name_regx.test(u.CUSTOMER_NAME != null ? u.CUSTOMER_NAME.toLowerCase() : ''))  {
            this.builderjoblist.push(u);
          }
        });
        this.origjobslist.filter((u) => {
          if(job_regx.test(u.JOB_NO))  {
            this.builderjoblist.push(u);
          }
        });
      }
      if(this.builderjoblist && this.builderjoblist.length > 0) {
        this.customerservice.searchingJob.next([{"searchingjob": true}]);
      } else {
        this.norjoberr = 'No matched records found';
      }
      this.clearList();
    }
  }
  resetList() {
    this.norjoberr = '';
    this.showCheck = false;
    this.isSearched = false;
    this.builderjoblist = this.origjobslist;
    this.customerservice.searchingJob.next([{"resetall": true}]);
  }
  clearList() {
    this.isSearched = true;
    this.selectedplan = "";
    this.selectedbuilder = "";
  }

  //fn get user selected job documents 
  getplanDocuments(jobid) {
    this.customerservice.customerdocsData.next([]);
    this.customerservice.builderData
    .subscribe(
      data => { 
        this.companyDetails = data;
      }
    );
    this.customerservice.customerplansData.next({'loading': true});
    let response = this.api.getcustomerplansdocuments(this.userdata.data.WEB_VENDOR_ID,this.companyDetails.COMPANY_ID,this.companyDetails.VENDOR_ID,jobid);
    response.subscribe(res => {
      if (res['data'].length > 0) {
        this.customerservice.customerplandocs(res)
      }
    });
  }


  //fn to fetch job assets 
  getplanassets(jobid) {
    this.customerservice.builderData
    .subscribe(
      data => { 
        this.companyDetails = data;
      }
    );
    this.customerservice.vendorBuilderImagesList.next({"loading": true});
    let response = this.api.vendor_job_images(1,this.userdata.data.WEB_VENDOR_ID,this.companyDetails.COMPANY_ID,this.companyDetails.VENDOR_ID,jobid);
    response.subscribe(res => {
      if (res['data'].length > 0) {
        this.customerservice.vendorbuilderimages(res);
      }
    });
  }


    //fn to fetch builder jobs contacts on page load
  getBuilderjobsContacts(jobid) {
    this.customerservice.vendorcontactsList.next({"loading": true});
    let response = this.api.get_customer_plans_contacts(jobid);
    response.subscribe(res => {
      if (res['data'].length > 0) {
        this.customerservice.vendorcontactslist(res);
      }
    });
  }
    //fn to fetch builder jobs reports on job click action
  getBuilderjobsReports(jobid) {
    this.customerservice.vendorJobReport.next({"loading": true});
    let response = this.api.get_vendor_job_report(jobid);
    response.subscribe(res => {
      if (res['data'].length > 0) {
        this.customerservice.vendorjobreport(res);
      }
    });
  }
}
