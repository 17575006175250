import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../api.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.scss']
})
export class PagenotfoundComponent implements OnInit {

  constructor(private api: ApiService,private router: Router,) { }

  ngOnInit(): void {
  }
  navigateToHome(){
     this.router.navigate(['/']);
  }
}
