import { BrowserModule } from '@angular/platform-browser';
import { NgModule,ViewChild } from '@angular/core';
import { AppComponent } from './app.component';
import { HeaderComponent } from './core/header/header.component';
import { SidebarComponent } from './core/sidebar/sidebar.component';
import { FooterComponent } from './core/footer/footer.component';
import { HomepageComponent } from './modules/homepage/homepage.component';
import { RouterModule } from '@angular/router'; 
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { LightboxModule } from 'ngx-lightbox';
import { ModalModule } from 'ngb-modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BookmarksComponent} from './modules/bookmarks/bookmarks.component';
import {ChangePasswordComponent} from './modules/change-password/change-password.component';
import { LoginComponent } from './modules/login/login.component';
import { LogoutComponent } from './modules/logout/logout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './../material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HomelayoutComponent } from './homelayout/homelayout.component';
import { BidsLayoutComponent } from './bidsLayout/bidslayout.component';
import { NgxGalleryAction } from '@kolkov/ngx-gallery';
import { ShareModule } from '@ngx-share/core';
import { AuthGuard } from './auth.guard';
import { ApiService } from './api.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import 'hammerjs';
import { AppRoutes } from './app.routing';
import {Loader} from '../app/shared/ngx-loaded';
import { ARHInterceptor } from './interceptor/arh.interceptor';
import { NgxLoadingModule } from 'ngx-loading';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MomentModule } from 'angular2-moment';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BidsubmitComponent } from './modules/bidsubmit/bidsubmit.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { BidSubmitPipe } from './modules/bidsubmit/bidSubmitPipe';
import { CurrencyPipe, DecimalPipe } from '@angular/common'
import { CurrencyInputDirective } from './usd-only.directive';
import { PagenotfoundComponent } from "./modules/pagenotfound/pagenotfound.component";
import { AdminLoginComponent } from './adminLogin/adminLogin.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    HomepageComponent,
    BookmarksComponent,
    ChangePasswordComponent,
    LoginComponent,
    LogoutComponent,
    HomelayoutComponent,
    BidsLayoutComponent,
    BidsubmitComponent,
    Loader,
    BidSubmitPipe,
    CurrencyInputDirective,
    PagenotfoundComponent,
    AdminLoginComponent
  ],
  imports: [
    BrowserModule,
    NgxGalleryModule,
    LightboxModule,
    ModalModule,
    RouterModule.forRoot(AppRoutes), NgbModule,
    RouterModule.forRoot(AppRoutes),
    NgxLoadingModule.forRoot({}),
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule, FormsModule,
    LightboxModule,
    HttpClientModule, ShareModule,

    MomentModule,
    NgIdleKeepaliveModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    NgxDocViewerModule,
    ScrollingModule,
    PdfJsViewerModule 
  ],
  providers: [
    ApiService,
    AuthGuard,
    CurrencyPipe,
    DecimalPipe,
    // CustomerService,
    {
    provide: NgxGalleryAction,
    useValue: {
      dots: false,
      imageSize: 'cover'
    }
  },
  {
    provide:HTTP_INTERCEPTORS,
    useClass:ARHInterceptor,
    multi:true
  }],
  bootstrap: [AppComponent],
  exports: [RouterModule]
})
export class AppModule { }
