import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable, BehaviorSubject, Subject, fromEvent } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})

export class ApiService {

  private backToBidsHeaderCondtion = new Subject<any>();
  userdata: any;
  dateTime: any;
  appendTime: any;

  constructor(private http: HttpClient, private modalService: NgbModal) {
     // this.dateTime = new Date().getTime();
  }

  // This function will check changes in local storage
  checkLocalChanges() {
    return fromEvent<StorageEvent>(window, 'storage');
  }
  
    // This to check if the token is expired, if token expired then logging out the user
    checkForTokenExpiration(){
      const tokenData = JSON.parse(localStorage.getItem('userdata'));
      if(tokenData){
        const token = tokenData.accessToken;
        const tokenExpirationTime = this.getTokenExpirationTime(token);
        if(moment().valueOf() > moment(tokenExpirationTime * 1000).valueOf()){
          this.logout();
        }
      }
    }
  
    // This function will return expiration time of token.
    getTokenExpirationTime(token) {
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      return decodedToken.exp; 
    }

    // This function auto logs out the user when the token expires
    autoLogout(tokenExpirationTime: number){
      setTimeout(() => {
        this.logout();
      }, tokenExpirationTime);
    }

  logout(){
    localStorage.removeItem('userdata');
    window.location.href = '/logout';
  }

  loggedIn() {
    return !!localStorage.getItem('userdata');                           //return true if user logged in                      
  }

  isAssumeUser() {
    return !!localStorage.getItem('vendor_admin_token');                           //return true if user logged in                      
  }


  backToBidsHeaderCondtionSetfn(editarray ,addarray, secondSectionFlag) {
        this.backToBidsHeaderCondtion.next({editarray: editarray,addarray:addarray,secondSectionFlag:secondSectionFlag});
  }
  backToBidsHeaderCondtionGetfn(): Observable<any> {
     return this.backToBidsHeaderCondtion.asObservable();
  }
  login(user): Observable<any> {                                         //login api call to validate user
    let email = user.email
    let password = user.password
    let isAssumeUser = user.is_assume_user
    return this.http.post(environment.API_URL + 'login', { email, password, isAssumeUser });
  }

  adminLogin(user): Observable<any> {                                         //login api call to validate user
    let email = user.email
    let password = user.password
    let isAssumeUser = user.is_assume_user
    return this.http.post(environment.API_URL + 'adminLogin', { email, isAssumeUser });
  }
  verifyAdmin(adminData, token): Observable<any> {                                         //login api call to validate user
    const admin_email = adminData.admin_email;
    const admin_name = adminData.admin_name;
    const vendor_admin_token = token;
    const vendor_email = adminData.vendor_email
    const vendor_name = adminData.vendor_name
    const bc_num = adminData.bc_num
    return this.http.post(environment.API_URL + 'verifyAdmin', { admin_email, admin_name, vendor_admin_token, vendor_email, vendor_name, bc_num });
  }

  getbuilderslist(web_vendor_id): Observable<any> {                          //api call to get builders details
    this.appendTime = new Date().getTime();
    return this.http.get(environment.API_URL + `vendor-companies?web_vendor_id=${web_vendor_id}&time=${this.appendTime}`);
  }
  get_builder_jobs(web_vendor_id,company_id,vendor_id): Observable<any> {                          //api call to get user plans list
    this.appendTime = new Date().getTime();
    return this.http.get(environment.API_URL + `vendor-jobs?web_vendor_id=${web_vendor_id}&company_id=${company_id}&vendor_id=${vendor_id}&time=${this.appendTime}`);
  }
  get_userdocs_List(web_vendor_id,company_id,vendor_id): Observable<any> {                          //api call to get user documents list
    this.appendTime = new Date().getTime();
    return this.http.get(environment.API_URL + `vendor-documents?web_vendor_id=${web_vendor_id}&company_id=${company_id}&vendor_id=${vendor_id}&time=${this.appendTime}`);
  }
  getcustomerplansdocuments(web_vendor_id,company_id,vendor_id,job_id): Observable<any> {                          //api call to get document list based on selected plan 
    this.appendTime = new Date().getTime();
    return this.http.get(environment.API_URL + `vendor-job-documents?job_id=${job_id}&web_vendor_id=${web_vendor_id}&company_id=${company_id}&vendor_id=${vendor_id}&time=${this.appendTime}`);
  }
  get_jobSchedule_List(web_vendor_id,company_id,vendor_id): Observable<any> {                          //api call to get user Schedule list
    this.appendTime = new Date().getTime();
    return this.http.get(environment.API_URL + `vendor-schedule-list?web_vendor_id=${web_vendor_id}&company_id=${company_id}&vendor_id=${vendor_id}&time=${this.appendTime}`);
  }
  get_jobBids_List(web_vendor_id,company_id,vendor_id): Observable<any> {                          //api call to get user Bids list
    this.appendTime = new Date().getTime();
    return this.http.get(environment.API_URL + `vendor-bids-list?web_vendor_id=${web_vendor_id}&company_id=${company_id}&vendor_id=${vendor_id}&time=${this.appendTime}`);
  }
  get_jobPunchItems_List(web_vendor_id,company_id,vendor_id): Observable<any> {                          //api call to get user Bids list
    this.appendTime = new Date().getTime();
    return this.http.get(environment.API_URL + `vendor-punch-list?web_vendor_id=${web_vendor_id}&company_id=${company_id}&vendor_id=${vendor_id}&time=${this.appendTime}`);
  }
  get_jobWarrantyPo_List(web_vendor_id,company_id,vendor_id): Observable<any> {                          //api call to get user Bids list
    this.appendTime = new Date().getTime();
    return this.http.get(environment.API_URL + `vendor-warranty-po-list?web_vendor_id=${web_vendor_id}&company_id=${company_id}&vendor_id=${vendor_id}&time=${this.appendTime}`);
  }
  getbookmarks(web_vendor_id,company_id,vendor_id): Observable<any> {                          //bookmarks api call to get bookmarks list
    this.appendTime = new Date().getTime();
    return this.http.get(environment.API_URL + `vendor-bookmarks?web_vendor_id=${web_vendor_id}&vendor_id=${vendor_id}&company_id=${company_id}&time=${this.appendTime}`);
  }
  get_builder_contacts(company_id,vendor_id): Observable<any> {                          //bookmarks api call to get bookmarks list
    this.appendTime = new Date().getTime();
    return this.http.get(environment.API_URL + `vendor-sales-contact?vendor_id=${vendor_id}&company_id=${company_id}&time=${this.appendTime}`);
  }
  get_customer_plans_contacts(jobId): Observable<any> {                          //api call to get user plans contacts list
    this.appendTime = new Date().getTime();
    return this.http.get(environment.API_URL + `vendor-job-contacts?job_id=${jobId}&time=${this.appendTime}`);
  }
  get_builder_photos(page_count,web_vendor_id,company_id,vendor_id): Observable<any> {                          //Vendor images api call to get builder image list
    this.appendTime = new Date().getTime();
    return this.http.get(environment.API_URL + `vendor-images?web_vendor_id=${web_vendor_id}&vendor_id=${vendor_id}&company_id=${company_id}&p_count=${page_count}&time=${this.appendTime}`);
  }
  vendor_job_images(page_count,web_vendor_id,company_id,vendor_id,job_id): Observable<any> {                          //Vendor images api call to get builder image list
    this.appendTime = new Date().getTime();
    return this.http.get(environment.API_URL + `vendor-job-images?web_vendor_id=${web_vendor_id}&vendor_id=${vendor_id}&company_id=${company_id}&p_count=${page_count}&job_id=${job_id}&time=${this.appendTime}`);
  }
  get_jobpo_List(web_vendor_id,company_id,vendor_id): Observable<any> {                          //api call to get user documents list
    this.appendTime = new Date().getTime();
    return this.http.get(environment.API_URL + `vendor-po-list?web_vendor_id=${web_vendor_id}&company_id=${company_id}&vendor_id=${vendor_id}&time=${this.appendTime}`);
  }
  get_vendor_po_report_url(web_vendor_id,po_no,jobno): Observable<any> {  
    this.appendTime = new Date().getTime();
    return this.http.get(environment.API_URL + `vendor-po-report_url?web_vendor_id=${web_vendor_id}&po_no=${po_no}&job_no=${jobno}&time=${this.appendTime}`);
  }
  get_warrenty_report_url(web_vendor_id,work_order_no,jobno): Observable<any> {  
    this.appendTime = new Date().getTime();
    return this.http.get(environment.API_URL + `vendor-warranty-po-report_url?web_vendor_id=${web_vendor_id}&work_order_no=${work_order_no}&job_no=${jobno}&time=${this.appendTime}`);
  }
  get_vendor_schedule_report_url(web_vendor_id,company_id,jobid,vendorid,jobno,activity_id): Observable<any> {  
    this.appendTime = new Date().getTime();
    return this.http.get(environment.API_URL + `vendor-schedule-report_url?web_vendor_id=${web_vendor_id}&company_id=${company_id}&vendor_id=${vendorid}&job_id=${jobid}&job_no=${jobno}&activity_id=${activity_id}&time=${this.appendTime}`);
  }
  get_vendor_punch_report_url(web_vendor_id,company_id,jobid,vendorid,jobno,activity_id): Observable<any> {  
    this.appendTime = new Date().getTime();
    return this.http.get(environment.API_URL + `vendor-punch-report_url?web_vendor_id=${web_vendor_id}&company_id=${company_id}&vendor_id=${vendorid}&job_id=${jobid}&job_no=${jobno}&activity_id=${activity_id}&time=${this.appendTime}`);
  }
  get_vendor_bids_report_url(web_vendor_id,company_id,bid_id,jobid,vendorid,jobno): Observable<any> {  
    this.appendTime = new Date().getTime();
    return this.http.get(environment.API_URL + `vendor-bids-report_url?web_vendor_id=${web_vendor_id}&company_id=${company_id}&vendor_id=${vendorid}&bid_id=${bid_id}&job_id=${jobid}&job_no=${jobno}&time=${this.appendTime}`);
  }
  decode_img_url(asset_id,asset_name,file_name,document_url,vendor_id) {
    return this.http.post(environment.API_URL + 'vendor-encode-url', { asset_id,asset_name,file_name,document_url,vendor_id });
  }
  get_vendor_job_report(jobId): Observable<any> {  
    this.appendTime = new Date().getTime();
    return this.http.get(environment.API_URL + `vendor-job-reports-list?job_id=${jobId}&time=${this.appendTime}`);
  }
  get_vendor_job_report_url(web_vendor_id,company_id,jobid): Observable<any> {  
   this.appendTime = new Date().getTime();
    return this.http.get(environment.API_URL + `vendor-job-report_url?web_vendor_id=${web_vendor_id}&company_id=${company_id}&job_id=${jobid}&time=${this.appendTime}`);
  }
  updatepassword(user): Observable<any> {                                 //api call to update password
    let email = user.email;
    let password = user.password;
    return this.http.post(environment.API_URL + 'vendor-update-password', { email, password });
  }
  gets_bids_headers(bid_vendor_id) {
    this.appendTime = new Date().getTime();
    return this.http.get(environment.API_URL + `vendor-bid-header?bid_vendor_id=${bid_vendor_id}&time=${this.appendTime}`);
  }
  get_bids_logs(bid_id,bid_vendor_id) {
    this.appendTime = new Date().getTime();
    return this.http.get(environment.API_URL + `vendor-bid-logs?bid_id=${bid_id}&bid_vendor_id=${bid_vendor_id}&time=${this.appendTime}`);
  }
  gets_bids_items(bid_id,bid_vendor_id) {
    this.appendTime = new Date().getTime();
    return this.http.get(environment.API_URL + `vendor-bid-items?bid_id=${bid_id}&bid_vendor_id=${bid_vendor_id}&time=${this.appendTime}`);
  }
  gets_bids_items_location(bid_vendor_id) {
    this.appendTime = new Date().getTime();
    return this.http.get(environment.API_URL + `vendor-bid-location?bid_vendor_id=${bid_vendor_id}&time=${this.appendTime}`);
  }
  gets_bids_items_measure_unit() {
    this.appendTime = new Date().getTime();
    return this.http.get(environment.API_URL + `vendor-measure-unit`);
  }
  add_bid_items(bid_id,bid_vendor_id,vendor_id,company_id,bid_item): Observable<any>{   
    return this.http.post(environment.API_URL + 'vendor-add-bid-items', {bid_id,bid_vendor_id,vendor_id,company_id,bid_item});
  }
  update_bid_items(updated_bid_items): Observable<any>{   
    return this.http.post(environment.API_URL + 'vendor-update-bid-items', {updated_bid_items});
  }
  delete_bid_items(bid_item_id): Observable<any>{   
    return this.http.delete(environment.API_URL + `vendor-delete-bid-items?bid_item_id=${bid_item_id}&time=${this.appendTime}`);
  }
  vendor_bid_update(secondSectionArray): Observable<any>{
    return this.http.post(environment.API_URL + 'vendor-bid-update', secondSectionArray);
  }
  vendor_bid_submit(bid_vendor_id,bid_log_text): Observable<any>{
    return this.http.post(environment.API_URL + 'vendor-bid-submit', {bid_vendor_id,bid_log_text});
  }

}

  