<section class="logout">
    <div class="container-fluid">
        <div class="row justify-content-md-center">
            <div class="col-12 col-lg-6 col-md-8 col-sm-12 col-xs-12 padding-left-0">
                <div id="content" class="mob-hide bg-image" style="background-image: url(./../../assets/img/login.png);"></div>
            </div>
            <div class="col-12 col-lg-6 col-md-12 col-sm-12 col-xs-12 margauto">
                <div class="form-container">
                    <div id="logoimg">
                        <img src="./../../assets/img/logout.png"/>
                    </div>
                    <div class="tag">You are logged out</div>
                    <div class="tag">To continue your session, please log-in here</div>
                    <div class="inputs">
                        <a (click)="redirectToLoginPage()" class="button-clipped color-white">LOGIN TO PORTAL</a><br>
                    </div>
                </div>
                <p class="copyright text-center footer">
                    © AR Franchising, Inc. 2023 All rights reserved .
                </p>
            </div>
        </div>
    </div>
</section>