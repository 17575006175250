import { HttpInterceptor } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';

export class ARHInterceptor implements HttpInterceptor {
    intercept(req: import("@angular/common/http").HttpRequest<any>, next: import("@angular/common/http").HttpHandler): import("rxjs").Observable<import("@angular/common/http").HttpEvent<any>> {

        var phrase1 = environment['SHA_ACCESS_PHRASE1'];
        var phrase2 = moment(new Date()).utcOffset('-0400').format("DD_MM_YYYY");
        var phrase3 = environment['SHA_ACCESS_PHRASE2'];
        var completePhrase = `${phrase1}${phrase2}${phrase3}`;
        var iv = environment['SHA_ACCESS_IV'];

        const userdata = JSON.parse(localStorage.getItem('userdata'));
        var encrypted = CryptoJS.AES.encrypt(completePhrase, iv).toString();
    
        //before login header
        let headers = req.headers
            .set('Content-Type', 'application/json')
            .set('Access-Control-Allow-Origin', '*')
            .set('x-api-key', encrypted)
        //after login header
        if (userdata != null) {                    
            let headers = req.headers
            .set('Content-Type', 'application/json')
            .set('Access-Control-Allow-Origin', '*')
            .set('x-api-key', encrypted)
            .set('x-auth-token',userdata.accessToken)
            return next.handle(req.clone({ headers }));
        } else {
            return next.handle(req.clone({ headers }));
        }
    }

}