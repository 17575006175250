<div class="main-panel">
  <div class="content">
    <div class="maincontent">
      <div class="mainnav navformob">
        <ul>
          <li> <a (click)="openTabButon(1)" [ngClass]="{'but-active': loginPopToggle == 1}">dashboard</a></li>
          <li> <a (click)="openTabButon(2)" [ngClass]="{'but-active': loginPopToggle == 2}">contacts</a></li>
          <li> <a (click)="openTabButon(3)" [ngClass]="{'but-active': loginPopToggle == 3}">Documents</a></li>
          <li> <a (click)="openTabButon(4)" [ngClass]="{'but-active': loginPopToggle == 4}">Photos</a></li>
          <li> <a (click)="openTabButon(5)" [ngClass]="{'but-active': loginPopToggle == 5}">Bookmarks</a></li>

        </ul>
      </div>
      <div class="maincontentdiv1 homebg" *ngIf="loginPopToggle == 1" [ngClass]="{ padDashboard: nojoberrMsg || this.companyDetails != undefined }">
        <div id="dview">
          <section class="grid-style1" *ngIf="scheduledata && scheduledata.length > 0 && !searchingjob">
            <h5 class="font-weight-bold"><img src="assets/img/calendar.svg" class="calendar-style1" />Schedule
              <span class="pull-right" style="color: #b7b7b7;" *ngIf="scheduledata && scheduledata.length > 0">
                Total Count: {{scheduledata && scheduledata.length > 0 ? scheduledata.length : ''}}
              </span>
            </h5>
            <hr class="new2">
            <div class="table-scroll ScrollStyle">
            <table>
              <thead>
                <tr>
                  <th
                    scope="col"
                    width="15%"
                  >
                    JOB#
                    <div class="sortitem">
                      <i (click)="scheduleSort('jobid')"
                      [ngClass]="selectedkey === 'jobid' && reversesort ?  'fas fa-sort-up martop-9 ' : 'fas fa-sort-down martop-0 '"
                      class="marleft-2 pointer"></i>
                    </div>
                  </th>
                  <th
                    scope="col"
                    width="25%"
                  >
                  Customer Name
                    <div class="sortitem">
                      <i (click)="scheduleSort('cname')"
                        [ngClass]="selectedkey === 'cname' && namesort ?  'fas fa-sort-up martop-9 ' : 'fas fa-sort-down martop-0 '"
                        class="marleft-2 pointer"></i>
                    </div>
                  </th>
                  <th
                    scope="col"
                    width="20%"
                  >
                    Activity
                    <div class="sortitem">
                      <i (click)="scheduleSort('activity')"
                          [ngClass]="selectedkey === 'activity' && activity ?  'fas fa-sort-up martop-9 ' : 'fas fa-sort-down martop-0 '"
                          class="marleft-2 pointer"></i>
                    </div>
                  </th>
                  <th
                    scope="col"
                    width="20%"
                  >
                  Start Date
                    <div class="sortitem">
                      <i (click)="scheduleSort('startdate')"
                      [ngClass]="selectedkey === 'startdate' && startdatesort ?  'fas fa-sort-up martop-9 ' : 'fas fa-sort-down martop-0 '"
                      class="marleft-2 pointer"></i>
                    </div>
                  </th>
                  <th
                    scope="col"
                    width="20%"
                  >
                  Finish Date
                    <div class="sortitem">
                      <i (click)="scheduleSort('enddate')"
                        [ngClass]="selectedkey === 'enddate' && enddatesort ?  'fas fa-sort-up martop-9 ' : 'fas fa-sort-down martop-0 '"
                        class="marleft-2 pointer"></i>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody *ngIf="scheduleerr">
                <tr>
                  <td colspan="5" class="err-styles text-center border-0">
                    {{scheduleerr}}
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="!scheduleerr">
                <tr *ngFor="let sdata Of scheduledata;let i = index" [attr.data-index]="i" class="hoverclr"
                (click)="scheduleReports(sdata.COMPANY_ID,sdata.JOB_ID,sdata.VENDOR_ID,i,sdata.JOB_NO,sdata.ACTIVITY_ID)">
                  <td>
                    <p class="dashboard_text ">{{sdata.JOB_NO}}</p>
                  </td>
                  <td>
                    <p class="dashboard_text "> {{sdata.CUSTOMER_NAME}} </p>
                    <div *ngIf="this.dashboardreportsLoading && this.selectedpo === i && this.selectedThing === 'schedule'"
                        class="list_loader list-loader-end">
                        <ngx-loader [loading]="this.dashboardreportsLoading"></ngx-loader>
                    </div>
                  </td>
                  <td>
                    <p class="dashboard_text">{{sdata.ACTIVITY_DESC}}</p>
                  </td>
                  <td>
                    <p class="dashboard_text">
                      {{sdata && sdata.ACTUAL_START_DATE == null ?  "-" : moment(sdata.ACTUAL_START_DATE).utcOffset('-0400').format('ll') }}   
                    </p>
                  </td>
                  <td>
                    <p [ngClass]="sdata && sdata.ACTUAL_FINISH_DATE == null ? 'mk-center' : 'dashboard_text'">
                      {{sdata && sdata.ACTUAL_FINISH_DATE == null ?  "-" : moment(sdata.ACTUAL_FINISH_DATE).utcOffset('-0400').format('ll') }}     
                     </p>
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
          </section>
          <section class="grid-style1" *ngIf="punchitemsData && punchitemsData.length > 0 && !searchingjob">
            <h5 class="font-weight-bold"><img src="assets/img/punch-items.svg" class="calendar-style" /> Punch Lists
              <span class="pull-right" style="color: #b7b7b7;" *ngIf="punchitemsData && punchitemsData.length > 0">
                Total Count: {{punchitemsData && punchitemsData.length > 0 ? punchitemsData.length : ''}}
              </span>
            </h5>
            <hr class="new2">
            <div class="ScrollStyle table-scroll punch-list-table">
              <table>
                <thead>
                  <tr>
                    <th
                      scope="col"
                      width="10%"
                    >
                      JOB#
                      <div class="sortitem">
                        <i (click)="punchSort('pnjobid')"
                           [ngClass]="selectedkey === 'pnjobid' && reversesort ?  'fas fa-sort-up martop-9 ' : 'fas fa-sort-down martop-0 '"
                            class="marleft-2 pointer"></i>
                      </div>
                    </th>
                    <th
                    scope="col"
                    width="25%"
                    >
                      Customer Name
                      <div class="sortitem">
                        <i (click)="punchSort('pncname')"
                          [ngClass]="selectedkey === 'pncname' && namesort ?  'fas fa-sort-up martop-9 ' : 'fas fa-sort-down martop-0 '"
                          class="marleft-2 pointer"></i>
                      </div>
                    </th>
                    <th
                      scope="col"
                      width="20%"
                    >
                      Activity
                      <div class="sortitem">
                        <i (click)="punchSort('activity')" [ngClass]="selectedkey === 'activity' && activity ?  'fas fa-sort-up martop-9 ' : 'fas fa-sort-down martop-0 '"
                          class="marleft-2 pointer"></i>
                      </div>
                    </th>
                    <th
                    scope="col"
                    width="15%"
                    >
                      Room
                      <div class="sortitem">
                        <i (click)="punchSort('room')" [ngClass]="selectedkey === 'room' && roomsort ?  'fas fa-sort-up martop-9 ' : 'fas fa-sort-down martop-0 '"
                        class="marleft-2 pointer"></i>
                      </div>
                    </th>
                    <th
                    scope="col"
                    width="15%"
                    >
                      Create Date
                      <div class="sortitem">
                        <i (click)="punchSort('pnstartdate')" [ngClass]="selectedkey === 'pnstartdate' && startdatesort ?  'fas fa-sort-up martop-9 ' : 'fas fa-sort-down martop-0 '"
                          class="marleft-2 pointer"></i>
                      </div>
                    </th>
                    <th
                    scope="col"
                    width="15%"
                    >
                      Item
                      <div class="sortitem">
                        <i (click)="punchSort('item')" [ngClass]="selectedkey === 'item' && itemsort ?  'fas fa-sort-up martop-9 ' : 'fas fa-sort-down martop-0 '"
                            class="marleft-2 pointer"></i>
                      </div>
                    </th>
                 </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let pdata of punchitemsData;let i = index" [attr.data-index]="i" class="hoverclr"
                  (click)="punchReports(pdata.COMPANY_ID,pdata.JOB_ID,pdata.VENDOR_ID,i,pdata.JOB_NO,pdata.ACTIVITY_ID)">
                    <td>
                      <p class="dashboard_text">{{pdata.JOB_NO}}</p> 
                    </td>
                    <td>
                      <p class="dashboard_text">{{pdata.CUSTOMER_NAME}}</p> 
                      <div *ngIf="this.dashboardreportsLoading && selectedpo === i && this.selectedThing === 'punchreports'"
                      class="list_loader list-loader-end">
                        <ngx-loader [loading]="this.dashboardreportsLoading"></ngx-loader>
                      </div>
                    </td>
                    <td>
                      <p class="dashboard_text">{{pdata.ACTIVITY_DESC}}</p>
                    </td>
                    <td>
                      <p class="dashboard_text">{{pdata.ROOM_DESC}}</p>
                    </td>
                    <td>
                      <p [ngClass]="pdata && pdata.PLI_CREATE_DATE == null ? 'mk-center' : 'dashboard_text'">
                        {{pdata && pdata.PLI_CREATE_DATE == null ?  "-" : moment(pdata.PLI_CREATE_DATE).utcOffset('-0400').format('ll') }}
                      </p>
                    </td>
                    <td>
                      <p class="dashboard_text">{{pdata.ITEM_DESC}}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          <section class="grid-style1" *ngIf="purchasedata && purchasedata.length > 0 && !searchingjob">
            <h5 class="font-weight-bold"><img src="assets/img/po.svg" class="calendar-style" />PO (Purchase Order)
              <span class="pull-right" style="color: #b7b7b7;" *ngIf="purchasedata && purchasedata.length > 0">
                Total Count: {{purchasedata && purchasedata.length > 0 ? purchasedata.length : ''}}
              </span>
            </h5>
            <hr class="new2 ">
            <div class="ScrollStyle table-scroll">
            <table>
              <thead>
                <tr>
                  <th
                    scope="col"
                    width="15%"
                  >
                    JOB#
                    <div class="sortitem">
                      <i (click)="poSort('pojobid')"
                        [ngClass]="selectedkey === 'pojobid' && reversesort ?  'fas fa-sort-up martop-9 ' : 'fas fa-sort-down martop-0 '"
                        class="marleft-2 pointer"></i>
                    </div>
                  </th>
                  <th
                    scope="col"
                    width="25%"
                  >
                  Customer Name
                    <div class="sortitem">
                      <i (click)="poSort('poname')"
                      [ngClass]="selectedkey === 'poname' && namesort ?  'fas fa-sort-up martop-9 ' : 'fas fa-sort-down martop-0 '"
                      class="marleft-2 pointer"></i>
                    </div>
                  </th>
                  <th
                    scope="col"
                    width="20%"
                  >
                  PO #
                    <div class="sortitem">
                      <i (click)="poSort('pono')"
                        [ngClass]="selectedkey === 'pono' && pnosort ?  'fas fa-sort-up martop-9 ' : 'fas fa-sort-down martop-0 '"
                        class="marleft-2 pointer"></i>
                    </div>
                  </th>
                  <th
                    scope="col"
                    width="20%"
                  >
                  PO Date
                    <div class="sortitem">
                      <i (click)="poSort('startdate')"
                      [ngClass]="selectedkey === 'startdate' && startdatesort ?  'fas fa-sort-up martop-9 ' : 'fas fa-sort-down martop-0 '"
                      class="marleft-2 pointer"></i>
                    </div>
                  </th>
                  <th
                    scope="col"
                    width="20%"
                  >
                  Amount
                    <div class="sortitem">
                      <i (click)="poSort('enddate')"
                        [ngClass]="selectedkey === 'enddate' && enddatesort ?  'fas fa-sort-up martop-9 ' : 'fas fa-sort-down martop-0 '"
                        class="marleft-2 pointer"></i>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody *ngIf="purchaseerr">
                <tr>
                  <td colspan="5" class="err-styles text-center border-0">
                    {{purchaseerr}}
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="purchasedata && purchasedata.length > 0 && !searchingjob && !purchaseerr">
                <tr *ngFor="let pdata of purchasedata;let i = index" [attr.data-index]="i" class="hoverclr"
                (click)="purchaseReports(pdata.PO_NO,i,pdata.JOB_NO)">
                  <td>
                    <p class="dashboard_text">{{pdata.JOB_NO}}</p>
                  </td>
                  <td>
                    <p class="dashboard_text">{{pdata.CUSTOMER_NAME}}</p>
                    <div *ngIf="this.dashboardreportsLoading && selectedpo === i && this.selectedThing === 'purchase'"
                      class="list_loader list-loader-end">
                      <ngx-loader [loading]="this.dashboardreportsLoading"></ngx-loader>
                    </div>
                  </td>
                  <td>
                    <p class="dashboard_text">{{pdata.PO_NO}}</p>
                  </td>
                  <td>
                    <p class="dashboard_text">
                      {{pdata && pdata.PRINTED_DATE == null ?  "-" : moment(pdata.PRINTED_DATE).utcOffset('-0400').format('ll') }}
                     </p>
                  </td>
                  <td>
                    <p [ngClass]="pdata && pdata.PO_AMT == null ? 'mk-center' : 'dashboard_text'">{{pdata && pdata.PO_AMT == null ?  "-" :  '$ ' + pdata.PO_AMT.toFixed(2)}}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
          </section>
          <section class="grid-style1" *ngIf="wpoData && wpoData.length > 0 && !searchingjob">
            <h5 class="font-weight-bold"><img src="assets/img/sticker.svg" class="calendar-style" /> Warranty WOs / POs
              <span class="pull-right" style="color: #b7b7b7;" *ngIf="wpoData && wpoData.length > 0">
                Total Count: {{wpoData && wpoData.length > 0 ? wpoData.length : ''}}
              </span>
            </h5>
            <hr class="new2">
            <div class="table-scroll ScrollStyle">
              <table>
                <thead>
                  <tr>
                    <th
                      scope="col"
                      width="15%"
                    >
                      JOB#
                      <div class="sortitem">
                        <i (click)="warrentyPo('wpojobid')"
                          [ngClass]="selectedkey === 'wpojobid' && reversesort ?  'fas fa-sort-up martop-9 ' : 'fas fa-sort-down martop-0 '" class="marleft-2 pointer"></i>
                      </div>
                    </th>
                    <th
                      scope="col"
                      width="25%"
                    >
                    Customer Name
                      <div class="sortitem">
                        <i (click)="warrentyPo('wpocname')" [ngClass]="selectedkey === 'wpocname' && namesort ?  'fas fa-sort-up martop-9 ' : 'fas fa-sort-down martop-0 '" class="marleft-2 pointer"></i>
                      </div>
                    </th>
                    <th
                      scope="col"
                      width="20%"
                    >
                    WO / PO #
                      <div class="sortitem">
                        <i (click)="warrentyPo('wpo')"  [ngClass]="selectedkey === 'wpo' && pnosort ?  'fas fa-sort-up martop-9 ' : 'fas fa-sort-down martop-0 '" class="marleft-2 pointer"></i>
                      </div>
                    </th>
                    <th
                      scope="col"
                      width="20%"
                    >
                    Create Date
                      <div class="sortitem">
                        <i (click)="warrentyPo('startdate')"  [ngClass]="selectedkey === 'startdate' && startdatesort ?  'fas fa-sort-up martop-9 ' : 'fas fa-sort-down martop-0 '" class="marleft-2 pointer"></i>  
                      </div>
                    </th>
                    <th
                      scope="col"
                      width="20%"
                    >
                    Amount
                      <div class="sortitem">
                        <i (click)="warrentyPo('wpoamount')" [ngClass]="selectedkey === 'wpoamount' && enddatesort ?  'fas fa-sort-up martop-9 ' : 'fas fa-sort-down martop-0 '" class="marleft-2 pointer"></i>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let wpodata Of wpoData;let w = index" [attr.data-index]="w" class="hoverclr"
                  (click)="warrentyReports(wpodata.WORK_ORDER_NO,w,wpodata.JOB_NO)">
                    <td>
                      <p class="dashboard_text">{{wpodata.JOB_NO}}</p>
                    </td>
                    <td>
                      <p class="dashboard_text">{{wpodata.CUSTOMER_NAME}}</p>
                      <div *ngIf="this.dashboardreportsLoading && this.selectedpo === w && this.selectedThing === 'warrenty'"
                            class="list_loader list-loader-end">
                          <ngx-loader [loading]="this.dashboardreportsLoading"></ngx-loader>
                      </div>
                    </td>
                    <td>
                      <p class="dashboard_text">{{wpodata.WORK_ORDER_NO}}</p>
                    </td>
                    <td>
                      <p class="dashboard_text">
                        {{wpodata && wpodata.CREATE_DATE == null ?  "-" : moment(wpodata.CREATE_DATE).utcOffset('-0400').format('ll') }}  
                       </p>
                    </td>
                    <td>
                      <p [ngClass]="wpodata && wpodata.PO_AMT == null ? 'mk-center' : 'dashboard_text'">{{wpodata && wpodata.PO_AMT == null ?  "-" :  '$ ' + wpodata.PO_AMT.toFixed(2) }}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          <section class="grid-style1" *ngIf="bidsdata && bidsdata.length > 0 && !searchingjob">
            <h5 class="font-weight-bold"><img src="assets/img/legal.svg" class="calendar-style" /> Bids
              <span class="pull-right" style="color: #b7b7b7;" *ngIf="bidsdata && bidsdata.length > 0">
                Total Count: {{bidsdata && bidsdata.length > 0 ? bidsdata.length : ''}}
              </span>
            </h5>
            <hr class="new2">
            <div class="ScrollStyle table-scroll bids-table">
            <table>
              <thead>
                <tr>
                  <th
                    scope="col"
                    width="8%"
                  >
                    PDF
                    <div class="sortitem">
                      <i class="marleft-2 pointer"></i>
                    </div>
                  </th>
                  <th
                    scope="col"
                    width="10%"
                  >
                  Bid Id
                  <div class="sortitem">
                  <i (click)="bidsSort('bidid')"
                  [ngClass]="selectedkey === 'bidid' && bididsort ?  'fas fa-sort-up martop-9 ' : 'fas fa-sort-down martop-0 '"
                  class="marleft-2 pointer"></i>
                  </div>
                  </th>
                  <th
                    scope="col"
                    width="10%"
                  >
                  JOB#
                  <div class="sortitem">
                  <i (click)="bidsSort('bjobid')"
                  [ngClass]="selectedkey === 'bjobid' && reversesort ?  'fas fa-sort-up martop-9 ' : 'fas fa-sort-down martop-0 '"
                  class="marleft-2 pointer"></i>
                  </div>
                  <th
                    scope="col"
                    width="19%"
                  >
                  Customer Name
                  <div class="sortitem">
                    <i (click)="bidsSort('bname')" [ngClass]="selectedkey === 'bname' && namesort ?  'fas fa-sort-up martop-9 ' : 'fas fa-sort-down martop-0 '"
                    class="marleft-2 pointer"></i>
                  </div>
                  </th>
                  <th
                    scope="col"
                    width="15%"
                  >
                  Description
                  <div class="sortitem">
                    <i (click)="bidsSort('biddesc')" [ngClass]="selectedkey === 'biddesc' && descriptionsort ?  'fas fa-sort-up martop-9 ' : 'fas fa-sort-down martop-0 '"
                    class="marleft-2 pointer"></i>
                  </div>
                  </th>
                  <th
                    scope="col"
                    width="16%"
                  >
                  Received On
                  <div class="sortitem">
                    <i (click)="bidsSort('recieveon')"
                    [ngClass]="selectedkey === 'recieveon' && startdatesort ?  'fas fa-sort-up martop-9 ' : 'fas fa-sort-down martop-0 '"
                    class="marleft-2 pointer"></i>
                  </div>
                  </th>
                  <th
                    scope="col"
                    width="13%"
                  >
                  Due On
                  <div class="sortitem">
                    <i (click)="bidsSort('dueon')"
                    [ngClass]="selectedkey === 'dueon' && enddatesort ?  'fas fa-sort-up martop-9 ' : 'fas fa-sort-down martop-0 '"
                    class="marleft-2 pointer"></i>
                  </div>
                  </th>
                  <th
                    scope="col"
                    width="9%"
                  >
                  Status
                  <div class="sortitem">
                    <i (click)="bidsSort('bidstatus')"
                    [ngClass]="selectedkey === 'bidstatus' && bidstatus ?  'fas fa-sort-up martop-9 ' : 'fas fa-sort-down martop-0 '"
                    class="marleft-2 pointer"></i>
                  </div>
                  </th>
                </tr>
              </thead>
              <tbody *ngIf="canaryReleaseUserFlag">
                <tr
                *ngFor="let bdata of bidsdata;let i = index" [attr.data-index]="i" class="hoverclr "
                >              
                <td>
                  <img class="search-icon" src="../../../assets/img/PDF-Icon.png" (click)="bidsReports(bdata.BID_ID,bdata.JOB_ID,bdata.VENDOR_ID,i,bdata.JOB_NO)">
                </td>
                <td (click)="raiseBids(bdata.BID_ID,bdata.BID_VENDOR_ID,bdata.JOB_ID,bdata.JOB_NO, bdata.VENDOR_ID)">
                  <p [ngClass]="bdata && bdata.BID_ID == null ? 'mk-center' : 'dashboard_text'">{{bdata && bdata.BID_ID == null ?  "-" : bdata.BID_ID}}</p>
                </td>
                <td (click)="raiseBids(bdata.BID_ID,bdata.BID_VENDOR_ID,bdata.JOB_ID,bdata.JOB_NO, bdata.VENDOR_ID)">
                  <p [ngClass]="bdata && bdata.JOB_NO == null ? 'mk-center' : 'dashboard_text'">{{bdata && bdata.JOB_NO == null ?  "-" : bdata.JOB_NO}}</p>
                </td>
                <td (click)="raiseBids(bdata.BID_ID,bdata.BID_VENDOR_ID,bdata.JOB_ID,bdata.JOB_NO, bdata.VENDOR_ID)">
                  <p [ngClass]="bdata && bdata.CUSTOMER_NAME == null ? 'mk-center' : 'dashboard_text'">
                    {{bdata && bdata.CUSTOMER_NAME == null ?  "-" : bdata.CUSTOMER_NAME}}
                  </p>
                  <div *ngIf="this.dashboardreportsLoading && selectedpo === i && this.selectedThing === 'bids'"
                    class="list_loader">
                    <ngx-loader [loading]="this.dashboardreportsLoading"></ngx-loader>
                  </div>
                </td>
                <td (click)="raiseBids(bdata.BID_ID,bdata.BID_VENDOR_ID,bdata.JOB_ID,bdata.JOB_NO, bdata.VENDOR_ID)">
                  <p class="dashboard_text">{{bdata.BID_DESC}}
                </td>
                <td (click)="raiseBids(bdata.BID_ID,bdata.BID_VENDOR_ID,bdata.JOB_ID,bdata.JOB_NO, bdata.VENDOR_ID)">
                  <p class="dashboard_text"> 
                    {{bdata && bdata.RECEIVED == null ?  "-" : moment(bdata.RECEIVED).utcOffset('-0400').format('ll') }}
                  </p>
                </td>
                <td (click)="raiseBids(bdata.BID_ID,bdata.BID_VENDOR_ID,bdata.JOB_ID,bdata.JOB_NO, bdata.VENDOR_ID)">
                  <p class="dashboard_text">
                    {{bdata && bdata.DUE_DATE == null ?  "-" : moment(bdata.DUE_DATE).utcOffset('-0400').format('ll') }}
                  </p>
                </td>
                <td (click)="raiseBids(bdata.BID_ID,bdata.BID_VENDOR_ID,bdata.JOB_ID,bdata.JOB_NO, bdata.VENDOR_ID)">
                  <span [ngClass]="bdata.STATUS === 'Received' ? 'butn-style open_btn' : bdata.STATUS === 'Approved' || bdata.STATUS === 'Submitted'? 'butn-style closing': 'butn-style closed'">{{bdata.STATUS}}</span>
                </td>
                </tr>
              </tbody>
              <tbody *ngIf="!canaryReleaseUserFlag">
                <tr
                *ngFor="let bdata of bidsdata;let i = index" [attr.data-index]="i" class="hoverclr "
                >  
                <td>
                  <img class="search-icon" src="../../../assets/img/PDF-Icon.png" (click)="bidsReports(bdata.BID_ID,bdata.JOB_ID,bdata.VENDOR_ID,i,bdata.JOB_NO)">
                </td>
                <td (click)="bidsReports(bdata.BID_ID,bdata.JOB_ID,bdata.VENDOR_ID,i,bdata.JOB_NO)">
                  <p [ngClass]="bdata && bdata.BID_ID == null ? 'mk-center' : 'dashboard_text'">{{bdata && bdata.BID_ID == null ?  "-" : bdata.BID_ID}}</p>
                </td>
                <td (click)="bidsReports(bdata.BID_ID,bdata.JOB_ID,bdata.VENDOR_ID,i,bdata.JOB_NO)">
                  <p [ngClass]="bdata && bdata.JOB_NO == null ? 'mk-center' : 'dashboard_text'">{{bdata && bdata.JOB_NO == null ?  "-" : bdata.JOB_NO}}</p>
                </td>
                <td (click)="bidsReports(bdata.BID_ID,bdata.JOB_ID,bdata.VENDOR_ID,i,bdata.JOB_NO)">
                  <p [ngClass]="bdata && bdata.CUSTOMER_NAME == null ? 'mk-center' : 'dashboard_text'">
                    {{bdata && bdata.CUSTOMER_NAME == null ?  "-" : bdata.CUSTOMER_NAME}} {{bdata.VENDOR_ID}}
                  </p>
                </td>
                <td (click)="bidsReports(bdata.BID_ID,bdata.JOB_ID,bdata.VENDOR_ID,i,bdata.JOB_NO)">
                  <p class="dashboard_text">{{bdata.BID_DESC}}
                </td>
                <td (click)="bidsReports(bdata.BID_ID,bdata.JOB_ID,bdata.VENDOR_ID,i,bdata.JOB_NO)">
                  <p class="dashboard_text"> 
                    {{bdata && bdata.RECEIVED == null ?  "-" : moment(bdata.RECEIVED).utcOffset('-0400').format('ll') }}
                  </p>
                </td>
                <td (click)="bidsReports(bdata.BID_ID,bdata.JOB_ID,bdata.VENDOR_ID,i,bdata.JOB_NO)">
                  <p class="dashboard_text">
                    {{bdata && bdata.DUE_DATE == null ?  "-" : moment(bdata.DUE_DATE).utcOffset('-0400').format('ll') }}
                  </p>
                </td>
                <td (click)="bidsReports(bdata.BID_ID,bdata.JOB_ID,bdata.VENDOR_ID,i,bdata.JOB_NO)">
                  <span
                  [ngClass]="bdata.STATUS === 'Received' ? 'butn-style open_btn' : bdata.STATUS === 'Approved' || bdata.STATUS === 'Submitted'? 'butn-style closing': 'butn-style closed'">{{bdata.STATUS}}</span>
                </td>
                </tr>
              </tbody>
            </table>
            </div>
          </section>
          <div 
            *ngIf="this.companyLoading"
            class="company_loader">
            <ngx-loader [loading]="true"></ngx-loader>
          </div>
          <div 
            *ngIf="this.companyDetails == undefined && !this.companyLoading">
            <p class="text-center dtext">
              {{companyErrMsg}}
            </p>
          </div> 
          <div 
            *ngIf="scheduleLoading || warrentyLoading || punchLoading || bidsLoading || poLoading "
            class="schedule_loader">
            <ngx-loader [loading]="true"></ngx-loader>
          </div>
          <div 
            *ngIf="!nojoberrMsg && this.companyDetails != undefined && this.scheduledata && this.scheduledata.length === 0 && !this.scheduleLoading &&
            this.purchasedata && this.purchasedata.length === 0 && !this.poLoading &&
            this.bidsdata && this.bidsdata.length === 0 && !this.bidsLoading &&
            this.punchitemsData && this.punchitemsData.length === 0 && !this.punchLoading &&
            this.wpoData && this.wpoData.length === 0 && !this.warrentyLoading">
            <p class="text-center dtext" *ngIf="!searchingjob">
              No Jobs assigned to Vendor.
            </p>
        </div>
        <div *ngIf="nojoberrMsg">
          <p class="text-center">
            {{nojoberrMsg}}
          </p>
        </div>
          <div *ngIf="searchingjob">
            <p class="text-center">
              Select a job to see reports
            </p>
          </div>
        </div>
        <div class="dashboard-tabs maincontentdiv1">
          <div class="grid-style1" style="background: #fbfbfb;">
            <div [ngClass]="showmobmenu ? 'scroll_head' : 'hide_scroll_head'">
              <h4 *ngIf="showmobmenu" class="mob_scroll_head">
                {{subPopToggle === 1 ? 'Schedule' : subPopToggle === 2 ? 'PO' : 
                subPopToggle === 3 ?'Punch Lists' : subPopToggle === 4 ? 'Bids': subPopToggle === 5 ? 'Warranty WOs / POs' : ''}}
              </h4>
            </div>
            <div class="mob_scroll_list" (scroll)="hideMenu($event)">
              <div class="mainnav mobsubnav">
                <ul>
                  <li> <a (click)="setsubpopup(1)" [ngClass]="{'but-sub-active': subPopToggle == 1}" *ngIf="scheduledata && scheduledata.length > 0" >Schedule</a></li>
                  <li> <a (click)="setsubpopup(3)" [ngClass]="{'but-sub-active': subPopToggle == 3}" *ngIf="punchitemsData && punchitemsData.length > 0" >Punch Lists</a></li>
                  <li> <a (click)="setsubpopup(2)" [ngClass]="{'but-sub-active': subPopToggle == 2}" *ngIf="purchasedata && purchasedata.length > 0">Po</a></li>
                  <li> <a (click)="setsubpopup(5)" [ngClass]="{'but-sub-active': subPopToggle == 5}" *ngIf="wpoData && wpoData.length > 0">Warranty WOs / POs</a></li>
                  <li> <a (click)="setsubpopup(4)" [ngClass]="{'but-sub-active': subPopToggle == 4}" *ngIf="bidsdata && bidsdata.length > 0 && !searchingjob">Bids</a></li>
                </ul>
              </div>
              <div *ngIf="subPopToggle == 1">
                <div *ngIf="scheduledata && scheduledata.length > 0">
                  <div *ngFor="let sdata of scheduledata;let i = index" [attr.data-index]="i">
                    <div class="mob-table-root" (click)="scheduleReports(sdata.COMPANY_ID,sdata.JOB_ID,sdata.VENDOR_ID,i,sdata.JOB_NO,sdata.ACTIVITY_ID)">
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp pr1">
                          <p class="mob-tbl-hd">Job #</p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">{{sdata.JOB_NO}}</p>
                        </div>
                      </div>
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-hd">Customer Name</p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">{{sdata.CUSTOMER_NAME}}</p>
                        </div>
                      </div>
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-hd">Activity</p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">{{sdata.ACTIVITY_DESC}}</p>
                        </div>
                      </div>
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-hd">Create Date</p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">
                            {{sdata && sdata.ACTUAL_START_DATE == null ?  "-" : moment(sdata.ACTUAL_START_DATE).utcOffset('-0400').format('ll') }}      
                          </p>
                        </div>
                      </div>
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-hd">Finish Date</p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">
                          {{sdata && sdata.ACTUAL_FINISH_DATE == null ?  "-" : moment(sdata.ACTUAL_FINISH_DATE).utcOffset('-0400').format('ll') }}  
                          </p>
                        </div>
                      </div>
                      <div *ngIf="this.dashboardreportsLoading && this.selectedpo === i && this.selectedThing === 'schedule'"
                        class="mob_list_loader">
                        <ngx-loader [loading]="this.dashboardreportsLoading"></ngx-loader>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="subPopToggle == 2">
                <div *ngIf="purchasedata && purchasedata.length > 0">
                  <div *ngFor="let pdata of purchasedata;let i = index" [attr.data-index]="i">
                    <div class="mob-table-root" (click)="purchaseReports(pdata.PO_NO,i,pdata.JOB_NO)">
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-hd">Job #</p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">{{pdata.JOB_NO}}</p>
                        </div>
                      </div>
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-hd">Customer Name</p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">{{pdata.CUSTOMER_NAME}}</p>
                        </div>
                      </div>
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-hd">PO#</p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">{{pdata.PO_NO}}</p>
                        </div>
                      </div>
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-hd">PO Date</p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">
                          {{pdata && pdata.PRINTED_DATE == null ?  "-" : moment(pdata.PRINTED_DATE).utcOffset('-0400').format('ll') }}    
                          </p>
                        </div>
                      </div>
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-hd">Amount</p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">{{pdata && pdata.PO_AMT == null ?  "-" :  '$ ' + pdata.PO_AMT.toFixed(2)}}
                          </p>
                        </div>
                      </div>
                      <div *ngIf="this.dashboardreportsLoading && selectedpo === i && this.selectedThing === 'purchase'"
                        class="mob_list_loader">
                        <ngx-loader [loading]="this.dashboardreportsLoading"></ngx-loader>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="subPopToggle == 3">
                <div *ngIf="punchitemsData && punchitemsData.length > 0">
                  <div *ngFor="let pdata of punchitemsData;let i = index" [attr.data-index]="i">
                    <div class="mob-table-root" (click)="punchReports(pdata.COMPANY_ID,pdata.JOB_ID,pdata.VENDOR_ID,i,pdata.JOB_NO,pdata.ACTIVITY_ID)">
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp pr3">
                          <p class="mob-tbl-hd">Job #</p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">{{pdata.JOB_NO}}</p>
                        </div>
                      </div>
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-hd">Customer Name</p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">{{pdata.CUSTOMER_NAME}}</p>
                        </div>
                      </div>
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-hd">Activity</p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">{{pdata.ACTIVITY_DESC}}</p>
                        </div>
                      </div>
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-hd">Room</p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">{{pdata.ROOM_DESC}}</p>
                        </div>
                      </div>
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-hd">Start Date</p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">{{pdata && pdata.PLI_CREATE_DATE == null ?  "-" : moment(pdata.PLI_CREATE_DATE).utcOffset('-0400').format('ll') }}</p>
                        </div>
                      </div>
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-hd">Item</p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">{{pdata.ITEM_DESC}}</p>
                        </div>
                      </div>

                      <div *ngIf="this.dashboardreportsLoading && selectedpo === i && this.selectedThing === 'punchreports'"
                        class="mob_list_loader">
                        <ngx-loader [loading]="this.dashboardreportsLoading"></ngx-loader>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="subPopToggle == 4">
                <div *ngIf="bidsdata && bidsdata.length > 0">
                  <div *ngFor="let bdata of bidsdata;let i = index" [attr.data-index]="i">

                    

                    <!-- current user go to bids start -->
                    <div  class="mob-table-root">
                       
                      <div class="mob-tbl-flx dwnloadpdf">
                        <div class="mob-tbl-flx-sp pr4">
                          <p class="mob-tbl-hd">Pdf</p>
                        </div>
                        <div class="mob-tbl-flx-sp downloadico" (click)="bidsReports(bdata.BID_ID,bdata.JOB_ID,bdata.VENDOR_ID,i,bdata.JOB_NO)">
                          <img class="search-icon" src="../../../assets/img/PDF-Icon.png">
                          <!-- Download<div>Pdf</div> -->
                        </div>
                    </div>
                    <div *ngIf="canaryReleaseUserFlag"  (click)="raiseBids(bdata.BID_ID,bdata.BID_VENDOR_ID,bdata.JOB_ID,bdata.JOB_NO, bdata.VENDOR_ID)">
                     
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp pr4">
                          <p class="mob-tbl-hd">Job #</p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">{{bdata && bdata.JOB_NO == null ?  "-" : bdata.JOB_NO}}</p>
                        </div>
                      </div>
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-hd">Customer Name</p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">{{bdata && bdata.CUSTOMER_NAME == null ?  "-" : bdata.CUSTOMER_NAME}}</p>
                        </div>
                      </div>
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-hd">Description</p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">{{bdata.BID_DESC}}</p>
                        </div>
                      </div>
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-hd">Received on </p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">{{bdata && bdata.RECEIVED == null ?  "-" : moment(bdata && bdata.RECEIVED).utcOffset('-0400').format('ll') }}</p>
                        </div>
                      </div>
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-hd">Due on</p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">{{bdata && bdata.DUE_DATE == null ?  "-" : moment(bdata && bdata.DUE_DATE).utcOffset('-0400').format('ll') }}</p>
                        </div>
                      </div>
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-hd">Status</p>
                        </div>
                        <div class="mob-tbl-flx-sp mt-2 ml-3">
                          <span
                          [ngClass]="bdata.STATUS === 'Received' ? 'butn-style open_btn' : bdata.STATUS === 'Approved' || bdata.STATUS === 'Submitted'? 'butn-style closing': 'butn-style closed'">{{bdata.STATUS}}</span>
                        </div>
                      </div>
                      <div *ngIf="this.dashboardreportsLoading && selectedpo === i && this.selectedThing === 'bids'"
                        class="mob_list_loader">
                        <ngx-loader [loading]="this.dashboardreportsLoading"></ngx-loader>
                      </div>
                    </div>
                    
                    <!-- current user go to bids end -->

                    <!-- not current user download report start  -->
                    <div *ngIf="!canaryReleaseUserFlag"   (click)="bidsReports(bdata.BID_ID,bdata.JOB_ID,bdata.VENDOR_ID,i,bdata.JOB_NO)">
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp pr4">
                          <p class="mob-tbl-hd">Job #</p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">{{bdata && bdata.JOB_NO == null ?  "-" : bdata.JOB_NO}}</p>
                        </div>
                      </div>
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-hd">Customer Name</p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">{{bdata && bdata.CUSTOMER_NAME == null ?  "-" : bdata.CUSTOMER_NAME}}</p>
                        </div>
                      </div>
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-hd">Description</p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">{{bdata.BID_DESC}}</p>
                        </div>
                      </div>
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-hd">Received on </p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">{{bdata && bdata.RECEIVED == null ?  "-" : moment(bdata && bdata.RECEIVED).utcOffset('-0400').format('ll') }}</p>
                        </div>
                      </div>
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-hd">Due on</p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">{{bdata && bdata.DUE_DATE == null ?  "-" : moment(bdata && bdata.DUE_DATE).utcOffset('-0400').format('ll') }}</p>
                        </div>
                      </div>
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-hd">Status</p>
                        </div>
                        <div class="mob-tbl-flx-sp mt-2 ml-3">
                          <span
                          [ngClass]="bdata.STATUS === 'Received' ? 'butn-style open_btn' : bdata.STATUS === 'Approved' || bdata.STATUS === 'Submitted'? 'butn-style closing': 'butn-style closed'">{{bdata.STATUS}}</span>
                        </div>
                      </div>
                      <div *ngIf="this.dashboardreportsLoading && selectedpo === i && this.selectedThing === 'bids'"
                        class="mob_list_loader">
                        <ngx-loader [loading]="this.dashboardreportsLoading"></ngx-loader>
                      </div>
                    </div>
                    <!-- not current user download report end  -->
                  </div>
                  </div>
                </div>
              </div>
              <div *ngIf="subPopToggle == 5">
                <div *ngIf="wpoData && wpoData.length > 0">
                  <div *ngFor="let wpodata Of wpoData;let w = index" [attr.data-index]="w">
                    <div class="mob-table-root" (click)="warrentyReports(wpodata.WORK_ORDER_NO,w,wpodata.JOB_NO)">
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp pr5">
                          <p class="mob-tbl-hd">Job #</p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">{{wpodata.JOB_NO}}</p>
                        </div>
                      </div>
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-hd">Customer Name</p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">{{wpodata.CUSTOMER_NAME}}</p>
                        </div>
                      </div>
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-hd"> WO / PO #</p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">{{wpodata.WORK_ORDER_NO}}</p>
                        </div>
                      </div>
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-hd">Create Date</p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">
                            {{wpodata && wpodata.CREATE_DATE == null ?  "-" : moment(wpodata.CREATE_DATE).utcOffset('-0400').format('ll') }}
                          </p>
                        </div>
                      </div>
                      <div class="mob-tbl-flx">
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-hd">Amount</p>
                        </div>
                        <div class="mob-tbl-flx-sp">
                          <p class="mob-tbl-cnt">
                            {{wpodata && wpodata.PO_AMT == null ?  "-" :  '$ ' + wpodata.PO_AMT.toFixed(2) }}  
                          </p>
                        </div>
                      </div>
                      <div *ngIf="this.dashboardreportsLoading && this.selectedpo === w && this.selectedThing === 'warrenty'"
                        class="mob_list_loader">
                        <ngx-loader [loading]="this.dashboardreportsLoading"></ngx-loader>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngIf="this.scheduleLoading || this.warrentyLoading || this.punchLoading || this.bidsLoading || this.poLoading "
                class="schedule_loader">
                <ngx-loader [loading]="true"></ngx-loader>
              </div>
              <div 
                *ngIf="!nojoberrMsg && this.companyDetails != undefined && this.scheduledata && this.scheduledata.length === 0 && !this.scheduleLoading &&
                this.purchasedata && this.purchasedata.length === 0 && !this.poLoading &&
                this.bidsdata && this.bidsdata.length === 0 && !this.bidsLoading &&
                this.punchitemsData && this.punchitemsData.length === 0 && !this.punchLoading &&
                this.wpoData && this.wpoData.length === 0 && !this.warrentyLoading">
                <p class="text-center dtext" *ngIf="!searchingjob">
                  No Jobs assigned to Vendor.
                </p>
              </div>
              <div *ngIf="nojoberrMsg">
                <p class="text-center">
                  {{nojoberrMsg}}
                </p>
              </div>
              <div *ngIf="searchingjob">
                <p class="text-center">
                  Select a job to see reports
                </p>
              </div>
              <div style="height: 120px;clear: both;"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="maincontentdiv homebg" *ngIf="loginPopToggle == 2">
        <div class="mnlinks page-header" *ngIf="companyDetails">
          <i><img src="assets/img/home-new.svg"></i>
          <span class="site" *ngIf="companyDetails">{{companyDetails.COMPANY_NAME}}</span>
        </div>
        <div id="company" class="card">
          <div class="card-body" *ngIf="companyDetails">
            <p class="mt-1">{{companyDetails.ADDRESS_LINE_1}}</p>
            <p>{{companyDetails.CITY_NAME}}, {{companyDetails.STATE_ID}} {{companyDetails.ZIP_CODE}} </p>
            <p class="teltext"> <i class="num-preview fa fa-phone"></i> <a href="tel:{{companyDetails.PHONE_NO}}"
                class="sitehead2"> {{companyDetails.PHONE_NO}}</a></p>
          </div>
        </div>
        <div id="legal" class="card" *ngIf="legalDetails && legalDetails.COMMUNITY_NAME && legalDetails.SITE_LEGAL_ADDR">
          <div class="card-header text-center">
            <h6 class="headline">Site Legal Address</h6>
          </div>
          <div class="card-body pb-3">
            <!-- <p class="sub" >Site Legal Address</p> -->
            <p><strong>Community:</strong> {{legalDetails.COMMUNITY_NAME}} </p>
            <p><strong>Site Legal:</strong> {{legalDetails.SITE_LEGAL_ADDR}} </p>
            <p><strong>Address:</strong>
              {{legalDetails.ADDRESS_LINE_1}}{{legalDetails.ADDRESS_LINE_2 ? ','+legalDetails.ADDRESS_LINE_2 : ''}}</p>
            <p>{{legalDetails.CITY_NAME}}, {{legalDetails.STATE_ID}} {{legalDetails.ZIP_CODE}}
              {{legalDetails.ZIP_EXTENSION ? '-' + legalDetails.ZIP_EXTENSION : ''}}</p>
          </div>
        </div>
        <div id="legal" class="vendor card" *ngIf="contacts && contacts.length > 0">
          <div class="card-header text-center">
            <h6 class="headline">Contact Details</h6>
          </div>
          <div class="card-body pb-5">
            <div *ngFor="let contact of contacts">
              <div class="row mbordr ml-2">
                <div class="col-md-3">
                  <div class="demo-icon">
                    <div class="icon-preview">
                      <!-- <img src="assets\img\user.png" style="height:26px"> -->
                      <i class="fa fa-user"></i>
                    </div>
                    <div class="icon-class">
                      <span class="sitehead2" *ngIf="contact.CONTACT_NAME">{{contact.CONTACT_NAME}}</span>
                      <span class="sitehead2" *ngIf="contact.EMPLOYEE_NAME">{{contact.EMPLOYEE_NAME}}</span>
                      <span class="sitehead3" *ngIf="contact.CONTACT_DESC">{{contact.CONTACT_DESC}}</span>
                      <span class="sitehead3" *ngIf="contact.TITLE">{{contact.TITLE}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="demo-icon">
                    <div class="icon-preview">
                      <i class="fa fa-phone"></i>
                    </div>
                    <div class="icon-class">
                      <a class="sitehead2" href="tel:{{contact.PHONE_NO}}" *ngIf="contact.PHONE_NO"> {{contact.PHONE_NO}}</a>
                      <a class="sitehead2" *ngIf="!contact.PHONE_NO">Not Available</a>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="demo-icon">
                    <div class="icon-preview">
                      <i class="fa fa-envelope-open"></i>
                    </div>
                    <div class="icon-class">
                      <a href="mailto:{{contact.EMAIL_ADDRESS}}" *ngIf="contact.EMAIL_ADDRESS"
                        class="sitehead2 undrline">{{contact.EMAIL_ADDRESS}}</a>
                      <a 
                        class="sitehead2" *ngIf="!contact.EMAIL_ADDRESS">Not Available</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="this.contactsLoading" style="position: relative;margin-top: 2%;">
          <ngx-loader [loading]="this.contactsLoading"></ngx-loader>
        </div>
      </div>
      <div class="maincontentdiv5 homebg" *ngIf="loginPopToggle == 3">
        <div>
          <div class="report-style">REPORTS</div>
        </div>
        <div class="sort-style" *ngIf="reports && reports.length > 0">
          <span>Sort by:</span>
          <span [ngClass]="reportascensort ? 'sort-box' : 'sort-box1'" (click)="sortReports(0)">A-Z</span>
          <span [ngClass]="reportascensort ? 'sort-box1' : 'sort-box'" (click)="sortReports(1)">Z-A</span>
        </div>
        <div id="documents" *ngIf="reports && reports.length > 0">
          <div *ngFor="let report of reports;let i = index" [attr.data-index]="i">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">
                    <div class="demo-icon1">
                      <img class="dimg" src="../assets/img/PDF-Icon.png" />
                    </div>
                  </div>
                  <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 mt-3 txt-center">
                    <a class="dquotes"(click)="jobSelectionReports(report.MODULE_NAME_ID,report.MODULE_NAME)">
                      <span class="txt">{{report.MODULE_NAME}}</span>
                    </a>
                  </div>
                </div>
                <div *ngIf="selectedpo == report.MODULE_NAME_ID && this.jobselectionreportsLoading" class="list_loader">
                  <ngx-loader [loading]="this.jobselectionreportsLoading"></ngx-loader>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="this.reportsloading" style="position: relative;margin-bottom: 2%;">
          <ngx-loader [loading]="this.reportsloading"></ngx-loader>
        </div>
        <div class="text-center">
          <p *ngIf="reportserr">
            {{reportserr}}
          </p>
        </div>
        <div>
          <div class="report-style">DOCUMENTS</div>
        </div>
        <div class="sort-style" [style.display]="docsList && docsList.length > 0 ? 'block' : 'none'">
          <span>Sort by:</span>
          <span [ngClass]="ascensort ? 'sort-box' : 'sort-box1'" (click)="sortDocs(0)">A-Z</span>
          <span [ngClass]="ascensort ? 'sort-box1' : 'sort-box'" (click)="sortDocs(1)">Z-A</span>
          <span [ngClass]="datesort ? 'sort-box1 ml-4' : 'sort-box ml-4'" (click)="sortDocsbyDate(0)">Newest</span>
          <span [ngClass]="datesort ? 'sort-box' : 'sort-box1'" (click)="sortDocsbyDate(1)">Oldest</span>
        </div>
        <div id="documents">
          <div *ngIf="docsList && docsList.length > 0">
            <div *ngFor="let dlist of docsList">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">
                      <div class="demo-icon">
                        <img class="dimg" src="../assets/img/PDF-Icon.png" />
                      </div>
                    </div>
                    <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 mtop15 doc-center">
                      <a class="dquotes"
                        (click)="openDocumentpopup(dlist.ASSET_ID,dlist.URL_FILE_PATH,dlist.ASSET_NAME,dlist.FILE_NAME)">
                        <span class="text-left txt fw400">{{dlist.ASSET_NAME}}</span></a>
                      <p class="date-style">{{moment(dlist.MODIFICATION_DATE).format('MMMM D, YYYY LT')}}</p>
                    </div>
                  </div>
                </div>
                <div *ngIf="selectedjob == dlist.ASSET_ID && this.encodedocsloading" style="position: relative;margin-bottom: 2%;">
                  <ngx-loader [loading]="this.encodedocsloading"></ngx-loader>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="this.docsloading" style="position: relative;margin-bottom: 2%;">
            <ngx-loader [loading]="this.docsloading"></ngx-loader>
          </div>
          <div class="text-center">
            <p *ngIf="docerrMsg">
              {{docerrMsg}}
            </p>
          </div>
        </div>
      </div>
      <div class="maincontentdiv homebg" *ngIf="loginPopToggle == 4">
        <div class="sort-style1" [style.display]="galleryimages && galleryimages.length > 0 ? 'block' : 'none'">
          <span>Sort by:</span>
          <span [ngClass]="assetsascensort ? 'sort-box' : 'sort-box1'" (click)="sortassets(0)">A-Z</span>
          <span [ngClass]="assetsascensort ? 'sort-box1' : 'sort-box'" (click)="sortassets(1)">Z-A</span>
          <span [ngClass]="assetsdatesort ? 'sort-box1 ml-4' : 'sort-box ml-4'" (click)="sortassetsbydate(0)">Newest</span>
          <span [ngClass]="assetsdatesort ? 'sort-box' : 'sort-box1'" (click)="sortassetsbydate(1)">Oldest</span>
        </div>
        <!-- Page Content -->
        <div class="container height-90" *ngIf="galleryimages && galleryimages.length > 0">
          <div>
            <div class="assets_disp_behav">
              <div class="assests_styles" *ngFor="let gimages of galleryimages">
                <a (click)="openGallery(galleryContent,gimages,'builderassets')">
                  <img class="img-fluid img-thumbnail img-rounded assests_styles_img" [src]="gimages.URL_FILE_PATH" alt="">
                </a>
                <p class="img-text">{{gimages.ASSET_NAME ? gimages.ASSET_NAME : gimages.FILE_NAME}}</p>
              </div>
            </div>
          </div>
          <div class="gallery_spc"></div>
        </div>
        <div *ngIf="this.galleryloading" style="position: relative;">
          <ngx-loader [loading]="this.galleryloading"></ngx-loader>
        </div>
        <div *ngIf="galleryerrMsg">
          <p class="text-center">
            {{galleryerrMsg}}
          </p>
        </div>
      </div>
      <div class="maincontentdiv homebg" *ngIf="loginPopToggle == 5">
        <div class="card1" *ngIf= "bookmarks && bookmarks.length > 0">
          <div class="row">
            <div class="col-md-12">
              <h6 class="bookmark-style">Bookmarks {{bookmarks && bookmarks.length > 0 ? '('+bookmarks.length+')' : ''}}
                <span>
                  <i class="fas fa-sort-down martop-0 marleft-2 pointer" (click)="booksort('bookname')" [ngClass]="selectedkey === 'bookname' && namesort ?  'fas fa-sort-up martop-9 ' : 'fas fa-sort-down martop-0 '"></i>
                </span>
              </h6>
              <hr>
              <div *ngIf="this.bookmarksLoading" style="position: relative;">
                <ngx-loader [loading]="this.bookmarksLoading"></ngx-loader>
              </div>
              <div *ngFor="let bookmark of bookmarks">
                <div class="box-style">
                  <p class="font-style dark-color mono-family">{{bookmark.BOOKMARK_DESC}}</p>
                  <hr class="new5">
                  <div class="links">
                    <!-- <p class="web"> {{b.web_data}}</p> -->
                    <a href="{{bookmark.BOOKMARK_URL}}" class="book-link" target="_blank">Visit link now</a>
                  </div>
                  <hr style="margin-top:0rem!important;">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="this.bookmarkserr">
          <p class="text-center reduce-sp">
            {{bookmarkserr}}
          </p>
        </div>          
      </div>
    </div>
  </div>
  <!-- <p class="copyright text-center footer">
    © AR Franchising, Inc. 2020 All rights reserved.
</p>   -->
</div>
<div *ngIf="downloadsuccess" class="download-popup">
  <p class="download-popup-text">
    Downloaded Successfully
  </p>
</div>
<div>

  <!-- pdf pop-up start -->
  <ng-template #reportsdoccontents let-c="close" let-d="dismiss">
    <div class="login-box" *ngIf="reportsDesc">
      <div class="login-sec docmarg">
        <div class="nav-tabs">
          <div class="w3-bar w3-black" *ngIf="urlexists !== 0">
            <h4>
              <span class="position5 close-i" (click)="d('Cross click')"> <i class="fa fa-times"></i></span>
            </h4>
          </div>
          <div id="membership1" class="w3-container w3-border Buton">
            <div [ngClass]="{'doc-height': pdfViewerEnabled}" class="file-box" *ngIf="doctype === 'pdf'">
              <object [data]="reportsDesc" type="application/pdf" width="100%" id="doc-pdf" height="100%" style="margin-top: 15px;">
                <div class="popup-styles">
                  <div>
                    <p *ngIf="urlexists === 1" class="popup-p-styles">
                      <i class="fa fa-check popup-icon-styles"></i> Your file is ready for Download.
                    </p>
                    <p *ngIf="urlexists === 0" class="popup-p-styles">
                      <i class="fa fa-exclamation-triangle popup-wrng-styles"></i> Sorry we were unable to find that File.
                      </p>
                  </div>
                  <div>
                    <a  *ngIf="urlexists === 1" (click)="downloadpdf(reportsDesc)" class="pdf-download-btn">
                      Download Now
                    </a>
                    <a *ngIf="urlexists === 0" class="pdf-download-btn" (click)="d('Cross click')">
                      Close Now
                    </a>
                  </div>
                </div>
              </object>
           <!--    <div style="width: 100%;height:100%">
               <ng2-pdfjs-viewer [pdfSrc]="reportsDesc"></ng2-pdfjs-viewer>
              </div> -->
            </div>
            <div class="file-box" *ngIf="doctype !== 'pdf'">
                <div class="popup-styles" *ngIf="urlexists === 0">
                    <div>
                      <p class="popup-p-styles">
                        <i class="fa fa-exclamation-triangle popup-wrng-styles"></i> Sorry we were unable to find that File.
                        </p>
                    </div>
                    <div>
                      <a class="pdf-download-btn" (click)="d('Cross click')">
                        Close Now
                      </a>
                    </div>
                  </div>

              <div *ngIf="urlexists === 1">
                <div>
                  <a (click)="downloaddoc(reportsDesc)">
                    <i class="fa fa-download pull-right" style="margin-right: 5%;margin-bottom: 1%;cursor: pointer;"></i>
                  </a>
                </div>
                <ngx-doc-viewer [url]="reportsDesc" id="docs" viewer="office" disableContent="popout"
                  style="width:100%; height:100vh; display:grid; object-fit:scale-down;scroll-behavior: smooth;">
                </ngx-doc-viewer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <!-- pdf pop-up end -->

  <!-- image gallery pop-up start -->
  <ng-template #galleryContent let-c="close" let-d="dismiss">
    <div class="">
      <div class="login-box">
        <div class="login-sec">
          <h4>
            <!-- <span class="position7 arrow-l" (click)="showNext()"><i class="fas fa-angle-left"></i></span>
          <span class="position6 arrow-r"><i class="fas fa-angle-right"></i></span> -->
            <span class="position5 close-i" (click)="d('Cross click')"> <i class="fa fa-times"></i></span>
          </h4>
          <div class="photo-gallery">
            <div class="photo-gallery-popup">
              <div class="photo-gallery-popup-sp">
                <span class="photoss ">Photos</span>
                <!-- <span class="pipes nopipe">1/3</span> -->
              </div>
              <div class="photo-gallery-popup-sp">
                <div class="photo-gallery-popup-social">
                  <div>
                    <a (click)="downloadImage(popupImages[imagetodownload].medium, popupImages[imagetodownload].label)">
                      <span class="positiondd"><i class="fa fa-download"></i></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="nav-tabs">
            <div id="membership1" class="w3-container w3-border Buton">
              <ngx-gallery [options]="galleryOptions" [images]="popupImages" (change)="onChange($event)">
              </ngx-gallery>
              <!-- <gallery gallerize id="mixedExample" [thumbTemplate]="thumbTemplate" [itemTemplate]="itemTemplate" (load)="itemTemplate"></gallery> -->
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #notFound let-c="close" let-d="dismiss">
    <div>
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title fw-bold" id="recreateModalLabel">
           Error
          </h3>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"
              (click)="d('Cross click')"><span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p class="text-center">
           Document url not found
          </p>
        </div>
      </div>
    </div>
  </ng-template>
</div>
