import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import { ApiService } from '../../api.service';
import { langauge } from '../../../environments/language/en';
import { Base64 } from 'js-base64';
import * as moment from 'moment';

@Component({
selector: 'app-login',
templateUrl: './login.component.html',
styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loading = false;
  public updateloading = false;
  errMsg: string = "";
  erremail: string = "";
  errpassword: string = "";
  email: string = "";
  password: string = "";
  changepassword: any = false;
  newpassword: string = "";
  successmsg: string = "";
  confirmpassword: string = "";
  errnewpwd: string = "";
  errcnfpwd: string = "";
  modalRef: any;
  hide: any;
  cnfhide: any;
  isIEBrowser:boolean = false;
  moment: any = moment;
  currentYear: string = '';
  @ViewChild("contents", {static: false} ) forgotPopup: ElementRef;
  
  constructor(private modalService: NgbModal,private router:Router,private api: ApiService) { }
    ngOnInit() { 
        if(this.api.loggedIn){
            this.router.navigate(['/homepage']);
        }
      const isIEConst = this.IEdetection();
      this.isIEBrowser = isIEConst;
      this.currentYear = moment(new Date()).format('YYYY');
    }
  
    login(){
      this.loading = true;
      this.erremail = '';
      this.errpassword = '';
      if (this.email == '' || this.email == undefined) {
          this.loading = false;
          this.erremail = langauge.EMAIL_REQ
      } else {
          this.loading = false;
          this.erremail = ''
      }
      if (this.email != '' && this.email != undefined) {
          this.loading = false;
          let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
          let test = emailPattern.test(this.email);
          if (!test) {
              this.erremail = langauge.EMAIL_VALID_REQ
          }
      }
      if (this.password == '' || this.password == undefined) {
          this.loading = false;
          this.errpassword = langauge.PASSWORD_REQ
      }
      let formdata = {
          'email': this.email,
          'password': Base64.encode(this.password),
          'is_assume_user': this.api.isAssumeUser()
      }
      this.errMsg = '';
      if (this.email!= '' && this.password != '' && this.erremail == '' && this.errpassword == '') {
          this.loading = true;
          let response = this.api.login(formdata);   //api call to get user data
          response.subscribe(res => {
              this.loading = false;
              if (res['message'] == 'SUCCESS' && res['status'] === true) {
                  localStorage.setItem('userdata', JSON.stringify(res));
                  if(res.accessToken){
                   const tokenExpirationTime = this.api.getTokenExpirationTime(res.accessToken);
                   const timeForTokenToExpire = moment(tokenExpirationTime * 1000).valueOf();
                   if(timeForTokenToExpire > moment().valueOf()) this.api.autoLogout(timeForTokenToExpire - moment().valueOf());
                  }
                  this.router.navigate(['/homepage']);
              } else if(res['message'] === 'ERROR' && res['data'] === "Email cannot be same as password") {
                this.loading = false;
                this.changepassword = true;
                this.errnewpwd = "";
                this.errcnfpwd = "";
                this.newpassword = "";
                this.confirmpassword="";
                this.openForgotPassword(this.forgotPopup,'updatepassword');
            } else if(res['message'] === 'ERROR' && res['status'] === false) {
                this.loading = false;
                this.errMsg = res['data'];
            }
          },
          err => {
              if (err.status == 401) {
                  this.loading = false;
              }
          });
      }
    }

    openForgotPassword(contents,type) {
        if(type==='forgetpwd') {
            this.changepassword = false;
        }
        this.modalRef = this.modalService.open(contents, { centered: true,windowClass: 'dark-modal' });
    }
    closeForgotPassword() {
        //this.email="";
        this.password="";
        this.newpassword = "";
        this.confirmpassword="";
        this.changepassword=false;
        this.errnewpwd = "";
        this.errcnfpwd = "";
        this.modalRef.close();
    }

    updatePassword() {                        //fn to update user password
        this.errnewpwd = '';
        this.errcnfpwd = '';
        if (this.newpassword == '' || this.newpassword == undefined) {
            this.errnewpwd = langauge.NEW_PWD_REQ
        } else {
            this.errnewpwd = ''
        }
        if (this.newpassword != '' && this.newpassword != undefined) {
            let pwdPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,30}$/;
            let test = pwdPattern.test(this.newpassword);
            if (!test) {
                this.errnewpwd = langauge.INV_NEW_PWD
            } else {
                this.errnewpwd = '';
            }
        }
        if (this.confirmpassword == '' || this.confirmpassword == undefined) {
            this.errcnfpwd = langauge.CNF_PWD_REQ
        } else {
            this.errcnfpwd = ''
        }
        if(this.newpassword != '' && this.newpassword != undefined && this.confirmpassword != '' && this.confirmpassword != undefined) {
            if(this.newpassword === this.confirmpassword && this.errnewpwd == '' && this.errcnfpwd == '') {
                let data = {
                    'email': this.email,
                    'password': Base64.encode(this.confirmpassword)
                }
                this.updateloading = true;
               let response = this.api.updatepassword(data);   //api call to update user password
                response.subscribe(res => {
                    if(res['message'] === 'SUCCESS' && res['status'] === true) {
                        if(res['data'] === 'Password Updated successfully') {
                            this.successmsg = 'Password Updated successfully'
                            this.updateloading = false;
                            setTimeout(() => {
                                this.successmsg = '';
                                this.closeForgotPassword();
                            }, 2000);
                        }
                    } else {
                        this.errcnfpwd = res['data'];
                        this.updateloading = false;
                    }
                });
            } else if(this.errnewpwd == '') {
                this.errcnfpwd = langauge.PWD_UNMATCH
            }
        }
    }

        //Function to detect IE or not 
     IEdetection() { 
        var ua = window.navigator.userAgent; 
        var msie = ua.indexOf('MSIE '); 
        if (msie > 0) { 
            // IE 10 or older, return version number 
            return  true; 
        } 
        var trident = ua.indexOf('Trident/'); 
        if (trident > 0) { 
            // IE 11, return version number 
            //var rv = ua.indexOf('rv:'); 
            // return ('IE ' + parseInt(ua.substring( 
            //   rv + 3, ua.indexOf('.', rv)), 10)); 
            return  true;
        } 
        // var edge = ua.indexOf('Edge/'); 
        // if (edge > 0) { 
        //     //Edge (IE 12+), return version number 
        //     return ('IE ' + parseInt(ua.substring( 
        //       edge + 5, ua.indexOf('.', edge)), 10)); 
        // } 
        // User uses other browser 
        return false; 
    } 
}


