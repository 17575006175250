<div class="main-panel" style="width:100%;">
  <div class="content">

    <div class="maincontent">
      <div class="bid-main">
      <section class="hidden">

      <div class="expsec" *ngIf="!isShowDivIf" [ngClass]="Bidstatus ? 'secdisable' : ''">    
        <section class="items grp1" *ngIf="bidsHeaders && bidsHeaders.length > 0">
            <div class="row">
              <div class="col-md-9">
                <div class="itemrow1">
                  <div class="id">
                    <span class="bid"> ID:</span>
                    <span class="bid-heading1">{{bidsHeaders && bidsHeaders[0] ? bidsHeaders[0].p_bid_id  : ""}}</span>
                  </div>
                  <div class="cname">
                    <div class="font9">
                      <span class="bid">Company name:</span>
                      <span
                        class="bid-heading1">{{bidsHeaders && bidsHeaders[0] ? bidsHeaders[0].p_company_name  : ""}}</span>
                    </div>
                  </div>
                  <div class="from">
                    <span class="bid">From:</span>
                    <span
                      class="bid-heading1">{{bidsHeaders && bidsHeaders[0] ? bidsHeaders[0].p_employee_name  : ""}}.</span>
                  </div>
                  <div class="due">
                    <span class="bid"> Due Date:</span>
                    <span
                      class="bid-heading1">{{bidsHeaders && bidsHeaders[0].p_due_date ? moment(bidsHeaders[0].p_due_date).utcOffset('-0400').format('ll')  : "-"}}</span>
                  </div>
                </div>
                <div class="itemrow2">
                  <div class="des">
                    <span class="bid">Description:</span>
                    <span
                      class="bid-heading1">{{bidsHeaders && bidsHeaders[0] ? bidsHeaders[0].p_description  : ""}}</span>
                  </div>
                  <div class="notes">
                    <span class="bid"> Notes:</span>
                    <span
                      class="bid-heading1">{{bidsHeaders && bidsHeaders[0] ? bidsHeaders[0].p_bid_vendor_desc  : ""}}</span>
                  </div>
                  <div class="item due">
                    <span class="bid"> Status:</span>
                    <span class="recieve"
                      [ngClass]="bidsHeaders[0].p_bid_vendor_status_desc === 'Received' ? 'butn-style open_btn' : bidsHeaders[0].p_bid_vendor_status_desc === 'Approved' || bidsHeaders[0].p_bid_vendor_status_desc === 'Submitted'? 'butn-style closing': 'butn-style closed'">{{bidsHeaders && bidsHeaders[0] ? bidsHeaders[0].p_bid_vendor_status_desc  : ""}}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="bid-but">
                  <div class="hidebutn bidbut">
                    <button [disabled] = "Bidstatus || defaultDisablebutton" style="float: right;" class="row-style5" (click)="saveBidData()">Save</button>
                    <button [disabled] = "defaultDisablebutton" style="float: right;" class="row-style5">Cancel</button>
                    <button [disabled] = "Bidstatus" style="float: right;" class="row-style5 back-blue" data-toggle="modal"
                      data-target="#submitModal" (click)="checkSavedData()" (click)="clearContents()"> Submit Bid</button>
                  </div>
                  <div class="mt-3 bidbut">
                    <button [disabled] = "Bidstatus || defaultDisablebutton || checkRevisedFinalBid || RowEmptyFields == 1" class="row-style5"(click)="saveBidData()">Save</button>
                    <button [disabled] = "Bidstatus || defaultDisablebutton" class="row-style5" data-toggle="modal" data-target="#cancelModalHome" >Cancel</button>  
                    <button [disabled] = "Bidstatus || holdEditRowInArray.length > 0 || holdAddRowInArray.length > 0 || secondSectionArrayFlag" class="row-style5" [ngClass]="holdEditRowInArray.length > 0 || holdAddRowInArray.length > 0 || secondSectionArrayFlag ? 'disabled' : 'back-blue'" data-toggle="modal" data-target="#submitModal"
                      (click)="checkSavedData()">
                      Submit Bid</button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div *ngIf="this.bidsHeadersLoading" class="bids-headers-loading-styles loadingbar">
            <ngx-loader [loading]="this.bidsHeadersLoading"></ngx-loader>
          </div>
          <section  *ngIf="bidsHeaders && bidsHeaders.length > 0" class="items1" [ngClass]="bidTypeflag ? 'jobtype_com' : ''">
            <div class="row">
              <div class="col-xl-3 col-lg-2 col-md-2 bid-heading paddingleft-19">
                Bid Mode<span class="colRed">*</span>
              </div>
              <div class="col-xl-3 col-lg-2 col-md-2 bid-heading  bidiscount jobtypekeyhome_com">
                Bid Discount
              </div>
              <div class="col-xl-2 col-lg-3 col-md-3 bid-heading bidprice">
                Total Bid Price
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 bid-heading tdiscount">
                Total Discount
              </div>
              <div class="col-xl-2 col-lg-3 col-md-3 bid-heading revbid">
                Revised Final Bid
              </div>
            </div>
            <div class="new"></div>
            <div class="row itemcenter itemleft">
              <!--<div class="col-xl-3 col-lg-2 col-md-2 padleft28" *ngIf="bidsHeaders && bidsHeaders[0] && bidsHeaders[0].p_all_inclusive_ind === 'Y'">-->
              <div class="col-xl-3 col-lg-2 col-md-2 padleft28">


               <div class="jobtypekeyhome_com">
                <div>
                  <input *ngIf="isMobile == 0" [disabled] = "Bidstatus || bidTypeflag" [value]="true" [(ngModel)]="pAllInclusiveIndCheced" checked (click)="changeVal($event)" name="bidssecondsectiondesktop"
                    type="radio" class=" mr-2">

                  <label class="bid nowrp">Lump Sum Job Bid</label>
                 </div>
                  <div>
                    <input  [(ngModel)]="pAllInclusiveCost" [ngClass]="statusClass" id="myText" class="bid-modestyle "
                      type="text" value="" maxlength="10" size="7" [disabled]='disableTextbox || Bidstatus || bidTypeflag'
                      (keyup)="changePrice($event,'bidmode')" (keypress)="bidbyJobValidation($event,'bidmode')"
                      (focusout)="currencyChange($event,'bidmode')" (click)="currencyBack($event,'bidmode')"
                      autocomplete="off">
                  </div>
                </div>
                    <div class="bidtype "><div>
                  <input *ngIf="bidTypeflag || isMobile == 0" disabled [value]="false" [(ngModel)]="pAllInclusiveIndCheced" (click)='Disable($event)' type="radio"
                    name="bidbyitem" class=" mr-2">
                  <label class="bid">Bid by Item </label>
                </div></div>
              </div>
              <div
                [ngClass]="bidsHeaders && bidsHeaders[0] && bidsHeaders[0].p_all_inclusive_ind === 'N' ? 'col-xl-3 col-lg-2 col-md-2 padleft20 bidiscount jobtypekeyhome_com' : 'col-xl-3 col-lg-2 col-md-2 padleft7 bidiscount jobtypekeyhome_com'">

                <input *ngIf="isMobile == 0"  [disabled] = "Bidstatus || bidTypeflag" [value]="false" [(ngModel)]="pBidDiscountChecked"  (click)="checkBidDiscountType($event)"
                  (click)="showInputs()" type="radio" value="bidpercentagediscount" name="amount" class="mr-2">
                <!-- <img  class="radio-style mr-2" src="../../../assets/img/Screen Shot 2020-07-27 at 3.56.09 PM.png"> -->
                <label class="bid" style="min-width: 142px;">Percentage (%)</label>

                <input  [disabled] = "Bidstatus || bidTypeflag || percentdisabel" *ngIf="showInput && isMobile == 0" [(ngModel)]="pBidDiscountPct" class="bid-modestyle" type="text"
                  value="$7,000.00" maxlength="7" size="7" (keyup)="changePrice($event,'biddiscount')"
                  (keypress)="bidbyJobValidation($event,'biddiscount')"
                  (focusout)="currencyChange($event,'biddiscount')"
                  autocomplete="off">

              </div>
              <div class="col-xl-2 col-lg-3 col-md-3 dollar bidprice">
                {{bidByJobPrice | currency:'USD':true:'1.2-3'}}
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 dollar tdiscount">
                {{bidDiscountWithsign}}
              </div>
              <div class="col-xl-2 col-lg-3 col-md-3 dollar1">
                {{revisedFinalBid | currency:'USD':true:'1.2-3'}}
              </div>
            </div>
            <div class="row ml-1 marbot-12 itemcenter">
              <!-- <div class="col-xl-3 col-lg-2 col-md-2" *ngIf="bidsHeaders && bidsHeaders[0] && bidsHeaders[0].p_all_inclusive_ind === 'N'">-->
              <div class="col-xl-3 col-lg-2 col-md-2 " >
                <div [ngClass]="bidTypeflag ? 'jobtypekeyhome_com' : ''">
                  <input *ngIf="!bidTypeflag || isMobile == 0" [disabled] = "Bidstatus" [value]="false" [(ngModel)]="pAllInclusiveIndCheced" (click)='Disable($event)' type="radio"
                    name="bidssecondsectiondesktop" class=" mr-2">
                  <label class="bid">Bid by Item </label>
                </div>
              </div>
              <div class="col-xl-3 col-lg-2 col-md-2 padleft amt jobtypekeyhome_com">
                <div>
                  <input *ngIf="isMobile == 0" [disabled] = "Bidstatus || bidTypeflag" [value]="true" [(ngModel)]="pBidDiscountChecked" (click)="hideInputs()"
                    (click)="checkBidDiscountType($event)" type="radio" value="bidamountdiscount" name="amount"
                    class=" mr-2"> 
                    <label class="bid" style="min-width:142px">
                    Amount ($) </label>
                  <!-- <img class="radio-style mr-2" src="../../../assets/img/Screen Shot 2020-07-27 at 3.56.09 PM.png"> -->
                </div>
                <div>
                  <!-- <form [formGroup]="form"> -->
                  <input [disabled] = "Bidstatus || amountdisabel" *ngIf="hideInput && isMobile == 0" [(ngModel)]="pBidDiscountAmt" class="bid-modestyle" type="text" maxlength="7"
                    size="7" (focusout)="currencyChange($event,'bidamount')" (keyup)="changePrice($event,'bidamount')"
                    (keypress)="bidbyJobValidation($event,'bidamount')" (click)="currencyBack($event,'bidamount')"
                    autocomplete="off">
                  <!-- <input *ngIf="hideInput" [(ngModel)]="blankPrice0" type="text" formControlName="amount" class="bid-modestyle" currencyInput maxDigits="7"
                        (keyup)="changePrice($event,'biddiscount')" (keypress)="bidbyJobValidation($event,'biddiscount')" 
                        >-->
                  <!-- </form> -->
                </div>
              </div>
              <div class="col-xl-4 col-lg-6 col-md-6 padleft5">


               <!-- minus Sign error  start-->
                <div *ngIf="checkRevisedFinalBid"
                  class="alert alert-warning alert-dismissible fade show padleft0 negativeerror" role="alert">
                  <div>Revised Final Bid Cannot be Negative.</div>
                </div>
              <!-- minus Sign error  end-->
                <!-- <span class="bid-heading"> -->
                  <!--Job Description: </span><span class="bid">22002: Maysoun & Ayman Al-Mawla--><!-- </span> -->
              </div>
              <div class="col-xl-1 col-lg-1 col-md-1 dollar1">
              </div>
              <div class="col-xl-1 col-lg-1 col-md-1 dollar">
              </div>
            </div>
          </section>
        </div>
          <div class="butsection" [ngClass]="isShowDivIf ? 'exmenu' : ''">

            <button *ngIf="holdEditRowInArray.length > 0 || holdAddRowInArray.length > 0 || secondSectionArrayFlag" type="button" class="badktobid btn-style" data-toggle="modal" data-target="#submitModalHome"
              (click)="checkSavedData()">
              <i class="fa fa-arrow-left pr-2">
                  </i>Back to Bids
            </button>

            <button routerLink="/homepage" *ngIf="holdEditRowInArray.length == 0 && holdAddRowInArray.length == 0 && !secondSectionArrayFlag" type="button" class="badktobid btn-style" 
              (click)="checkSavedData()">
              <i class="fa fa-arrow-left pr-2">
                  </i>Back to Bids
            </button>
            <!-- error alert start-->
            <div *ngIf="displayErrorPopup && RowEmptyFields == 1"
              class="alert alert-warning alert-dismissible fade show" role="alert">
              <div>Error: Please fill the required fields</div>
              <button type="button" class="close err-close" (click)="closeError()" aria-label="Close">
                <span aria-hidden="true" class="close-button">&times;</span>
              </button>
            </div>
            <!-- error alert end-->
             <!-- Loder start-->
            <div  *ngIf="loderBeforeApiLoad" class="" role="alert">
              <div> <ngx-loader [loading]="loderBeforeApiLoad"></ngx-loader>
              </div>
            </div>
            <!-- success alert end-->
            <!-- success alert start-->

            <div *ngIf="displaySuccessPopup" class="alert alert-success alert-dismissible fade show" role="alert">
              <div>Success: Data saved successfully</div>
              <button type="button" class="close err-close" (click)="closeSuccess()" aria-label="Close">
                <span aria-hidden="true" class="close-button">&times;</span>
              </button>
            </div>
            <!-- success alert end-->

            <span class="buttons">
              <button  [disabled]="Bidstatus || pAllInclusiveIndCheced" *ngIf="bidType == 'JOB'" class="row-style" (click)="addBidData()">Add Row</button>
              <!-- <button class="row-style" (click)="editBidData()">Edit</button> -->
            <button *ngIf="isShowDivIf" [disabled] = "Bidstatus || defaultDisablebutton || checkRevisedFinalBid || RowEmptyFields == 1" class="row-style5"(click)="saveBidData()">Save</button>

            <button *ngIf="isShowDivIf" [disabled] = "Bidstatus || defaultDisablebutton" class="row-style5" data-toggle="modal" data-target="#cancelModalHome" >Cancel</button>

            <button class="row-style back-blue" data-toggle="modal" data-target="#exampleModal"(click)="checkSavedData()">Bid Logs</button> 

            <button *ngIf="isShowDivIf" [disabled] = "Bidstatus || holdEditRowInArray.length > 0 || holdAddRowInArray.length > 0 || secondSectionArrayFlag" class="row-style5" [ngClass]="holdEditRowInArray.length > 0 || holdAddRowInArray.length > 0 || secondSectionArrayFlag ? 'disabled' : 'back-blue'" data-toggle="modal" data-target="#submitModal" (click)="checkSavedData()">Submit Bid</button>
              <!-- <i class="material-icons search-icon">&#xe8b6;</i> -->
              <img class="search-icon" src="../../../assets/img/Vector (4).svg">
              <input type="text" class="search-style" [(ngModel)]="searchvalue" id="search" (keyup)="searchtransform(searchvalue)"
                placeholder="search">
              <!-- <button class="row-style1" (click)="searchtransform(searchvalue)">Go</button> -->
             
              
              <!-- <a class="report-download" href="../../../assets/arhpdf/StGeorge.pdf"> -->
              <!--Download Report-->   <img class="pdf-icon" (click)="bidsReports()" src="../../../assets/img/PDF-Icon.png" style="display: inline-block;" title="Download Report"> 
              <!-- </a> -->
              <a *ngIf="!isShowDivIf" class="expdmenu"  (click)=toggleDisplayDivIf() >  <img src="../../../assets/img/exp-icon1.svg"></a>
              <a *ngIf="isShowDivIf" class="expdmenu"  (click)=toggleDisplayDivIf() >  <img src="../../../assets/img/exp-icon2.svg" ></a>
              
            </span>
            
          </div>
          <div *ngIf="this.dashboardreportsLoading" class="bids-pdf-loading-styles">
            <ngx-loader [loading]="this.dashboardreportsLoading"></ngx-loader>
          </div>
          <div *ngIf="this.errsearch" class="search-err">{{errsearch}}</div>
          <!-- Editable table -->
          <div class="bid-table">
            <div id="tablescroll">
              <div id="table" class="table-editable">
                <table class="table table-bordered table-responsive-md">
                  <thead>
                    <tr>
                      <!-- <th class="text-left"></th> -->
                      <th class="text-left"><span class="firstColumn-head">Item Description
                          <!-- <i class="fas fa-sort-up" (click)="dataSort('concrete')"></i> -->
                          <div class="sortitem"><i (click)="dataSorting('ITEM_DESC')"
                            [ngClass]="(selectedkey === 'ITEM_DESC' && counter % 2==0 ? 'fas fa-sort-up ' : 'fas fa-sort-down')+' '+(isActive('ITEM_DESC') ? 'sortactive':'')"
                            class="marleft-2 pointer"></i>
                          </div>
                        </span>
                      </th>
                      <th class="text-left width-spacing itemization">CO#
                        <!-- <i class="fas fa-sort-up" (click)="dataSort('itemization')"></i> -->
                        <div class="sortitem"><i (click)="dataSorting('ITEMIZATION_NO')"
                          [ngClass]="(selectedkey === 'ITEMIZATION_NO'  && counter % 2==0 ? 'fas fa-sort-up ' : 'fas fa-sort-down ')+' '+(isActive('ITEMIZATION_NO') ? 'sortactive':'')"
                          class="marleft-2 pointer"></i></div>
                      </th>
                      <th class="text-left width-spacing"> Location
                        <!-- <i class="fas fa-sort-up" (click)="dataSort('location')"></i> -->
                      <div class="sortitem"><i (click)="dataSorting('LOCATION_NAME')"
                          [ngClass]="(selectedkey === 'LOCATION_NAME' && counter % 2==0 ? 'fas fa-sort-up ' : 'fas fa-sort-down ')+' '+(isActive('LOCATION_NAME') ? 'sortactive':'')"
                          class="marleft-2 pointer"></i></div>
                      </th>
                      <th class="text-left width-spacing">Quantity
                        <!-- <i class="fas fa-sort-up" (click)="dataSort('Quantity')"></i> -->
                        <div class="sortitem"><i (click)="dataSorting('ITEM_QTY')"
                          [ngClass]="(selectedkey === 'ITEM_QTY' && counter % 2==0 ? 'fas fa-sort-up ' : 'fas fa-sort-down ')+' '+(isActive('ITEM_QTY') ? 'sortactive':'')"
                          class="marleft-2 pointer"></i></div>
                      </th>
                      <th *ngIf="bidType == 'JOB'" class="text-left width-spacing">Bid Quantity
                        <!-- <i class="fas fa-sort-up" (click)="dataSort('Quantity')"></i> -->
                        <div class="sortitem"><i (click)="dataSorting('NEW_ITEM_QTY')"
                          [ngClass]="(selectedkey === 'NEW_ITEM_QTY' && counter % 2==0 ? 'fas fa-sort-up ' : 'fas fa-sort-down ')+' '+(isActive('NEW_ITEM_QTY') ? 'sortactive':'')"
                          class="marleft-2 pointer"></i></div>
                      </th>
                      <th class="text-left width-spacing">UOM
                        <!-- <i class="fas fa-sort-up" (click)="dataSort('UoM')"></i> -->
                      <div class="sortitem"><i (click)="dataSorting('MEASURE_UNIT')"
                          [ngClass]="(selectedkey === 'MEASURE_UNIT' && counter % 2==0  ? 'fas fa-sort-up ' : 'fas fa-sort-down ')+' '+(isActive('MEASURE_UNIT') ? 'sortactive':'')"
                          class="marleft-2 pointer"></i></div>
                      </th>
                      <th class="text-left width-spacing">Current Cost
                        <!-- <i class="fas fa-sort-up" (click)="dataSort('unitCost')"></i> -->
                        <div class="sortitem"><i (click)="dataSorting('ORIGINAL_UNIT_COST')"
                          [ngClass]="(selectedkey === 'ORIGINAL_UNIT_COST' && counter % 2==0 ? 'fas fa-sort-up ' : 'fas fa-sort-down ')+' '+(isActive('ORIGINAL_UNIT_COST') ? 'sortactive':'')"
                          class="marleft-2 pointer"></i></div>
                      </th>
                      <th class="text-left width-spacing">Bid Unit Cost
                        <!-- <i class="fas fa-sort-up" (click)="dataSort('bidCost')"></i> -->
                        <div class="sortitem"><i (click)="dataSorting('NEW_UNIT_COST')"
                          [ngClass]="(selectedkey === 'NEW_UNIT_COST' && counter % 2==0 ? 'fas fa-sort-up ' : 'fas fa-sort-down ')+' '+(isActive('NEW_UNIT_COST') ? 'sortactive':'')"
                          class="marleft-2 pointer"></i></div>
                      </th>
                      <th class="text-left" class="txtfield">Taxable
                        <!-- <i class="fas fa-sort-up" (click)="dataSort('tax')"></i> -->
                        <div class="sortitem"><i (click)="dataSorting('TAXABLE_IND')"
                          [ngClass]="(selectedkey === 'TAXABLE_IND' && counter % 2==0 ? 'fas fa-sort-up ' : 'fas fa-sort-down ')+' '+(isActive('TAXABLE_IND') ? 'sortactive':'')"
                          class="marleft-2 pointer"></i></div>
                      </th>
                      <th>Item Notes
                        <!-- <i class="fas fa-sort-up "></i> -->
                        <div class="sortitem"><i (click)="dataSorting('ITEM_NOTES')"
                          [ngClass]="(selectedkey === 'ITEM_NOTES' && counter % 2==0 ? 'fas fa-sort-up ' : 'fas fa-sort-down ')+' '+(isActive('ITEM_NOTES') ? 'sortactive':'')"
                          class="marleft-2 pointer"></i></div>
                      </th>
                    </tr>
                  </thead>
                  <!-- <ng-container *ngIf="( filterItems | bidsearch: searchvalue) as filterItems"> -->
                  <tbody [ngClass]="Bidstatus || pAllInclusiveIndCheced ? 'secdisable' : ''">

                    <tr [class.highlighted]="i == currentRowflag && RowEmptyFields == 1" class="table-bordered bid-hover"
                      *ngFor="let item Of filterItems,let i = index;">

                      <!-- concrete -->
                      <td class="body_data" *ngIf="(item.VENDOR_ADDITION_IND == 'N') 
                        || (RowEmptyFields == 0 &&  item.VENDOR_ADDITION_IND == 'Y' && Bidstatus) 
                        || (RowEmptyFields == 0 &&  item.VENDOR_ADDITION_IND == 'Y' && !Bidstatus && pAllInclusiveIndCheced)
                        || (RowEmptyFields == 1 &&  item.VENDOR_ADDITION_IND == 'Y' && !Bidstatus && i != currentRowflag) || 
                           (RowEmptyFields == 1 &&  item.VENDOR_ADDITION_IND == 'Y' && Bidstatus)">
                        <div style="display: flex;flex-direction: row;">
                          <div *ngIf="item.VENDOR_ADDITION_IND == 'Y' && !Bidstatus && !pAllInclusiveIndCheced" style="padding: 12px 0px;" class="delicon">
                            <img src="..\assets\img\delete (1).png" data-toggle="modal"
                              class="del-icon p cursor-not-allowed">
                          </div>

                          <div *ngIf="item.VENDOR_ADDITION_IND == 'Y' && Bidstatus" style="padding: 12px 0px;" class="delicon">
                            <img src="..\assets\img\delete (1).png" 
                              class="del-icon s">
                          </div>
                          <div style="width: 80%;margin-left: 8%; margin-top: 8px;">
                            <p
                              class="item-description label-margin" contenteditable="flase">
                              {{item.ITEM_DESC == null || item.ITEM_DESC == "" ? "Vendor Item":item.ITEM_DESC}}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td class="body_data itemization" *ngIf="(RowEmptyFields == 0 &&  item.VENDOR_ADDITION_IND == 'Y' && !Bidstatus && !pAllInclusiveIndCheced) || (RowEmptyFields == 1 &&  item.VENDOR_ADDITION_IND == 'Y' && !Bidstatus &&  i == currentRowflag)">
                        <div style="display: flex;flex-direction: row;">
                          <div *ngIf="item.VENDOR_ADDITION_IND == 'Y'" style="padding: 12px 0px;" class="delicon">
                            <img src="..\assets\img\delete (1).png" data-toggle="modal" data-target="#deleteModal"
                              class="del-icon" (click)="setDeleteId(item,i)">
                          </div>
                          <div style="width: 80%;margin-left: 8%; margin-top: 8px;">
                            <p *ngIf="item.VENDOR_ADDITION_IND == 'Y'" 
                              (focusout)="updateData($event,item,'ITEM_DESC',i)"
                              (keyup)="removeDefaultText($event,item,'ITEM_DESC',i)"
                              (click)="removeDefaultText($event,item,'ITEM_DESC',i)" 
                              (blur)="changedTableData($event,item,'NEW_UNIT_COST',i)"
                              class="item-description" contenteditable="true">
                              {{item.ITEM_DESC == null || item.ITEM_DESC == "" ? "Vendor Item":item.ITEM_DESC}}
                            </p>
                          </div>
                        </div>
                      </td>
                      <!-- concrete -->
                      <!-- itemization -->
                      <td class="itemization">
                        <span contenteditable="false" 
                          class="span-border inputs-select-spacing span-note pl-0">{{item.ITEMIZATION_NO == null || item.ITEMIZATION_NO == "" ? "-" : item.ITEMIZATION_NO}}</span>
                      </td>
                      <!-- itemization -->
                      <!-- locations -->
                      <td>
                        <span contenteditable="false" 
                          class="span-border inputs-select-spacing span-note pl-0">{{item.LOCATION_NAME == null || item.LOCATION_NAME == "" ? "-" : item.LOCATION_NAME}}</span>
                      </td>
                      <!-- locations -->
                      <!-- Quantity -->
                      <td>
                        <span contenteditable="false" 
                          class="span-border inputs-select-spacing span-note pl-0">{{item.ITEM_QTY == null || item.ITEM_QTY == "" ? "-" : item.ITEM_QTY | number:'1.2-2'}}</span>
                      </td>
                      <!-- Quantity -->
                      <!-- BidQuantity -->
                      <td *ngIf="(bidType == 'JOB')">
                        <input disabled *ngIf="(RowEmptyFields == 1 && item.VENDOR_ADDITION_IND == 'Y' && i != currentRowflag) || 
                         (RowEmptyFields == 1 &&  item.VENDOR_ADDITION_IND == 'N' && i != currentRowflag) || 
                         (RowEmptyFields == 0 && item.VENDOR_ADDITION_IND == 'N' && Bidstatus) || 
                         (RowEmptyFields == 0 && item.VENDOR_ADDITION_IND == 'Y' && Bidstatus)
                         || (RowEmptyFields == 0 && item.VENDOR_ADDITION_IND == 'N' && !Bidstatus && pAllInclusiveIndCheced) || 
                         (RowEmptyFields == 0 && item.VENDOR_ADDITION_IND == 'Y' && !Bidstatus && pAllInclusiveIndCheced)"
                         contenteditable="false" type="text"
                          class="firstColumn input-border inputs-select-spacing cost-data-width"
                          value="{{item.NEW_ITEM_QTY == null || item.NEW_ITEM_QTY == '' ? '-' : item.NEW_ITEM_QTY | number:'1.2-2' }}"
                          minlength="4" maxlength="16" autocomplete="off">

                        <input
                          *ngIf="(RowEmptyFields == 0 &&  item.VENDOR_ADDITION_IND == 'Y' && !Bidstatus && !pAllInclusiveIndCheced) || (RowEmptyFields == 0 &&  item.VENDOR_ADDITION_IND == 'N' && !Bidstatus && !pAllInclusiveIndCheced) || (RowEmptyFields == 1 && item.VENDOR_ADDITION_IND == 'Y' && i == currentRowflag) || (RowEmptyFields == 1 && item.VENDOR_ADDITION_IND == 'N' && i == currentRowflag)"
                          (click)="currencyBack($event,'NEW_ITEM_QTY')"
                          (keyup)="removeDefaultText($event,item,'NEW_ITEM_QTY',i)"(keypress)="checkValue($event,item,'NEW_ITEM_QTY')" contenteditable="false" type="text"
                          (focusout)="updateData($event,item,'NEW_ITEM_QTY',i)
                          " 
                          (blur)="changedTableData($event,item,'NEW_UNIT_COST',i)"
                          class="firstColumn input-border inputs-select-spacing cost-data-width"
                          value="{{item.NEW_ITEM_QTY == null || item.NEW_ITEM_QTY == '' ? '-' : item.NEW_ITEM_QTY | number:'1.2-2' }}"
                          minlength="4" maxlength="16" autocomplete="off">
                      </td>
                      <!-- BidQuantity -->
                      <!-- UoM -->
                      <td *ngIf="(item.VENDOR_ADDITION_IND == 'N') 
                        || (RowEmptyFields == 0 &&  item.VENDOR_ADDITION_IND == 'Y' && Bidstatus) 
                        || (RowEmptyFields == 0 &&  item.VENDOR_ADDITION_IND == 'Y' && !Bidstatus && pAllInclusiveIndCheced)
                        || (RowEmptyFields == 1 &&  item.VENDOR_ADDITION_IND == 'Y' && !Bidstatus && i != currentRowflag) || 
                           (RowEmptyFields == 1 &&  item.VENDOR_ADDITION_IND == 'Y' && Bidstatus)">

                        <span contenteditable="false"
                          class="span-border inputs-select-spacing span-note pl-0">{{item.MEASURE_UNIT == null || item.MEASURE_UNIT == "" ? "-" : item.MEASURE_UNIT}}</span>
                      </td>
                      <td *ngIf="(RowEmptyFields == 0 &&  item.VENDOR_ADDITION_IND == 'Y' && !Bidstatus && !pAllInclusiveIndCheced) || (RowEmptyFields == 1 &&  item.VENDOR_ADDITION_IND == 'Y' && !Bidstatus &&  i == currentRowflag)">
                        <select [ngClass]=" onTabClassUam ? 'onuam' : ''" contenteditable="true" (focusout)="updateData($event,item,'MEASURE_UNIT',i)"
                          
                          (blur)="changedTableData($event,item,'NEW_UNIT_COST',i)"
                          class="form-control span-border option-style inputs-select-spacing pl-0" name="person"
                          [(ngModel)]="item.MEASURE_UNIT">
                          <option value="">Select UOM</option>
                          <option value="{{uoms.MEASURE_UNIT}}" *ngFor="let uoms of umoSelect">
                            {{uoms.MEASURE_UNIT == null || uoms.MEASURE_UNIT == '' ? '-' : uoms.MEASURE_UNIT}}</option>
                        </select>
                      </td>
                      <!-- UoM -->
                      <!-- unitCost -->
                      <td>
  
                        <span contenteditable="false" 
                          class="span-border inputs-select-spacing span-note pl-0">{{item.ORIGINAL_UNIT_COST == null || item.ORIGINAL_UNIT_COST == "" ? "-" : item.ORIGINAL_UNIT_COST | currency:'USD':'symbol'}}</span>
                      </td>
                      <!-- unitCost -->
                      <!-- bidCost -->
                      <td *ngIf="(RowEmptyFields == 1 && item.VENDOR_ADDITION_IND == 'Y' && i != currentRowflag) || 
                         (RowEmptyFields == 1 &&  item.VENDOR_ADDITION_IND == 'N' && i != currentRowflag) || 
                         (RowEmptyFields == 0 && item.VENDOR_ADDITION_IND == 'N' && Bidstatus) || 
                         (RowEmptyFields == 0 && item.VENDOR_ADDITION_IND == 'Y' && Bidstatus)
                         || (RowEmptyFields == 0 && item.VENDOR_ADDITION_IND == 'N' && !Bidstatus && pAllInclusiveIndCheced) || 
                         (RowEmptyFields == 0 && item.VENDOR_ADDITION_IND == 'Y' && !Bidstatus && pAllInclusiveIndCheced)">
                        <input disabled (keypress)="checkValue($event,item,'NEW_UNIT_COST')" contenteditable="true"
                          type="text" 
                          class="firstColumn input-border inputs-select-spacing cost-data-width"
                          value="{{item.NEW_UNIT_COST == null || item.NEW_UNIT_COST == '' ? '-' : item.NEW_UNIT_COST | currency:'USD':'symbol' }}"
                          minlength="4" maxlength="16" autocomplete="off">
                      </td>
                      <td
                        *ngIf="(RowEmptyFields == 0 &&  item.VENDOR_ADDITION_IND == 'Y' && !Bidstatus && !pAllInclusiveIndCheced) || (RowEmptyFields == 0 &&  item.VENDOR_ADDITION_IND == 'N' && !Bidstatus && !pAllInclusiveIndCheced) || (RowEmptyFields == 1 && item.VENDOR_ADDITION_IND == 'Y' && i == currentRowflag) || (RowEmptyFields == 1 && item.VENDOR_ADDITION_IND == 'N' && i == currentRowflag)">
                        <input (keypress)="checkValue($event,item,'NEW_UNIT_COST')" contenteditable="true" type="text"
                        (click)="currencyBack($event,'NEW_UNIT_COST')"
                        (focusout)="currencyChange($event,'NEW_UNIT_COST')"
                        (focusout)="updateData($event,item,'NEW_UNIT_COST',i)" 
                        (keyup)="removeDefaultText($event,item,'NEW_UNIT_COST',i)"
                        (blur)="changedTableData($event,item,'NEW_UNIT_COST',i)"
                          class="firstColumn input-border inputs-select-spacing cost-data-width"
                          value="{{item.NEW_UNIT_COST == null || item.NEW_UNIT_COST == '' ? '-' : item.NEW_UNIT_COST | currency:'USD':'symbol' }}"
                          minlength="4" maxlength="16" autocomplete="off">
                      </td>
                      <!-- bidCost -->
                      <!-- Tax -->
                      <td *ngIf="(item.VENDOR_ADDITION_IND == 'N') 
                        || (RowEmptyFields == 0 &&  item.VENDOR_ADDITION_IND == 'Y' && Bidstatus) 
                        || (RowEmptyFields == 0 &&  item.VENDOR_ADDITION_IND == 'Y' && !Bidstatus && pAllInclusiveIndCheced)
                        || (RowEmptyFields == 1 &&  item.VENDOR_ADDITION_IND == 'Y' && !Bidstatus && i != currentRowflag) || 
                           (RowEmptyFields == 1 &&  item.VENDOR_ADDITION_IND == 'Y' && Bidstatus)">
                        <span 
                          contenteditable="false"
                          class="span-border inputs-select-spacing span-note">{{item.TAXABLE_IND == null || item.TAXABLE_IND == "" ? "-" : item.TAXABLE_IND}}</span>
                      </td>
                      <td *ngIf="(RowEmptyFields == 0 &&  item.VENDOR_ADDITION_IND == 'Y' && !Bidstatus && !pAllInclusiveIndCheced) || (RowEmptyFields == 1 &&  item.VENDOR_ADDITION_IND == 'Y' && !Bidstatus &&  i == currentRowflag)">
                        <select contenteditable="true" (focusout)="updateData($event,item,'TAXABLE_IND',i)"
                          (blur)="changedTableData($event,item,'NEW_UNIT_COST',i)"
                          class="form-control span-border option-style inputs-select-spacing txtfield" name="person"
                          [(ngModel)]="item.TAXABLE_IND">
                          <option value="{{taxs}}" *ngFor="let taxs of taxSelect">{{taxs}}</option>
                        </select>
                      </td>
                      <!-- Tax -->
                      <!-- Note -->
                      <td *ngIf="(RowEmptyFields == 1 && item.VENDOR_ADDITION_IND == 'Y' && i != currentRowflag) || 
                         (RowEmptyFields == 1 &&  item.VENDOR_ADDITION_IND == 'N' && i != currentRowflag) || 
                         (RowEmptyFields == 0 && item.VENDOR_ADDITION_IND == 'N' && Bidstatus) || 
                         (RowEmptyFields == 0 && item.VENDOR_ADDITION_IND == 'Y' && Bidstatus)
                         || (RowEmptyFields == 0 && item.VENDOR_ADDITION_IND == 'N' && !Bidstatus && pAllInclusiveIndCheced) || 
                         (RowEmptyFields == 0 && item.VENDOR_ADDITION_IND == 'Y' && !Bidstatus && pAllInclusiveIndCheced)">
                        <span  contenteditable="false"
                          class="span-border priyank inputs-select-spacing span-note pl-0">{{item.ITEM_NOTES == null || item.ITEM_NOTES == "" ? "-" : item.ITEM_NOTES}}</span>
                      </td>
                      <td
                        *ngIf="(RowEmptyFields == 0 &&  item.VENDOR_ADDITION_IND == 'Y' && !Bidstatus && !pAllInclusiveIndCheced) || (RowEmptyFields == 0 &&  item.VENDOR_ADDITION_IND == 'N' && !Bidstatus && !pAllInclusiveIndCheced) || (RowEmptyFields == 1 && item.VENDOR_ADDITION_IND == 'Y' && i == currentRowflag) || (RowEmptyFields == 1 && item.VENDOR_ADDITION_IND == 'N' && i == currentRowflag)">
                        <span  
                        (keypress)="checkValue($event,item,'ITEM_NOTES')" contenteditable="true"
                        (keyup)="updateData($event,item,'ITEM_NOTES',i)" 
                        (click)="removeDefaultText($event,item,'ITEM_NOTES',i)"
                        (blur)="changedTableData($event,item,'NEW_UNIT_COST',i)"
                          class="span-border inputs-select-spacing span-note pl-0">{{item.ITEM_NOTES == null || item.ITEM_NOTES == "" ? "-" : item.ITEM_NOTES}}</span>
                      </td>
                      <!-- Note -->
                    </tr>
                  </tbody>
                  <tbody *ngIf="filterItems.length === 0 && !this.bidsTablesDataLoading">
                    <tr class="table-bordered">
                      <td colspan="6" class="no-user">{{bidsItemErrMsg}}</td>
                    </tr>
                  </tbody>
                  <tbody *ngIf="this.bidsTablesDataLoading">
                    <tr class="table-bordered">
                      <td colspan="6">
                        <div *ngIf="this.bidsTablesDataLoading" class="bids-headers-loading-styles loadingbar">
                          <ngx-loader [loading]="this.bidsTablesDataLoading"></ngx-loader>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <!-- </ng-container> -->
                </table>
              </div>
            </div>
            <div style="height: 20px;"></div>
            <div id="textbox">
              <!-- <p class="alignleft">1 Row Selected | Total 46 | Last Edited On: July 20,2020 | This job bid is expiring on date:
          July 25, 2020</p> -->
              <p class="alignright"> Total Rows: {{filterItems.length}}<span class="total-amt">Total: {{itemtotal | currency:'USD':true:'1.2-3'}}</span> </p>
              <div style="clear: both;"></div>
            </div>
          </div>


          <!-- pdf popup start -->
          <ng-template #doccontents let-c="close" let-d="dismiss">
            <div class="">
              <div class="login-box">
                <div class="login-sec docmarg">
                  <div class="nav-tabs">
                    <div class="w3-bar w3-black">
                      <h4>
                        <span class="position5 close-i" (click)="d('Cross click')"> <i class="fa fa-times"></i></span>
                      </h4>
                    </div>
                    <div id="membership1" class="w3-container w3-border Buton">
                      <div class="file-box">
                        <object data="../../../assets/arhpdf/StGeorge.pdf" type="application/pdf" width="100%"
                          id="doc-pdf" height="100%">
                          <p>Your browser does not support PDFs.
                          </p>
                        </object>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <!-- pdf popup end -->

          
          <!-- <div class="modal fade" id="confirmSubmit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content modalSubmit height299 padtop25">
                <button type="button" class="close close-margin marLeft" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true" class="cross-style"> &#10005;</span>
                </button>

                <div *ngIf="bidsubmitSuccessPopup">
                  <img class="warning warnPad" src="../../../assets/img/Group 138.svg">
                  <i class="material-icons warning">&#xe86c;</i>
                  <p class="bid-headingModal">Thank you for submitting this bid request.</p>
                  <p class="modal-para">Dear Vendor, Thank your for submitting this bid. Our<br>
                    team will go through your bid and will get back to you.</p>

                  <div class="row marleft40">
                    <span class=" rowModal-style  back-blue" data-dismiss="modal">close</span>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <!-- <div *ngIf="!isDataChanged" class="modal fade" id="AddExpense" id="submitModal" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content modalSubmit height299">
                <button type="button" class="close close-modal close-margin  marLeft" data-dismiss="modal"
                  aria-label="Close">
                  <span aria-hidden="true" class="cross-style"> &#10005;</span>
                </button>
                <img class="warning padtop25" src="../../../assets/img/Group 139.svg">
                <i class="material-icons warning">&#xe002;</i>
                <p class="bid-headingModal">Do you want to submit this bid?</p>
                <p class="modal-para">Once you submit the bid, you won't be able to edit it.</p>
                <div class="row itemcenter">
                  <span class=" rowModal-style" data-dismiss="modal">I'd like to
                    review</span>
                  <span class="back-blue rowModal-style" data-toggle="modal" data-target="#confirmSubmit"
                    data-dismiss="modal" aria-label="Close"
                    (click) = "acceptBid()"
                    >Yes, Submit</span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal fade" id="confirmSubmit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content modalSubmit height299 padtop25">
                <button type="button" class="close close-margin marLeft" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true" class="cross-style"> &#10005;</span>
                </button>
                <div  *ngIf="bidsubmitLoder" class=" fade show" role="alert">
                  <div> <ngx-loader [loading]="bidsubmitLoder"></ngx-loader>
                  </div>
                </div>
                <div *ngIf="bidsubmitSuccessPopup">
                <img class="warning warnPad" src="../../../assets/img/Group 138.svg">
                <i class="material-icons warning">&#xe86c;</i>
                <p class="bid-headingModal">Thank you for submitting this bid request.</p>
                <p class="modal-para">Dear Vendor, Thank your for submitting this bid. Our<br>
                  team will go through your bid and will get back to you.</p>
                
                <div class="row marleft40">
                  <span class=" rowModal-style  back-blue" data-dismiss="modal">close</span>
                </div>
                </div>
              </div>
            </div>
          </div> -->
         
          
        </section>
 <!-- bid sbmit popup end -->
        <div *ngIf="!isDataChanged" class="modal fade popcenter" id="AddExpense" id="submitModalHome" tabindex="-1"
        role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content modalSubmit height299 padtop25">
            <button type="button" class="close close-modal close-margin  marLeft" data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true" class="cross-style"> &#10005;</span>
            </button>
            <img class="warning" src="../../../assets/img/Group 139.svg">
            <!-- <i class="material-icons warning">&#xe002;</i> -->
            <p class="bid-headingModal">Are you sure want to go back?</p>
            <p class="modal-para">Once you click on Yes, all your un-saved data will be lost and you will be redirected back to the home page.</p>
            <div class="row itemcenter">
              <span class="rowModal-style" data-dismiss="modal" routerLink="/homepage">Yes</span>
              <span class="rowModal-style back-blue" data-dismiss="modal" aria-label="Close">No</span>
            </div>
          </div>
        </div>
      </div>
      <!--back to bidpopup end -->

      <!-- delete popup start -->
      <div class="modal fade popcenter" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content modalSubmit height299 padtop25">
            <button type="button" class="close close-modal close-margin  marLeft" data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true" class="cross-style"> &#10005;</span>
            </button>
            <img class="warning" src="../../../assets/img/Group 139.svg">
            <!-- <i class="material-icons warning">&#xe002;</i> -->
            <p class="bid-headingModal">Are you sure want to delete?</p>
            <!-- <p class="modal-para">Once you say no, You will redirect to home page.</p> -->
            <div class="row itemcenter">
              <span class="rowModal-style" (click)="deleteBidData()" data-dismiss="modal">Yes</span>
              <span class="rowModal-style back-blue" data-dismiss="modal" aria-label="Close">No</span>
            </div>
          </div>
        </div>
      </div>
      <!-- delete popup end -->

           <!-- Cancel popup start -->
          <div class="modal fade cancelmodal popcenter"  id="cancelModalHome" tabindex="-1"
            role="dialog" aria-labelledby="cancelModalHome" aria-hidden="true"  data-backdrop="static">
            <div class="modal-dialog" role="document">
              <div class="modal-content modalSubmit height299 padtop25">
                <button type="button" class="close close-modal close-margin  marLeft" data-dismiss="modal"
                  aria-label="Close">
                  <span aria-hidden="true" class="cross-style"> &#10005;</span>
                </button>
                <img class="warning" src="../../../assets/img/Group 139.svg">
                <!-- <i class="material-icons warning">&#xe002;</i> -->
                <p class="bid-headingModal">Are you sure want to cancel?</p>
                <p class="modal-para">Once you click on Yes, all your un-saved data will be lost.</p>
                <div class="row itemcenter">
                  <span (click)="cancelRequest()" class="rowModal-style" data-dismiss="modal"
                  >Yes</span>
                  <span class="rowModal-style back-blue" data-dismiss="modal" aria-label="Close">No</span>
                </div>
              </div>
            </div>
          </div>
           <!-- Cancel popup start -->


          <!-- Cancel popup start -->
          <!-- <div class="modal fade cancelmodal popcenter"  id="cancelPopupEditRow" tabindex="-1"
            role="dialog" aria-labelledby="cancelPopupEditRow" aria-hidden="true"  data-backdrop="static">
            <div class="modal-dialog" role="document">
              <div class="modal-content modalSubmit height299 padtop25">
                <button type="button" class="close close-modal close-margin  marLeft" data-dismiss="modal"
                  aria-label="Close">
                  <span aria-hidden="true" class="cross-style"> &#10005;</span>
                </button>
                <img class="warning" src="../../../assets/img/Group 139.svg">
               
                <p class="bid-headingModal">Are you sure want to cancel?</p>
                <p class="modal-para">Once you click on Yes, all your un-saved data will be lost.</p>
                <div class="row itemcenter">
                  <span  class="rowModal-style" data-dismiss="modal"
                  >Yes</span>
                  <span class="rowModal-style back-blue" data-dismiss="modal" aria-label="Close">No</span>
                </div>
              </div>
            </div>
          </div> -->
           <!-- Cancel popup start -->

          <!-- bid log popup start -->
          <div *ngIf="!isDataChanged" class="modal fade " id="exampleModal" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content" style="border-radius: 8px;">
                <div class="modal-header">
                  <div class="bid-heading2">
                    Bid Log
                  </div>
                  <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" class="cross-style"> &#10005;</span>
                  </button>
                </div>
                <div *ngIf="bidsLogs && bidsLogs.length > 0">
                  <div *ngFor="let logs Of bidsLogs,let i = index">
                    <div class="row bid-grid">
                      <div class="border-right col-lg-4 col-md-4">
                        <span class="outbound d-flex "><i
                            class="material-icons d-flex  mr-3 martop">&#xe5c7;</i> <img src="../../../assets/img/mail-icon.svg" class="material-icons d-flex  mr-3 martop"> {{logs.LOG_TYPE_DESC}}</span>
                        <p class="date-style">{{logs.LOG_DATE | bidSubmitFormatDate}}</p>
                      </div>
                      <div class="col-lg-8 col-md-8 bid-enter">
                        <span class="align-right">{{logs.LOG_MESSAGE}}</span>
                      </div>
                    </div>
                    <div class="new"></div>
                  </div>
                </div>
                <div class="pb-3"></div>
              </div>
            </div>
          </div>
          <!-- bid-log pop end -->

          <!-- bid sbmit popup start -->
          <div *ngIf="!isDataChanged" class="modal fade popcenter"  id="submitModal" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content modalSubmit height299">
                <button type="button" class="close close-modal close-margin  marLeft" (click)="clearContents()" data-dismiss="modal"
                  aria-label="Close">
                  <span aria-hidden="true" class="cross-style"> &#10005;</span>
                </button>

                <div *ngIf="!bidsubmitSuccessPopup">
                  <img class="warning padtop25" src="../../../assets/img/Group 139.svg">
                  <!-- <i class="material-icons warning">&#xe002;</i> -->
                  <!-- <p class="bid-headingModal">Do you want to submit this bid?</p>
                  <p class="modal-para">Once you submit the bid, you won't be able to edit it.</p> -->
                  <p class="bid-heading2">Submit Bid and Log Message</p>
                   <div class="msgboxarea">
                     <form [formGroup]='submitForm'>
                    <textarea #myText (keyup)="textCounter($event)"  (input)="onInput($event,myText.value)"  formControlName="sectionTitle"  ng-model="test" ng-trim="false" maxlength="500" placeholder="Enter your Bid Log Message"></textarea>
                  </form> 
                    <div class="chlimit">
                      Max Characters 
                      <span class="maxno">{{bidsubmitmessagecounter}}</span> 
                    </div>
                  </div>
                  <div class="row itemcenter">
                    <span  class=" rowModal-style"  data-dismiss="modal">cancel</span>
                    <span class="back-blue rowModal-style" (click)="acceptBid()">Submit</span>
                  </div>
                </div>

                <div *ngIf="bidsubmitLoder" class=" fade show" role="alert">
                  <div>
                    <ngx-loader [loading]="bidsubmitLoder"></ngx-loader>
                  </div>
                </div>
                <div *ngIf="bidsubmitSuccessPopup">
                  <img class="warning warnPad" src="../../../assets/img/Group 138.svg">
                  <!-- <i class="material-icons warning">&#xe86c;</i> -->
                  <p class="bid-headingModal">Thank you for submitting this bid request.</p>
                  <p class="modal-para">Dear Vendor, Thank your for submitting this bid. Our<br>
                    team will go through your bid and will get back to you.</p>
                  <div class="row itemcenter">
                    <span class=" rowModal-style  back-blue"  data-dismiss="modal" (click)="closeBidSubmitPopup()">close</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- bid sbmit popup end -->
        <!-- mob-design -->
        <div id="mob-structure">
          <div id="bidmgt">
            <div class="tabsection">
            <!--<div class="download tright popview padtop10"><img class="return"
                src="./../../../assets/img/return.png" /><a data-toggle="modal" data-target="#exampleModalBack"
                class="button">Back to Bids</a></div>-->
                <button *ngIf="holdEditRowInArray.length > 0 || holdAddRowInArray.length > 0 || secondSectionArrayFlag" type="button" class="btn-style backtoback" data-toggle="modal" data-target="#submitModalHome"
                  (click)="checkSavedData()">
                  <i class="fa fa-arrow-left pr-2">
                      </i>Back to Bids
                </button>
    
                <button routerLink="/homepage" *ngIf="holdEditRowInArray.length == 0 && holdAddRowInArray.length == 0 && !secondSectionArrayFlag" type="button" class="btn-style  backtoback" 
                  (click)="checkSavedData()">
                  <i class="fa fa-arrow-left pr-2">
                      </i>Back to Bids
                </button>

                <div class="tab box">
                  <input type="checkbox" id="chck1" class="check1">
                  <label class="tab-label" for="chck1"><span>Information</span></label>
                  <div class="tab-content">
                    <ul *ngIf="bidsHeaders && bidsHeaders.length > 0">
                    <!-- <ul> -->
                      <!-- <li class="no-padtop"><label>{{itemInfo.infoname}}</label><span class="padleft6">{{itemInfo.infovalue}}</span></li> -->
                      <li class="no-padtop">
                        <label>Description:</label>
                        <span class="padleft6 width50">
                          {{bidsHeaders && bidsHeaders[0] ? bidsHeaders[0].p_description  : ""}}
                        </span>
                      </li>
                      <li class="no-padtop">
                        <label>ID:</label>
                        <span class="padleft6">
                          {{bidsHeaders && bidsHeaders[0] ? bidsHeaders[0].p_bid_id  : ""}}
                        </span>
                      </li>
                      <li class="no-padtop">
                        <label>Company name:</label>
                        <span class="padleft6">
                          {{bidsHeaders && bidsHeaders[0] ? bidsHeaders[0].p_company_name  : ""}}
                        </span>
                      </li>
                      <li class="no-padtop">
                        <label>Notes:</label>
                        <span class="padleft6 width50">
                          {{bidsHeaders && bidsHeaders[0] ? bidsHeaders[0].p_bid_vendor_desc  : ""}}
                        </span></li>
                      <li class="no-padtop">
                        <label>From:</label>
                         <span class="padleft6">
                          {{bidsHeaders && bidsHeaders[0] ? bidsHeaders[0].p_employee_name  : ""}}
                         </span>
                       </li>
                      <li class="no-padtop">
                        <label>Status:</label>
                        <span class="rcv padleft6" [ngClass]="bidsHeaders[0].p_bid_vendor_status_desc === 'Received' ? 'butn-style open_btn' : bidsHeaders[0].p_bid_vendor_status_desc === 'Approved' || bidsHeaders[0].p_bid_vendor_status_desc === 'Submitted'? 'butn-style closing': 'butn-style closed'" >{{bidsHeaders && bidsHeaders[0] ? bidsHeaders[0].p_bid_vendor_status_desc  : ""}}</span>
                      </li>
                      <li class="no-padtop">
                        <label>Due:</label>
                        <span class="padleft6">
                         {{bidsHeaders && bidsHeaders[0].p_due_date ? moment(bidsHeaders[0].p_due_date).utcOffset('-0400').format('ll')  : "-"}}
                        </span>
                      </li>
                      <li class="no-padtop">
                        <label>Bid Type:</label>
                        <span class="padleft6">
                          {{bidsHeaders && bidsHeaders[0] ? bidsHeaders[0].p_bid_type  : ""}}
                        </span>
                      </li>
                      <li class="no-padtop">
                        <label>Total Bid Price:</label>
                        <span class="padleft6">
                          {{bidByJobPrice | currency:'USD':true:'1.2-3'}}
                        </span>
                      </li>
                      <li class="no-padtop">
                        <label>Total Discount:</label>
                        <span class="padleft6">
                          {{bidDiscountWithsign}}
                        </span>
                      </li>
                      <li class="no-padtop">
                         <label>Revised Final Bid:</label>
                         <span class="padleft6">
                            {{revisedFinalBid | currency:'USD':true:'1.2-3'}}
                         </span>
                      </li>
                    </ul>
                    <button  data-toggle="modal" data-target="#exampleModal" class="bbtn" style="font-weight: 400;">
                      Bid Logs
                    </button>
                    <button (click)="checkSavedData()" [disabled]="Bidstatus || secondSectionArrayFlag" data-toggle="modal" data-target="#submitModal" class="bbtn "
                      style="font-weight: 400;">Bid Submit</button>
                  </div>
                </div>
                <div class="tab">
                  <input type="checkbox" id="chck2" class="check1">
                  <label class="tab-label padleft14" for="chck2"><span class="tunderline">Bid Mode<b>*</b></span></label>
                  <div class="tab-content">
                    <div class="item">
                      <div class="w50 iblock">
                        <ul>
                          <li>
                            <label *ngIf="!bidTypeflag" class="radio radio-before mb0">
                              <span class="radio__input">
                                <!-- <input type="radio" name="radio1" (click)="changeVal()" checked> -->
                                 <input *ngIf="isMobile == 1" [disabled] = "Bidstatus || bidTypeflag" [value]="true" [(ngModel)]="pAllInclusiveIndCheced" checked (click)="changeVal($event)" name="bidssecondsectionmobile"
                                 type="radio">
  
                                <span class="radio__control"></span>
                              </span>
                              <span class="radio__label">
                                Lump Sum Job Bid
                              </span>
                            </label>
                            <div class="iblock vtop">
                              <!--  <input type="text" value="$56,0000.00" class="biprice" [disabled]='disableTextbox' /> -->
         
                                <input *ngIf="!bidTypeflag"  [(ngModel)]="pAllInclusiveCost" [ngClass]="statusClass" id="myText" class="bid-modestyle "
                               type="text" value="" maxlength="10" size="7" [disabled]='disableTextbox || Bidstatus || bidTypeflag'
                               (keyup)="changePrice($event,'bidmode')" (keypress)="bidbyJobValidation($event,'bidmode')"
                               (focusout)="currencyChange($event,'bidmode')" (click)="currencyBack($event,'bidmode')"
                               autocomplete="off">
         
                             </div>
                            <label *ngIf="!bidTypeflag" class="radio radio-before">
                              <span class="radio__input">
  
                                 <input *ngIf="!bidTypeflag && isMobile == 1" [disabled] = "Bidstatus" [value]="false" [(ngModel)]="pAllInclusiveIndCheced" (click)='Disable($event)' type="radio"
                                  name="bidssecondsectionmobile" class=" mr-2">
  
                                <!-- <input type="radio" name="radio1" (click)='Disable($event)'> -->
                                <span class="radio__control"></span>
                              </span>
                              <span class="radio__label">Bid by Item</span>
                            </label>
                            <label *ngIf="bidTypeflag" class="radio radio-before">
                              <span class="radio__input">
  
                                 <input checked *ngIf="isMobile == 1" [disabled] = "Bidstatus" (click)='Disable($event)' type="radio"
                                  name="bidssecondsectionmobile" class=" mr-2">
  
                                <!-- <input type="radio" name="radio1" (click)='Disable($event)'> -->
                                <span class="radio__control"></span>
                              </span>
                              <span class="radio__label">Bid by Item</span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    
                    </div>
                    <div class="itemsep">
                      <p class="tab-label2">Bid Discount</p>
                      <div class="item mb0">
                        <!-- <label class="tab-label2" for="chck2"><span class="">Bid Discount</span></label> -->
                        <div class="w50 iblock">
                          <ul>
                            <li>
                              <label class="radio radio-before mb0">
                                <span class="radio__input">
                                  <!-- <input type="radio" name="radio" (click)="hiding()"> -->
  
                                  <input *ngIf="isMobile == 1" [disabled] = "Bidstatus || bidTypeflag" [value]="false" [(ngModel)]="pBidDiscountChecked"  (click)="checkBidDiscountType($event)"
                                  (click)="showInputs()" type="radio" value="bidpercentagediscount" name="amount" class="mr-2">
  
                                  <span class="radio__control"></span>
                                </span>
                                <span class="radio__label">Percentage (%)</span>
                              </label>
                              <div class="iblock vtop">
                                <!-- <input type="text" *ngIf="showInput" value="$7000" class="biprice" />
                                <input type="text" *ngIf="hideInput" class="biprice" /> -->
                                <input [disabled] = "Bidstatus || bidTypeflag || percentdisabel"   *ngIf="showInput && isMobile == 1" [(ngModel)]="pBidDiscountPct" class="bid-modestyle" type="text"
                                value="$7,000.00" maxlength="7" size="7" (keyup)="changePrice($event,'biddiscount')"
                                (keypress)="bidbyJobValidation($event,'biddiscount')"
                                (focusout)="currencyChange($event,'biddiscount')"
                                autocomplete="off">
                                </div>
  
                              <label class="radio radio-before mb0">
                                <span class="radio__input">
                                  <!-- <input type="radio" name="radio" (click)="showing()" checked> -->
                                  <input *ngIf="isMobile == 1" [disabled] = "Bidstatus || bidTypeflag" [value]="true" [(ngModel)]="pBidDiscountChecked" (click)="hideInputs()"
                                  (click)="checkBidDiscountType($event)" type="radio" value="bidamountdiscount" name="amount"
                                  class=" mr-2"> 
                                  <span class="radio__control"></span>
                                </span>
                                <span class="radio__label">Amount ($)</span>
                              </label>
                            </li>
                          </ul>
                        </div>
                        <div class="iblock vtop">
                          
                           <input [disabled] = "Bidstatus || amountdisabel" *ngIf="hideInput && isMobile == 1" [(ngModel)]="pBidDiscountAmt" class="bid-modestyle" type="text" maxlength="7"
                            size="7" (focusout)="currencyChange($event,'bidamount')" (keyup)="changePrice($event,'bidamount')"
                            (keypress)="bidbyJobValidation($event,'bidamount')" (click)="currencyBack($event,'bidamount')"
                            autocomplete="off">
                        </div>
                      </div>
  
                    </div>
                  </div>
                </div>
              </div>
            <div class="tabs">
               <!-- Loder start-->
              <div *ngIf="loderBeforeApiLoad" class="alert alert-success alert-dismissible fade show loadingbar"
               role="alert">
               <div>
                <ngx-loader [loading]="loderBeforeApiLoad"></ngx-loader>
</div></div>
               
                 <!-- success alert start-->
                 <div *ngIf="displaySuccessPopup" class="alert alert-success alert-dismissible fade show notificaitonalert"
                   role="alert">
                   <div>Success: Data saved successfully</div>
                   <button type="button" class="close err-close" (click)="closeSuccess()" aria-label="Close">
                     <span aria-hidden="true" class="close-button">&times;</span>
                   </button>
                 </div>
                 <!-- success alert end -->
              <div class="btns btnsgrid">
                 <!-- minus Sign error  start-->
                <div *ngIf="checkRevisedFinalBid"
                  class="alert alert-warning alert-dismissible fade show padleft0 negativeerror notificaitonalert" role="alert">
                  <div>Revised Final Bid Cannot be Negative.</div>
                </div>
              <!-- minus Sign error  end-->
           
              
              </div>
              <div class="blubox">
 <!--  <span class="tbtn" data-toggle="modal" data-target="#exampleModalAddrow" (click)="Rowshow()" (click)="addBidData()" >Add Row</span> -->
 <button [disabled]="Bidstatus || pAllInclusiveIndCheced" *ngIf="bidType == 'JOB'" class="tbtn" (click)="openAddPopup(additemcontent)" >Add Row</button>

 <button  [disabled] = "Bidstatus || defaultDisablebutton || checkRevisedFinalBid" class="row-style5"(click)="saveSecondSectionData()">Save</button>

 <button  [disabled] = "Bidstatus || defaultDisablebutton" class="row-style5" data-toggle="modal" data-target="#cancelModalHome" >Cancel</button>
  <!-- <span class="tbtn">Edit</span>
    <span class="bbtn">Save</span> -->
    <div class="search">
      <div class="searchcnt">
        <span class="icon"><img class="pad-left7" src="./../../../assets/img/search.png" /></span>
        <!-- <input type="search" id="search" placeholder="Search..." (keyup)="searchtransform(searchvalue)"/> -->
         <input type="text" class="search-style" [(ngModel)]="searchvalue" id="search" (keyup)="searchtransform(searchvalue)"
      placeholder="search">
      </div>
    </div>
    <div (click)="bidsReports()" class="download text-right font12"><i class="fa fa-download" aria-hidden="true"></i> Download Report</div>
              </div>
              <div id="report" class="text-right">
               
                <div *ngIf="this.dashboardreportsLoading" class="bids-pdf-loading-styles">
                 <div style='position:relative;height:30px;'>
                  <ngx-loader [loading]="this.dashboardreportsLoading"></ngx-loader>
                </div>
               </div>
                
                <div class="box scrollbox text-left marbot-11" id="customscroll">
                  <!-- <ul *ngFor="let itemDesc of descriptions"> -->
                  <!-- <ul> -->
                  <!-- <li style="border-bottom: 2px solid #cfcbc9;"><label>{{itemDesc.descName}}</label><span>{{itemDesc.descvalue}}</span></li> -->
                  <!--                       
                      <li style="border-bottom: 2px solid #cfcbc9;"><label>Item Description:</label><span>Concrete
                          Countertop</span></li>
                      <li style="border-bottom: 2px solid #cfcbc9;"><label>Location:</label><span>N/A</span></li>
                      <li style="border-bottom: 2px solid #cfcbc9;"><label>Quantity:</label><span>1,000.00</span></li>
                      <li style="border-bottom: 2px solid #cfcbc9;"><label>UoM:</label><span>Square Foot</span></li>
                      <li style="border-bottom: 2px solid #cfcbc9;"><label>Unit Cost:</label><span>$10,000</span></li>
                      <li style="border-bottom: 2px solid #cfcbc9;"><label>Bid Unit Cost:</label><span>$9,000</span></li>
                      <li style="border-bottom: 2px solid #cfcbc9;"><label>Taxable:</label><span>No</span></li>
                      <li><label>Item Notes:</label><span>Dumm</span></li>
  
                    </ul> -->
                   <div *ngIf="this.bidsTablesDataLoading" class="bids-mobile-loading-styles loadingbar">
                     <ngx-loader [loading]="this.bidsTablesDataLoading"></ngx-loader>
                    </div>
                    
                  <div *ngFor="let mobileitem Of filterItems,let i = index" [ngClass]="Bidstatus || pAllInclusiveIndCheced ? 'secdisable' : ''">

                    <ul class="BidBorders" (click)="!Bidstatus && !pAllInclusiveIndCheced && getSingleBitItems(mobileitem.id,'edit')" data-toggle="modal">
                      <li  style="border-bottom: 2px solid #f3f0ef;padding-left: 15px"><label>Item Description:</label>
                        <span>{{mobileitem.ITEM_DESC == null || mobileitem.ITEM_DESC == "" ? "dummy content" : mobileitem.ITEM_DESC}}</span>
                      </li>
                      <li style="border-bottom: 2px solid #f3f0ef;padding-left: 15px">
                        <label>Itemization:</label>
                        <span>{{mobileitem.ITEMIZATION_NO == null || mobileitem.ITEMIZATION_NO == "" ? "-" : mobileitem.ITEMIZATION_NO}}</span>
                      </li>
                      <li style="border-bottom: 2px solid #f3f0ef;padding-left: 15px">
                        <label>Location:</label>
                        <span>{{mobileitem.LOCATION_NAME == null || mobileitem.LOCATION_NAME == "" ? "-" : mobileitem.LOCATION_NAME}}</span>
                      </li>
                      <li style="border-bottom: 2px solid #f3f0ef;padding-left: 15px">
                        <label>Quantity:</label>
                        <span>{{mobileitem.ITEM_QTY == null || mobileitem.ITEM_QTY == "" ? "-" : mobileitem.ITEM_QTY | number:'1.2-2'}}</span>
                      </li>
                      <li *ngIf="bidType == 'JOB'" style="border-bottom: 2px solid #f3f0ef;padding-left: 15px">
                        <label>Bid Quantity:</label>
                        <span>{{mobileitem.NEW_ITEM_QTY == null || mobileitem.NEW_ITEM_QTY == '' ? '-' : mobileitem.NEW_ITEM_QTY | number:'1.2-2' }}</span>
                      </li>
                      <li style="border-bottom: 2px solid #f3f0ef;padding-left: 15px">
                        <label>UoM:</label>
                        <span>{{mobileitem.MEASURE_UNIT == null || mobileitem.MEASURE_UNIT == "" ? "-" : mobileitem.MEASURE_UNIT}}</span>
                      </li>
                      <li style="border-bottom: 2px solid #f3f0ef;padding-left: 15px">
                        <label>Unit Cost:</label>
                        <span>{{mobileitem.ORIGINAL_UNIT_COST == null || mobileitem.ORIGINAL_UNIT_COST == "" ? "-" : mobileitem.ORIGINAL_UNIT_COST | currency:'USD':'symbol'}}</span>
                      </li>
                      <li style="border-bottom: 2px solid #f3f0ef;padding-left: 15px">
                        <label>Bid Unit Cost:</label>
                        <span>{{mobileitem.NEW_UNIT_COST == null || mobileitem.NEW_UNIT_COST == '' ? '-' : mobileitem.NEW_UNIT_COST | currency:'USD':'symbol' }}</span>
                      </li>
                      <li style="border-bottom: 2px solid #f3f0ef;padding-left: 15px">
                        <label>Taxable:</label>
                        <span>{{mobileitem.TAXABLE_IND == null || mobileitem.TAXABLE_IND == "" ? "-" : mobileitem.TAXABLE_IND}}</span>
                      </li>
                      <li>
                        <label style="padding-left: 8px;">Item Notes:</label>
                        <span >{{mobileitem.ITEM_NOTES == null || mobileitem.ITEM_NOTES == "" ? "-" : mobileitem.ITEM_NOTES}}</span>
                      </li>
                    </ul>
                  </div>
                  <div *ngIf="filterItems.length === 0 && !this.bidsTablesDataLoading">
                    <div class="aligncenter mt-3 mb-3"> No Data Records Found.</div>  
                  </div>
                </div>
              
                <div class="modal fade" id="exampleModalBack" tabindex="-1" role="dialog"
                  aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div id="back" class="">
                        <div class="popup">
                          <!-- <div class="close"><a class="closeicon"     >&times;</a></div> -->
                          <div class="content">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true" class="cross-style"> &#10005;</span>
                            </button>
                            <img src="./../../../assets/img/confirm.svg" />
                            <h2>Are you sure want to go back?</h2>
                            <p class="linehigh20">Once you say no, you will redirect to homepage</p>
                            <a class="tbtn" data-dismiss="modal" routerLink="/homepage">Yes</a>
                            <a class="bbtn" data-dismiss="modal" aria-label="Close">No</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
               
                <!-- popup functionality -->
                  <!-- add mobile popup start -->
                  <ng-template #additemcontent let-c="close" let-d="dismiss">
                    <div id="exampleModalAddrow" tabindex="-1" role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div >
                      <div>
                        <div id="bidlog" class="">
                          <div class="bidpopup container">
    
                            <!-- error alert start-->
                            <!-- <div *ngIf="displayErrorPopup" class="alert alert-warning alert-dismissible fade show"
                              role="alert">
                              <div>Error: Please fill the required fields</div>
                              <button type="button" class="close err-close" (click)="closeError()" aria-label="Close">
                                <span aria-hidden="true" class="close-button">&times;</span>
                              </button>
                            </div> -->
                            <!-- error alert end-->
                            <!-- Loder start-->
                            <div *ngIf="loderBeforeApiLoad" class="alert alert-success alert-dismissible fade show"
                          role="alert">
                            <div>
                              <ngx-loader [loading]="loderBeforeApiLoad"></ngx-loader>
                            </div>
                        </div>

                           <!-- error alert start-->
                              <div *ngIf="editErrorMessage"
                                class="alert alert-warning alert-dismissible fade show" role="alert">
                                <div>Error: Please fill the required fields</div>
                              </div>
                              <!-- error alert end-->
                            <!-- success alert start-->
                            <div  *ngIf="displaySuccessPopup" class="alert alert-success alert-dismissible fade show notificaitonalert"
                              role="alert">
                              <div>Success: Data saved successfully</div>
                              <button type="button" class="close err-close" (click)="closeSuccess()" aria-label="Close">
                                <span aria-hidden="true" class="close-button">&times;</span>
                              </button>
                            </div>
                            <!-- success alert end -->
                              <!-- cancle alert start-->
                              <div style="width:100%" *ngIf="cancelError" class="mt-2 alert alert-success alert-dismissible fade show"
                              role="alert">
                              <div>Your item data has been reset </div>
                              <button type="button" class="close err-close" (click)="closeSuccess()" aria-label="Close">
                                <span aria-hidden="true" class="close-button">&times;</span>
                              </button>
                            </div> 
                            <!-- cancel alert end -->
                             
                           
                            <form [formGroup]='addItemForm'>
                              <div class="box bidhead text-left marbot-11 ">
                                <ul>
                                  <li style="border-bottom: 2px solid #f3f0ef;">
                                    <label>Item Description  <span class="req">*</span></label>
                                    <span>
                                      <textarea name="itemdesc" id="itemdesc" class="form-control destxtarea " type="text" formControlName="itemdesc"
                                      placeholder="Vendor Item" [ngClass]="{'is-invalid': f.itemdesc.touched && f.itemdesc.errors}" (keyup)="mobupdateData($event,'ITEM_DESC')"></textarea>
                                    </span>
                                  </li>
                                  <li style="border-bottom: 2px solid #f3f0ef;"><label>Itemization</label><span
                                      style="color:black">1</span></li>
                                  <li style="border-bottom: 2px solid #f3f0ef;"><label>Location</label><span
                                      style="color:black">-</span></li>
                                  <li style="border-bottom: 2px solid #f3f0ef;"><label>Quantity</label><span
                                      style="color:black">-</span></li>
                                  <li style="border-bottom: 2px solid #f3f0ef;">
                                    <label>Bid Quantity <span class="req">*</span></label>
                                    <span>
                                      <input
                                       autocomplete="off"
                                        name="newitemqty" id="newitemqty" class=" form-control" type="text" formControlName="newitemqty" [ngClass]="{'is-invalid': f.newitemqty.touched && f.newitemqty.errors}"
                                        placeholder="Quantity" (focusout)="currencyChange($event,'NEW_ITEM_QTY')"
                                        (click)="currencyBack($event,'NEW_ITEM_QTY')"
                                        maxlength="16" (input)="mobupdateData($event,'NEW_ITEM_QTY_MOB')"
  
                                        >
                                    </span>
                                  </li>
                                  <li style="border-bottom: 2px solid #f3f0ef;">
                                    <label>UoM <span class="req">*</span></label>
                                    <span>
                                      <select formControlName="measureunit"  name="measureunit"  id="measureunit" class="form-control select inputs-select-spacing pl-0"  formControlName="measureunit" [ngClass]="{'is-invalid': f.measureunit.touched && f.measureunit.errors}" (change)="mobupdateData($event,'MEASURE_UNIT')">
                                        <option value='all' [selected]="'all'">Select UOM</option>
                                        <option value="{{uoms.MEASURE_UNIT}}" *ngFor="let uoms of umoSelect">
                                          {{uoms.MEASURE_UNIT == null || uoms.MEASURE_UNIT == '' ? '-' : uoms.MEASURE_UNIT}}
                                        </option>
                                      </select>
                                    </span>
                                  </li>
                                  <li style="border-bottom: 2px solid #f3f0ef;"><label>Unit Cost</label><span
                                      style="color:black">-</span></li>
                                  <li style="border-bottom: 2px solid #f3f0ef;"><label>Bid Unit Cost <span class="req">*</span></label>
                                    <span>
                                      <input 
                                      autocomplete="off"
                                      name="bidunitcostvalue" id="bidunitcostvalue" class=" form-control" type="text" formControlName="bidunitcostvalue"  [ngClass]="{'is-invalid': f.bidunitcostvalue.touched && f.bidunitcostvalue.errors}"
                                        placeholder="Bid Unit Cost" (focusout)="currencyChange($event,'NEW_UNIT_COST')"
                                        (click)="currencyBack($event,'NEW_UNIT_COST')"
                                        maxlength="16" (keyup)="mobupdateData($event,'NEW_UNIT_COST_MOB')">
                                    </span>
                                  </li>
                                  <li style="border-bottom: 2px solid #f3f0ef;">
                                    <label>Taxable <span class="req">*</span></label>
                                    <span>
                                      <select  name="taxableind"  id="taxableind" class="form-control select inputs-select-spacing"  formControlName="taxableind" [ngClass]="{'is-invalid': f.taxableind.touched && f.taxableind.errors}" (change)="mobupdateData($event,'TAXABLE_IND')">
                                        <option [selected]="'N'" value="N">N</option>
                                        <option value="Y">Y</option>
                                      </select>
                                    </span>
                                  </li>
                                  <li>
                                    <label>Item Notes:</label>
                                    
                                      <textarea name="itemnotes"  id="itemnotes" class="form-control destxtarea" type="text" formControlName="itemnotes" [ngClass]="{'is-invalid': f.itemnotes.touched && f.itemnotes.errors}"
                                        placeholder="Item Notes" (keyup)="mobupdateData($event,'ITEM_NOTES')"></textarea>
                                   
                                  </li>
                                </ul>
                              </div>
                              <div class="aligncenter">
                                <button [disabled]="!addItemForm.valid" class="bbtn ml-3" style="padding: 7px 25px;"
                                  (click)="addMobileItemData(addItemForm)">Save</button>
                                <button [disabled]="!compareItemArrayFlag" class="bbtn ml-3" style="padding: 7px 25px;" 
                                   data-toggle="modal" data-target="#cncelAddPopup">Cancel</button> 
                                <button *ngIf="compareItemArrayFlag" type="button" class="bbtn ml-3" data-toggle="modal" data-target="#closeAddPopup" aria-label="Close">Close</button>
                                <button *ngIf="!compareItemArrayFlag" type="button" class="bbtn ml-3" 
                                  (click)="d('Cross click')" aria-label="Close">Close</button>
                              </div>
                              </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    </ng-template>
                      <!-- add mobile popup end -->
    
                    <!-- Edit mobile popup start -->
                    <ng-template #edititemcontent let-c="close" let-d="dismiss">
                      <div id="exampleModalEditrow" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                        aria-hidden="true">
                        <div>
                          <div>
                            <div id="bidlog" class="">
                              <div class="bidpopup container">
    
                            <!-- Loder start-->
                            <div *ngIf="loderBeforeApiLoad" class="alert alert-success alert-dismissible fade show"
                          role="alert">
                          <div>
                            <ngx-loader [loading]="loderBeforeApiLoad"></ngx-loader>
                          </div>
                        </div>
                            <!-- success alert start-->
                            <div style="width:100%" *ngIf="displaySuccessPopup" class="alert alert-success alert-dismissible fade show mt-3"
                            role="alert">
                            <div>Success: Data saved successfully</div>
                            <button type="button" class="close err-close" (click)="closeSuccess()" aria-label="Close">
                              <span aria-hidden="true" class="close-button">&times;</span>
                            </button>
                          </div>

                           <!-- cancle alert start-->
                              <div style="width:100%" *ngIf="cancelError" class="alert alert-success alert-dismissible fade show mt-3"
                              role="alert">
                              <div>Your item data has been reset </div>
                              <button type="button" class="close err-close" (click)="closeSuccess()" aria-label="Close">
                                <span aria-hidden="true" class="close-button">&times;</span>
                              </button>
                            </div> 

                              <!-- error alert start-->
                              <div *ngIf="editErrorMessage"
                                class="alert alert-warning alert-dismissible fade show mt-3" role="alert">
                                <div>Error: Please fill the required fields</div>
                              </div>
                              <!-- error alert end-->
                            <!-- cancel alert end -->
                                 <!-- <button  type="button" #closebutton class="close"data-toggle="modal" data-target="#closeEditPopup" aria-label="Close">
                                <span aria-hidden="true" class="cross-style"> &#10005;</span>
                              </button> -->
                                <!-- <span class="position5 close-i" (click)="d('Cross click')"> <i
                                    class="fa fa-times"></i></span> -->
                                <form [formGroup]='editItemForm'>
                                  <input [(ngModel)]="mobEditItemList.biditemid" type="hidden" formControlName="biditemId">
                                  <input [(ngModel)]="VENDOR_ADDITION_IND" type="hidden" formControlName="vendorAdditionInd">
                                  <input [(ngModel)]="mobEditItemList.itemqty" type="hidden" formControlName="itemqty">

                                  <div class="box bidhead text-left marbot-11">
                                    <ul>
                                      <li style="border-bottom: 2px solid #f3f0ef;">
                                        <label>Item Description<span class="req">*</span></label>
                                        <span *ngIf="VENDOR_ADDITION_IND == 'Y'">
                                          <textarea name="edititemdesc" id="edititemdesc" formControlName="edititemdesc" [(ngModel)]="mobEditItemList.mobItemDesc"
                                          [ngClass]="{'is-invalid':e.edititemdesc.touched && e.edititemdesc.errors}"
                                            class="form-control destxtarea" type="text" (keyup)="mobupdateData($event,'ITEM_DESC')" placeholder="Vendor Item"></textarea>
                                        </span>
                                        <span *ngIf="VENDOR_ADDITION_IND == 'N'">
                                          {{mobEditItemList.mobItemDesc == null || mobEditItemList.mobItemDesc == '' ? '-' : mobEditItemList.mobItemDesc}}
                                        </span>
                                      </li>
                                      <li style="border-bottom: 2px solid #f3f0ef;">
                                        <label>Itemization</label>
                                        <span style="color:black">
                                          {{mobEditItemList.itemizationno == null || mobEditItemList.itemizationno == '' ? '-' : mobEditItemList.itemizationno}}
                                        </span>
                                      </li>
                                      <li style="border-bottom: 2px solid #f3f0ef;">
                                        <label>Location</label>
                                        <span style="color:black">
                                          {{mobEditItemList.locatonname == null || mobEditItemList.locatonname == '' ? '-' : mobEditItemList.locatonname}}
                                        </span>
                                      </li>
                                      <li style="border-bottom: 2px solid #f3f0ef;">
                                        <label>Quantity</label>
                                        <span style="color:black">
                                          {{mobEditItemList.itemqty == null || mobEditItemList.itemqty == '' ? '-' : mobEditItemList.itemqty | number:'1.2-2' }}
                                        </span>
                                      </li>
                                      <li *ngIf="(bidType == 'JOB')" style="border-bottom: 2px solid #f3f0ef;">
                                        <label>Bid Quantity<span class="req">*</span></label>
                                        <span>
                                          <input 
                                          autocomplete="off"
                                          name="editnewitemqty" id="editnewitemqty" formControlName="editnewitemqty" [(ngModel)]="mobEditItemList.newitemqty"
                                            class="form-control" value="{{mobEditItemList.newitemqty}}"
                                            [ngClass]="{'is-invalid':e.editnewitemqty.touched && e.editnewitemqty.errors}"
                                            type="text" (click)="currencyBack($event,'NEW_ITEM_QTY')"
                                            (focusout)="currencyChange($event,'NEW_ITEM_QTY')"maxlength="16"
                                            (keyup)="mobupdateData($event,'NEW_ITEM_QTY_MOB')"
                                           >
                                        </span>
                                      </li>
                                      <li style="border-bottom: 2px solid #f3f0ef;">
                                        <label>UoM<span class="req">*</span></label>
                                        <span *ngIf="VENDOR_ADDITION_IND == 'Y'">
                                          <select formControlName="editmeasureunitdesc" class="form-control select inputs-select-spacing pl-0"  [ngClass]="{'is-invalid':e.editmeasureunitdesc.touched && e.editmeasureunitdesc.errors}"
                                            [(ngModel)]="mobEditItemList.measureunitdesc"
                                            (change)="mobupdateData($event,'MEASURE_UNIT')">
                                            <option value="">Select UOM</option>
                                            <option value="{{uoms.MEASURE_UNIT}}" *ngFor="let uoms of umoSelect">
                                              {{uoms.MEASURE_UNIT == null || uoms.MEASURE_UNIT == '' ? '-' : uoms.MEASURE_UNIT}}
                                            </option>
                                          </select>
                                        </span>
                                        <span *ngIf="VENDOR_ADDITION_IND == 'N'">
                                          {{mobEditItemList.measureunitdesc == null || mobEditItemList.measureunitdesc == '' ? '-' : mobEditItemList.measureunitdesc}}
                                        </span>
                                      </li>
                                      <li style="border-bottom: 2px solid #f3f0ef;">
                                        <label>Unit Cost</label>
                                        <span style="color:black">
                                          {{mobEditItemList.unitcost == null || mobEditItemList.unitcost == '' ? '-' : mobEditItemList.unitcost}}
                                        </span>
                                      </li>
                                      <li style="border-bottom: 2px solid #f3f0ef;">
                                        <label>Bid Unit Cost<span class="req">*</span></label>
                                        <span>
                                          <input 
                                          autocomplete="off"
                                          name="editbidunitcost" id="editbidunitcost" formControlName="editbidunitcost" [(ngModel)]="mobEditItemList.bidunitcost"
                                            value="{{mobEditItemList.bidunitcost}}"
                                            [ngClass]="{'is-invalid':e.editbidunitcost.touched && e.editbidunitcost.errors}"
                                            class="form-control" type="text" (click)="currencyBack($event,'NEW_UNIT_COST')"
                                            (focusout)="currencyChange($event,'NEW_UNIT_COST')"
                                            (keyup)="mobupdateData($event,'NEW_UNIT_COST_MOB')"
                                            >
                                        </span>
                                      </li>
                                      <li style="border-bottom: 2px solid #f3f0ef;">
                                        <label>Taxable</label>
                                        <span *ngIf="VENDOR_ADDITION_IND == 'Y'">
                                          <select formControlName="edittaxableind"name="edittaxableind"  id="edittaxableind" class="form-control select inputs-select-spacing"[ngClass]="{'is-invalid': e.edittaxableind.touched && e.edittaxableind.errors}" [(ngModel)]="mobEditItemList.taxableind"
                                          (change)="mobupdateData($event,'TAXABLE_IND')">
                                            <option value="N">N</option>
                                            <option value="Y">Y</option>
                                          </select>
                                        </span>
                                        <span *ngIf="VENDOR_ADDITION_IND == 'N'">
                                          {{mobEditItemList.taxableind == null || mobEditItemList.taxableind == '' ? '-' : mobEditItemList.taxableind}}
                                        </span>
                                      </li>
                                      <li style="border-bottom: 2px solid #f3f0ef;">
                                        <label>Item Notes</label>
                                        <span>
                                          <textarea name="edititemnote" id="edititemnote" formControlName="edititemnote" [(ngModel)]="mobEditItemList.itemnote"
                                          [ngClass]="{'is-invalid': e.edititemnote.touched && e.edititemnote.errors}"
                                            class="form-control destxtarea" type="text"  (keyup)="mobupdateData($event,'ITEM_NOTES')"></textarea>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="aligncenter">  <button [disabled]="!editItemForm.valid" class="bbtn ml-3" style="padding: 7px 25px;"
                                    (click)="editMobileItemData(editItemForm)">Save</button>
                                  <button *ngIf="VENDOR_ADDITION_IND == 'Y'" class="bbtn ml-3" style="padding: 7px 25px;"
                                  data-toggle="modal" data-target="#EditdeleteModal" >Delete</button>

                                  <button [disabled]="!compareItemArrayFlag"  class="bbtn ml-3" style="padding: 7px 25px;"
                                  data-toggle="modal" data-target="#cancelEditPopup" 
                                  >Cancel</button>

                                  <button *ngIf="compareItemArrayFlag"  class="bbtn ml-3" style="padding: 7px 25px;" data-toggle="modal" data-target="#closeEditPopup">Close</button>

                                  <button *ngIf="!compareItemArrayFlag" class="bbtn ml-3" style="padding: 7px 25px;" (click)="d('Cross click')">Close</button>
                                </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                    <!-- Edit mobile popup end -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
              <!-- mobile delete popup start -->
          <div class="modal fade popcenter cancelmodal" id="EditdeleteModal" tabindex="-1" role="dialog" aria-labelledby="EditdeleteModal"
              aria-hidden="true" data-backdrop="static">
              <div class="modal-dialog" role="document">
                <div class="modal-content modalSubmit height299 padtop25">
                  <button type="button" class="close close-modal close-margin  marLeft" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true" class="cross-style"> &#10005;</span>
                  </button>
                  <img class="warning" src="../../../assets/img/Group 139.svg">
                  <!-- <i class="material-icons warning">&#xe002;</i> -->
                  <p class="bid-headingModal">Are you sure want to delete?</p>
                  <!-- <p class="modal-para">Once you say no, You will redirect to home page.</p> -->
                  <div class="row itemcenter">
                    <span class="rowModal-style" (click)="deleteMobileItemData(mobEditItemList.biditemid,mobEditItemList.itemindex)" data-dismiss="modal">Yes</span>
                    <span class="rowModal-style back-blue" data-dismiss="modal" aria-label="Close">No</span>
                  </div>
                </div>
              </div>
            </div>
            <!--mobile delete popup end -->
    
             <!-- close edit popup start -->
             <div class="modal fade cancelmodal popcenter"  id="closeEditPopup" tabindex="-1"
             role="dialog" aria-labelledby="closeEditPopup" aria-hidden="true"  
             >
             <div class="modal-dialog" role="document">
               <div class="modal-content modalSubmit height299 padtop25">
                 <button type="button" class="close close-modal close-margin  marLeft" data-dismiss="modal"
                   aria-label="Close">
                   <span aria-hidden="true" class="cross-style"> &#10005;</span>
                 </button>
                 <img class="warning" src="../../../assets/img/Group 139.svg">
                 <!-- <i class="material-icons warning">&#xe002;</i> -->
                 <p class="bid-headingModal">Are you sure want to close?</p>
                 <p class="modal-para">Once you click on Yes, all your un-saved data will be lost.</p>
                 <div class="row itemcenter">
                   <span class="rowModal-style"(click)="closeEditPopup()" data-dismiss="modal"
                   >Yes</span>
                   <span class="rowModal-style back-blue" data-dismiss="modal" aria-label="Close">No</span>
                 </div>
               </div>
             </div>
           </div>
            <!-- Close edit popup end -->
             <!-- close add popup start -->
             <div class="modal fade cancelmodal popcenter"  id="closeAddPopup" tabindex="-1"
             role="dialog" aria-labelledby="closeaddPopup" aria-hidden="true"  
             >
             <div class="modal-dialog" role="document">
               <div class="modal-content modalSubmit height299 padtop25">
                 <button type="button" class="close close-modal close-margin  marLeft" data-dismiss="modal"
                   aria-label="Close">
                   <span aria-hidden="true" class="cross-style"> &#10005;</span>
                 </button>
                 <img class="warning" src="../../../assets/img/Group 139.svg">
                 <!-- <i class="material-icons warning">&#xe002;</i> -->
                 <p class="bid-headingModal">Are you sure want to close?</p>
                 <p class="modal-para">Once you click on Yes, all your un-saved data will be lost.</p>
                 <div class="row itemcenter">
                   <span class="rowModal-style"(click)="closeAddPopup()" data-dismiss="modal"
                   >Yes</span>
                   <span class="rowModal-style back-blue" data-dismiss="modal" aria-label="Close">No</span>
                 </div>
               </div>
             </div>
           </div>
            <!-- Close add popup end -->
            <!-- cancel edit popup start -->
            <div #canceledititemcontent class="modal fade cancelmodal popcenter"  id="cancelEditPopup" tabindex="-1"
            role="dialog" aria-labelledby="cancelEditPopup" aria-hidden="true"  
            >
            <div class="modal-dialog" role="document">
              <div class="modal-content modalSubmit height299 padtop25">
                <button type="button" class="close close-modal close-margin  marLeft" data-dismiss="modal"
                  aria-label="Close">
                  <span aria-hidden="true" class="cross-style"> &#10005;</span>
                </button>
                <img class="warning" src="../../../assets/img/Group 139.svg">
                <!-- <i class="material-icons warning">&#xe002;</i> -->
                <p class="bid-headingModal">Are you sure want to cancel?</p>
                <p class="modal-para">Once you click on Yes, all your un-saved data will be lost.</p>
                <div class="row itemcenter">
                  <span class="rowModal-style"(click)="canceleditpopup(mobEditItemList.itemindex);" data-dismiss="modal"
                  >Yes</span>
                  <span class="rowModal-style back-blue" data-dismiss="modal" aria-label="Close">No</span>
                </div>
              </div>
            </div>
          </div>
              <!-- cancel edit popup end -->
              <!-- cancel add popup start -->
            <div #canceladditemcontent class="modal fade cancelmodal popcenter"  id="cncelAddPopup" tabindex="-1"
            role="dialog" aria-labelledby="cncelAddPopup" aria-hidden="true"  
            >
            <div class="modal-dialog" role="document">
              <div class="modal-content modalSubmit height299 padtop25">
                <button type="button" class="close close-modal close-margin  marLeft" data-dismiss="modal"
                  aria-label="Close">
                  <span aria-hidden="true" class="cross-style"> &#10005;</span>
                </button>
                <img class="warning" src="../../../assets/img/Group 139.svg">
                <!-- <i class="material-icons warning">&#xe002;</i> -->
                <p class="bid-headingModal">Are you sure want to cancel?</p>
                <p class="modal-para">Once you click on Yes, all your un-saved data will be lost.</p>
                <div class="row itemcenter">
                  <span class="rowModal-style"(click)="resetAddItemForm()" data-dismiss="modal"
                  >Yes</span>
                  <span class="rowModal-style back-blue" data-dismiss="modal" aria-label="Close">No</span>
                </div>
              </div>
            </div>
          </div>
              <!-- cancel add popup end -->
  <!-- pdf pop-up start -->
  <ng-template #reportsdoccontents let-c="close" let-d="dismiss">
    <div class="login-box" *ngIf="reportsDesc">
      <div class="login-sec docmarg">
        <div class="nav-tabs">
          <div class="w3-bar w3-black" *ngIf="urlexists !== 0">
            <h4>
              <span class="position5 close-i" (click)="d('Cross click')"> <i class="fa fa-times"></i></span>
            </h4>
          </div>
          <div id="membership1" class="w3-container w3-border Buton">
            <div class="file-box" *ngIf="doctype === 'pdf'">
              <object [data]="reportsDesc" type="application/pdf" width="100%" id="doc-pdf" height="100%" style="margin-top: 15px;">
                <div class="popup-styles">
                  <div>
                    <p *ngIf="urlexists === 1" class="popup-p-styles">
                      <i class="fa fa-check popup-icon-styles"></i> Your file is ready for Download.
                    </p>
                    <p *ngIf="urlexists === 0" class="popup-p-styles">
                      <i class="fa fa-exclamation-triangle popup-wrng-styles"></i> Sorry we were unable to find that File.
                      </p>
                  </div>
                  <div>
                    <a  *ngIf="urlexists === 1" (click)="downloadpdf(reportsDesc)" class="pdf-download-btn">
                      Download Now
                    </a>
                    <a *ngIf="urlexists === 0" class="pdf-download-btn" (click)="d('Cross click')">
                      Close Now
                    </a>
                  </div>
                </div>
              </object>
           <!--    <div style="width: 100%;height:100%">
               <ng2-pdfjs-viewer [pdfSrc]="reportsDesc"></ng2-pdfjs-viewer>
              </div> -->
            </div>
            <div class="file-box" *ngIf="doctype !== 'pdf'">
                <div class="popup-styles" *ngIf="urlexists === 0">
                    <div>
                      <p class="popup-p-styles">
                        <i class="fa fa-exclamation-triangle popup-wrng-styles"></i> Sorry we were unable to find that File.
                        </p>
                    </div>
                    <div>
                      <a class="pdf-download-btn" (click)="d('Cross click')">
                        Close Now
                      </a>
                    </div>
                  </div>

              <div *ngIf="urlexists === 1">
                <div>
                  <a (click)="downloaddoc(reportsDesc)">
                    <i class="fa fa-download pull-right" style="margin-right: 5%;margin-bottom: 1%;cursor: pointer;"></i>
                  </a>
                </div>
                <ngx-doc-viewer [url]="reportsDesc" id="docs" viewer="office" disableContent="popout"
                  style="width:100%; height:100vh; display:grid; object-fit:scale-down;scroll-behavior: smooth;">
                </ngx-doc-viewer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <!-- pdf pop-up end -->
  
<!-- <div class="modal fade" id="exampleModalMob" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                
              </div>
            </div>
          </div>
          data-toggle="modal" data-target="#exampleModal" -->
<script src="https://ajax.googleapis.com/ajax/libs/angularjs/1.6.9/angular.min.js"></script>
